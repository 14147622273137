<template>
    <div class="container-fluid" v-if="cardsForPage.length">
        <div class="row">
            <button type="button" :class="buttonClasses" v-on:click="toggleVisibility(currentRouteName)">{{ displayCards ? 'Hide Content' : 'Show Content' }}</button>
        </div>
    </div>
    <hr>
    <section id="cards" class="welcome_area p_120 my-4" v-if="displayCards && cardsForPage.length">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 mb-3 px-1 py-6" v-for="card in cardsForPage" :key="card.id">
              <div class="carddesign card h-100">
                <div class="card-header" v-html="card.title.rendered"></div>
                  <div class="card-body">
                    <div class="overlay"></div>
                    <!--<h3><i class="icon fa fa-code fa-1.5x"></i> {{ card.title.rendered }}</h3>-->
                    <div v-html="card.content.rendered"></div>
                    <!--<a href="https://services.ncl.ac.uk/" class="btn btn-primary">Read More ...</a>-->
                  </div>
              </div>
            </div>
          </div>
        </div>
    </section>
</template>

<script>
    import { mapState, mapActions } from 'pinia';
    import { useCardStore } from '@/stores/card.module';

    export default {
        name: "ContentCard",
        computed: {
            ...mapState(useCardStore, ['cards']),
            buttonClasses() {
                return this.cards[this.$route.name]?.display ? "btn btn-sm btn-block btn-secondary" : "btn btn-sm btn-block btn-primary";
            },
            currentRouteName() {
                return this.$route.name;
            },
            cardsForPage() {
                return this.cards[this.$route.name]?.items ? this.cards[this.$route.name]?.items : [];
            },
            displayCards() {
                return this.cards[this.$route.name]?.display;
            }
        },
        methods: {
            ...mapActions(useCardStore, ['toggleVisibility']),
        }
    };
</script>

<style scoped>
    .btn-close {
        float:right;
    }
</style>
