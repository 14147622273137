<template>

    <section id="timetable-modules" class="ttable-width">
        <section v-if="GetModules.length">
            <div>
                <span> My modules: {{ GetModules.length }}</span>

                <div class="modules-container">
                    <div v-for="mod in GetModules" :key=mod.Name>
                        <div class="module-pill bg-gradient" :style="{ 'background-color': '#FFF', 'font-weight':'bold', 'color': '#333'}">
                            <span class="module-name" :title="mod.Description">{{ mod.Name }}</span><!--<span class="module-description">: {{ mod.Description }}</span>-->
                        </div>
                    </div>
                </div>

                <!--<div>{{ GetModules }}</div>-->
            </div>
        </section>
    </section>

</template>


<script>
/* style="{ 'background-color': mod.displayColours.bgCol , 'font-weight':'bold', 'color': '#333' */
import {mapActions, mapState} from "pinia";
    import {useMyTimetableStore} from "@/stores/timetables.module";
    import {useAlertStore} from "@/stores/alert.module";

    export default {
        name: "MyModules",
        components: {
        },
        created() {
            //this.initialiseTT();
        },
        mounted() {
        },
        computed: {
            ...mapState(useMyTimetableStore, ['timetabledata']),
            //...mapState(useWeeksOfYearStore, ['academicweeks']),
            GetModules() {
                let retval = this.timetabledata?.ttmodules ? this.timetabledata.ttmodules : false; //[{Name: "ABC12345", Description: "Holder description", Hostkey: "ABC12345", displayColours: {}}];
                // console.log(retval);
                return retval;
            },
        },
        methods: {
            ...mapActions(useMyTimetableStore, ['getTimetable']),
            ...mapActions(useAlertStore, ['showErrorAlert']),
            async initialiseTT() {
                //console.log("Initialising TT in modules");
                try {
                    await this.getTimetable(1);
                }
                catch (error) {
                    let extraInfo = "";
                    if (error?.response?.status === 404) {
                        extraInfo = " Not Found";
                    } else {
                        extraInfo = error?.response;
                    }
                    //console.log("Error in fetching modules", error);
                    this.showErrorAlert('Error: Unable to retrieve teaching timetable (from modules) ');
                }
            },
            sleep(ms) {
                return new Promise(resolve => setTimeout(resolve, ms));
            },
        }
    }
</script>

<style>
    #timetable-modules {
        margin-top: 2em;
    }
    .modules-container {
        display: flex;
        flex-flow: row wrap;
        justify-content: left;
        align-items: flex-start;
    }
    .module-pill {
        display: inline-block;
        background-color: #FFF; /*#ffc107;*/
        color: #333;
        padding: 0.2em;
        margin: 0.3em;
        border-color: #333;
        border-width: 1px;
        border-radius: 10px;
        border-style: solid;
        width: 7em;
        text-align: center;
    }
</style>
