<template>
    <div class="clearfix header-container">
        <div class="float-start">
            <div>
                <h2>{{ $strings('news.heading') }}</h2>
            </div>
            <div>
                {{ $strings('news.subHeading') }}
            </div>
        </div>
        <div class="float-end">
            <button type="button" :class="buttonClasses" class="settings-button" @click="$router.push('Settings')"><i class="bi bi-gear-fill"></i></button>
        </div>
    </div>
    <the-alert />
    <section id="content" class="welcome_area p_120" v-if="content && content.length">
        <hr>
        <div class="container">
            <div class="row">
                <div class="col-lg-4 my-1 px-1 py-1" v-for="item in paginated" :key="item.link">
                    <NewsCard
                        :title="item.title"
                        :description="item.description"
                        :imageURL="item.imageURL"
                        :link="item.link"
                        :publishDate="item.pubDate"
                        :channel="item.channel.name"
                    />
                </div>
            </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="my-5 px-1 py-1 d-flex justify-content-center" align="center">
                <pagination v-model="page" :records="content.length" :per-page="itemsCountPerPage" :options="{chunk:5,chunksNavigation:scroll}" />
            </div>
          </div>
        </div>
    </section>
</template>

<script>
    import { mapState, mapActions } from 'pinia';
    import { useArticleStore } from '@/stores/article.module';
    import { useUserSettingsStore } from '@/stores/usersettings.module';
    import { useAlertStore } from '@/stores/alert.module';
    import TheAlert from '@/components/TheAlert.vue';
    import NewsCard from '@/components/news-events/NewsCard.vue';
    import Pagination from 'v-pagination-3';
    import moment from 'moment';
    
    export default {
        name: 'NewsListingView',
        components: {
            NewsCard,
            Pagination,
            TheAlert
        },
        data() {
            return {
                page: 1
            };
        },
        created() {
            this.initialiseUI();
        },
        computed: {
            ...mapState(useArticleStore, ['content']),
            ...mapState(useUserSettingsStore, ['myNewsChannels','feedsUpdatedAt']),
            buttonClasses() {
                return "btn btn-sm btn-block btn-secondary";
            },
            itemsCountPerPage() {
                return 9;
            },
            indexStart() {
                return (this.page - 1) * this.itemsCountPerPage;
            },
            indexEnd() {
                return this.indexStart + this.itemsCountPerPage;
            },
            paginated() {
                return this.content.slice(this.indexStart, this.indexEnd);
            }
        },
        methods: {
            ...mapActions(useArticleStore, ['getChannels']),
            ...mapActions(useAlertStore, ['showInformationAlert', 'showErrorAlert']),
            async initialiseUI() {
                let loader = this.$loading.show();
                try {
                    const lastRefresh = moment(this.feedsUpdatedAt);
                    const dateNow = moment();
                    const elapsedHours = dateNow.diff(lastRefresh, 'hours');
                    // Refresh feed data if more than 1 hour has passed
                    if (this.content.length === 0 || this.feedsUpdatedAt === undefined || elapsedHours > 1) {
                        await this.getChannels(true, this.myNewsChannels);
                    }
                } catch (error) {
                    this.showErrorAlert('Error: Unable to retrieve news article. Please try again later');
                }
                loader.hide();
            }
        },
    };
</script>

<style scoped>
    .settings-button {
        width:35px;
        background-color:#6699ff;
        color: #fff;
    }
</style>
