<template>
    <the-alert />
    <div>
        <h2>{{ this.$route.meta.title }} - {{getName}}</h2>
    </div>
    <digital-id></digital-id>
</template>

<script>
    import {mapActions, mapState} from 'pinia';
    import { useAlertStore } from '@/stores/alert.module';
    import TheAlert from '@/components/TheAlert.vue';
    import DigitalId from '@/components/MyPhoto.vue';
    import {useAuthenticationStore} from "@/stores/authentication.module";

    export default {
        name: 'MyPhotoView',
        components: {
            TheAlert,
            DigitalId,
        },
        setup() {}, // NEW wtf is this?
        created() {},
        mounted() {},
        computed: {
            ...mapState(useAuthenticationStore, ['isAdmin', 'getUsername', 'getName']),
        },
        methods: {
            ...mapActions(useAlertStore, ['showInformationAlert', 'showErrorAlert']),
        },
    };
</script>
