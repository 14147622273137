<template>
    <div class="carddesign card h-100">
        <div class="card-header" v-html="$strings('main.tiles.MyFeed.title')"></div>
        <div class="card-body">
            <div class="list-group mb-2">
                <a v-for="item in topContent" :key="item.link" :href="item.link" target="_blank" class="list-group-item list-group-item-action flex-column align-items-start">
                    <div class="d-flex w-100 justify-content-between">
                        <h6 class="mb-1" v-html="item.title"></h6>
                    </div>
                    <small>&ndash; Posted {{ formatDate(item.pubDate) }} in <b>{{item.channel?.name}}</b></small>
                </a>
            </div>
            <router-link class="btn btn-primary"
                :to="{ name: $strings('main.tiles.MyFeed.link') }">{{ $strings('main.tiles.MyFeed.linkTitle') }}&nbsp;<i class="bi bi-arrow-right"></i></router-link>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'pinia';
    import { useArticleStore } from '@/stores/article.module';
    import { useUserSettingsStore } from '@/stores/usersettings.module';
    import moment from 'moment';

    export default {
        name: 'MyFeedTile',
        created() {
            this.initialiseUI();
        },
        computed: {
            ...mapState(useArticleStore, ['topContent']),
            ...mapState(useUserSettingsStore, ['myNewsChannels','feedsUpdatedAt']),
        },
        methods: {
            ...mapActions(useArticleStore, ['getChannels']),
            formatDate(pubDate) {
                return moment(pubDate).fromNow();
            },
            async initialiseUI() {
                try {
                    const lastRefresh = moment(this.feedsUpdatedAt);
                    const dateNow = moment();
                    const elapsedHours = dateNow.diff(lastRefresh, 'hours');
                    // Refresh feed data if more than 1 hour has passed
                    if (this.topContent.length === 0 || this.feedsUpdatedAt === undefined || elapsedHours > 1) {
                        await this.getChannels(true, this.myNewsChannels);
                    }
                } catch (error) {
                    //this.showErrorAlert('Error: Unable to retrieve news article. Please try again later');
                }
            }
        }
    };
</script>
