// logger.js
// simple wrapper for application insights client package

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { useApplicationSettingsStore } from '@/stores/applicationsettings.module';

var Logger = (function() {
    let appInsights;
    const LOGLEVELINFORMATION = 1;
    const LOGLEVELWARNING = 2;
    const LOGLLEVELERROR = 3;

    function install(app) {
        const applicationSettingsStore = useApplicationSettingsStore();
        const connectionString = applicationSettingsStore.$state.applicationInsightsConnectionString;
        //console.log('connstring', connectionString);
        appInsights = new ApplicationInsights({
            config: {
                connectionString: connectionString,
                enableAutoRouteTracking: true,
            }
        });
        appInsights.loadAppInsights();
        appInsights.trackPageView();
        app.config.globalProperties.$logger = this;
    }
    function logMessage(message, severityLevel) {
        appInsights.trackTrace({ message: message, severityLevel: severityLevel });
    }
    function logInformation(message) {
        logMessage(message, LOGLEVELINFORMATION);
    }
    function logWarning(message) {
        logMessage(message, LOGLEVELWARNING);
    }
    function logError(message) {
        logMessage(message, LOGLLEVELERROR);
    }
    function logExceptionWarning(error) {
        logException(error, LOGLEVELWARNING);
    }
    function logExceptionInformation(error) {
        logException(error, LOGLEVELINFORMATION)
    }
    function logException(error, severityLevel) {
        appInsights.trackException({ exception: error, severityLevel: severityLevel });
    }
    function setTaggedUser(username) {
        appInsights.setAuthenticatedUserContext(username);
    }
    function clearTaggedUser() {
        appInsights.clearAuthenticatedUserContext();
    }

    return {
        install,
        logInformation,
        logWarning,
        logError,
        logExceptionInformation,
        logExceptionWarning,
        setTaggedUser,
        clearTaggedUser
    }
})();

export default Logger;
