<template>
    <div id="button-ical-dl">
        <button v-on:click="DownloadIcal" type="button" class="btn btn-primary">Export my timetable</button>
    </div>
</template>

<script>


//import {useTimetableDownload} from "@/stores/timetables-download.module.js";

import {mapActions} from "pinia";
import {useTimetableStore} from "@/stores/timetable.module";
import {useTimetableDownload} from "@/stores/timetables-download.module";

export default {
    name: "ICalDownloader",
    methods: {
        ...mapActions(useTimetableDownload, ['fetchICalStr']),
        DownloadIcal: function() {
            //https://www.raymondcamden.com/2020/12/15/vue-quick-shot-downloading-data-as-a-file
            //console.log("Download baby");
            let text = this.fetchICalStr(); //JSON.stringify(this.cats);
            let filename = 'myTimetable.ical';
            let element = document.createElement('a');
            element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(text));
            element.setAttribute('download', filename);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();
            document.body.removeChild(element);
        },
    },
}

</script>


<style scoped>
    #button-ical-dl {
        margin-top: 1em;
    }
</style>
