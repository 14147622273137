import axios from 'axios';
import { defineStore } from 'pinia';

export const useAdminTestStore = defineStore('adminTest', {
    state: () => ({
        adminData: undefined,
        loading: false
    }),
    actions: {
        async getAdminSettings() {
            this.loading = true;
            const response = await axios.get('/applicationsettings.json');
            this.adminData = {
                runId:  response.data.Application.BuildRunId,
                runName: response.data.Application.BuildRunName,
                sourceCommit: response.data.Application.BuildSourceCommit ,
                connectionString: response.data.ConnectionStrings.DbConnection,
                environment: response.data.Application.Environment,
                //accessToken: applicationSettings.Application.AccessToken, 
            };
            this.loading = false;
        },
    },
});
