<template>
    <button type="button" :class="buttonClasses" class="geo-button" v-show="viewType == 'map'" v-on:click="$emit('setCurrentPosition')"><i class="bi bi-geo-alt-fill"></i></button>&nbsp;
    <button type="button" :class="buttonClasses" class="view-button" v-on:click="$emit('toggleView')">{{ viewType == 'map' ? 'List View' : 'Map View' }}</button>&nbsp;
    <button type="button" :class="buttonClasses" class="reset-button" v-on:click="$emit('initialiseList')">Reset Filter</button>
</template>

<script>
    export default {
        name: 'MapToolbar',
        props: {
            viewType: String
        },
        computed: {
            buttonClasses() {
                return "btn btn-sm btn-block btn-secondary";
            }
        }
    };
</script>

<style scoped>
    .geo-button {
        width:35px;
        background-color:#6699ff;
    }
    .view-button {
        width:130px;
        background-color:#6699ff;
    }
    .reset-button {
        width:130px;
        background-color:#c70039;
    }
</style>