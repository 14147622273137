<template>
    <div id="appvue" class="clntap">
        <header>
            <TheHeader />
            <notifications v-if="showNotifications" position="bottom right" :duration="-1" @click="(msg) => handleNotifyDismiss(msg)"/>
            <!-- <TheErrorAlert /> -->
        </header>
        <main class="mb-5" :class="currentRouteClass">
            <div class="container">
                <router-view v-bind:key="$route.fullPath" />
            </div>
        </main>
        <footer>
            <TheFooter />
        </footer>
        <div v-if="loading" id="loading">
            <div class="spinner-border text-info" role="status">
                <span class="visually-hidden">Loading</span>
            </div>
        </div>
        <div v-if="dummyLog"></div>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import TheHeader from '@/components/TheHeader.vue';
    import TheFooter from '@/components/TheFooter.vue';
    // import TheErrorAlert from '@/components/TheErrorAlert.vue';
    import { useAdminTestStore } from '@/stores/admintest.module';
    import { useArticleStore } from '@/stores/article.module';
    import { useApplicationSettingsStore } from '@/stores/applicationsettings.module';
    import { useAuthenticationStore } from '@/stores/authentication.module';
    //import { useNotificationsStore } from '@/stores/notifications.module';
    //import { displayNotifications } from '@/helpers/notifications.helper';

    let adminTestStore;
    let articleStore;
    let applicationSettingsStore;

    export default {
        created() {
            adminTestStore = useAdminTestStore();
            articleStore = useArticleStore();
            applicationSettingsStore = useApplicationSettingsStore();
        },
        computed: {
            ...mapState(useAuthenticationStore, ['isLoggedIn']),
            //...mapState(useNotificationsStore, ['notifications','dismissedNotifications']),
            loading() {
                return adminTestStore.loading || applicationSettingsStore.isLoading || articleStore.isLoading;
            },
            currentRouteClass() {
                return this.$route.path.replace(/[^a-z0-9]/gi, '').toLowerCase();
            },
            dummyLog() {
                //console.log("====Page End=============================================================================");
                return false;
            },
            showNotifications() {
                return false;
            }
        },
        async updated() {},
        components: {
            TheHeader, TheFooter
            // TheErrorAlert
        },
        methods: {
            //...mapActions(useNotificationsStore, ['setDismissedNotification']),
            //...mapState(useNotificationsStore, ['getCurrentNotifications']),
            /* handleNotifyDismiss(msg) {
                this.setDismissedNotification(msg);
            }, */

            /* async handleNotificationDisplay() {
                this.$notify({ clean: true });
                await this.getCurrentNotifications();
                displayNotifications(this.$notify, this.notifications, this.dismissedNotifications);
            } */
        },
        watch: {
            // removed notifications as per ticket
            /* async isLoggedIn(newVal) {
                if (this.isLoggedIn) {
                    await this.handleNotificationDisplay();
                }
            }, */
            /* '$route.fullPath': async function(to, from) {
                if (this.isLoggedIn && from !== '/') {
                    await this.handleNotificationDisplay();
                }
            } */
        }
    };
</script>

<style>
    /* Global styles */
    body {
        background-color: white;
    }

    header {
        background-color: white;
    }

    main {
        /*padding: 30px;*/
        background-color: white;
        min-height: 300px;
    }

    #app {}

    #loading {
        top:0;
        left:0;
        width:100%;
        height:100%;
        position:absolute;
        background-color: darkgray;
        opacity:0.6;
    }
    #loading .spinner-border {
        position: absolute;
        left: 50vw;
        top: 40vh;
        opacity:1;
    }   
</style>
