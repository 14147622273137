
import {defineStore} from 'pinia';
import moment from 'moment';
import {useMyTimetableStore} from "@/stores/timetables.module";
import {useTimetableStore} from "@/stores/timetable.module";
//import { toRaw } from 'vue';

//import { useRoute } from 'vue-router';
//import {useIdCardInfoStore} from "@/stores/idcardinfo.module";
//import {useAuthenticationStore} from "@/stores/authentication.module";


export const useTimetableDownload = defineStore('timetabledownload', {

    state: () => ({
        timetablestore: useMyTimetableStore(),
        oldTimetableStore: useTimetableStore(),
        downloadData: null,
        lbr: "\n",
        lbr_esc: "\\n",
        downloadBuilt: false,
        iCalString: null,
    }),
    actions: {
        fetchICalStr: function() {
            this.buildCoursework(this.timetablestore.Activities);
            return this.iCalString;
        },
        buildCoursework: function(activities) {
            if (!this.downloadBuilt) {
                let self = this;
                // - get week events parsed correctly
                let startWeekNo = this.timetablestore?.physicalWeekNumber ? this.timetablestore.physicalWeekNumber : 0;
                let weekActivities = [];
                let cnt = 0;
                let evStr = "";
                let cntStr = "";
                let oneActivity = null;
                for (let weekNo = Math.max(startWeekNo - 1, 0); weekNo < 53; weekNo++) {
                    let weekActivityData = this.timetablestore.getWeekData(weekNo);
                    if (weekActivityData.length) {
                       //console("weekActivityData", weekActivityData);
                        weekActivityData.forEach(function (activity)
                        {
                            cnt++;
                            oneActivity = activity.Activity[0];
                            //console.log("activity: " , oneActivity);
                            evStr = evStr.concat(self.buildEvent(oneActivity, cnt));
                            cntStr = cntStr.concat("-" + cnt);
                        });
                    }
                    weekActivities[weekNo] = weekActivityData;
                }
                //console.log("WEEK ACTIVITIES: ", weekActivities);
                // - get today's date
                let dateToday = moment();

                // - set to midnight of today
                dateToday.hour(0).minute(0).second(0);
                const Header = this.buildFileHeader();
                const Footer = this.buildFileFooter();
                let iCalStr2 = "".concat(Header, evStr, Footer);
                this.iCalString = iCalStr2;
                this.downloadBuilt = true;
            }
            //console.log(this.iCalString);
            return this.iCalString;
        },
        buildEvent: function(ev, cnt) {
           //console("BUILDING " + cnt);
            let tsnow = moment();
            let createdDate = this.iCalDate(tsnow);
            /** exmaple structure
             * BEGIN:VCALENDAR
             * VERSION:2.0
             * PRODID:-//ZContent.net//Zap Calendar 1.0//EN
             * CALSCALE:GREGORIAN
             * METHOD:PUBLISH
                 * BEGIN:VEVENT
                 * SUMMARY:Abraham Lincoln
                 * UID:c7614cff-3549-4a00-9152-d25cc1fe077d
                 * SEQUENCE:0
                 * STATUS:CONFIRMED
                 * TRANSP:TRANSPARENT
                 * RRULE:FREQ=YEARLY;INTERVAL=1;BYMONTH=2;BYMONTHDAY=12
                 * DTSTART:20080212
                 * DTEND:20080213
                 * DTSTAMP:20150421T141403
                 * CATEGORIES:U.S. Presidents,Civil War People
                 * LOCATION:Hodgenville\, Kentucky
                 * GEO:37.5739497;-85.7399606
                 * DESCRIPTION:Born February 12\, 1809\nSixteenth President (1861-1865)\n\n\n\nhttp://AmericanHistoryCalendar.com
                 * URL:http://americanhistorycalendar.com/peoplecalendar/1,328-abraham-lincoln
                 * END:VEVENT
             * END:VCALENDAR
             */
            let eventStr = "BEGIN:VEVENT\n";
            eventStr = eventStr.concat("UID:", "NCL_TEACHING_2023_" + cnt, this.lbr);
            eventStr = eventStr.concat("CREATED:", createdDate, this.lbr);
            eventStr = eventStr.concat("DTSTAMP:", createdDate, this.lbr);
            eventStr = eventStr.concat("LAST-MODIFIED:", createdDate, this.lbr);
            eventStr = eventStr.concat("DTSTART:", this.iCalDate(ev.startMoment, true), this.lbr);
            eventStr = eventStr.concat("DTEND:", this.iCalDate(ev.endMoment, true), this.lbr);
            eventStr = eventStr.concat("SUMMARY:", this.escapeChars(ev.Name), this.lbr);
            eventStr = eventStr.concat("DESCRIPTION:", this.formatDescription(ev), this.lbr);
            eventStr = eventStr.concat("LOCATION:", this.formatLocation(ev), this.lbr);
            eventStr = eventStr.concat("END:VEVENT", this.lbr);
            //console.log(eventStr);
            return eventStr;
        },
        escapeChars: function(str) {
            let escapedString = str;
            if (typeof str === "string") {
                escapedString = str.replace(",", "\\,");
            }
            return escapedString;
        },
        //
        formatDescription: function(ev) {
            let description = "";
            if (ev.Description) {
                description = description.concat(ev.Description);
            }
            if (ev.ActivityType_Name) {
                description = description.concat(this.lbr_esc, "Type: ", this.oldTimetableStore.act_translate(ev.ActivityType_Name));
            }
            description = this.escapeChars(description.concat(""));
            return description;
        },
        formatLocation: function(ev) {
            let location = "";
            if (ev.Location?.Description) {
                location = location.concat(ev.Location.Description);
            }
            return this.escapeChars(location);
        },
        iCalDate: function(aMoment, debug = false) {
            if (typeof aMoment === "string") {
                aMoment = moment(aMoment)
            }
            let date = "" + aMoment.year();
            date = date.concat(String(aMoment.month() + 1).padStart(2,"0"));
            date = date.concat(String(aMoment.date()).padStart(2,"0"));
            date = date.concat("T");
            date = date.concat(String(aMoment.hour()).padStart(2,"0"));
            date = date.concat(String(aMoment.minute()).padStart(2,"0"));
            date = date.concat("00"); // - seconds
            if (debug && date === "20240201T153000") {
               //console("DATE:", date);
            }
            return date;
        },
        buildFileHeader: function() {
            let strings = "BEGIN:VCALENDAR\n";
            strings += 'CALSCALE:GREGORIAN\n';
            strings += 'PRODID:-//Newcastle University//Teaching Calendar//EN\n';
            strings += 'VERSION:2.0\n';
            return strings;
        },
        buildFileFooter: function() {
            const strings = "END:VCALENDAR";
            return strings;
        },
    },
});
