<template>
    <div class="clearfix header-container">
        <div class="float-start">
            <h2>{{ this.$route.meta.title }}</h2>
        </div>
        <div class="float-end">
            <MapToolbar 
                :viewType="viewType"
                @initialise-list="initialiseList"
                @set-current-position="setCurrentPosition"
                @toggle-view="toggleView" />
        </div>
    </div>
    <hr style="margin-bottom:0;">
    <MapContainer
        ref="CMapContainer"
        gMapRef="GMap"
        v-show="viewType == 'map'"
        @toggle-view="toggleView"
        @search-facilities="searchFacilities"
        :center="center"
        :zoom="zoom"
        :markers="activeMarkers" />
    <ListContainer
        ref="ListContain"
        v-show="viewType == 'list'"
        @toggle-view="toggleView"
        @show-building-facilities="showBuildingFacilities"
        :facilities="activeList" />
</template>

<script>
    import ListContainer from '@/components/campus-map/ListContainer.vue';
    import MapContainer from '@/components/campus-map/MapContainer.vue';
    import MapToolbar from '@/components/campus-map/MapToolbar.vue';

    import { mapState, mapActions } from 'pinia';
    import { useMapStore } from '@/stores/map.module';
    import {useAlertStore} from "@/stores/alert.module";

    export default {
        name: "CampusMapView",
        components: {
            MapContainer,
            ListContainer,
            MapToolbar
        },
        data() {
            return {
                viewType: 'map'
            }
        },
        created() {
            this.initialiseUI();
        },
        computed: {
            ...mapState(useMapStore, ['center', 'zoom', 'markers', 'activeMarkers', 'activeList'])
        },
        methods: {
            ...mapActions(useMapStore, ['getBuildings', 'getFacilities', 'initialiseList', 'searchList', 'setActiveMarkers', 'setMapCenterPoint', 'resetMap']),
            ...mapActions(useAlertStore, ['showInformationAlert', 'showErrorAlert', 'hideAlert']),
            async initialiseUI() {
                let loader = this.$loading.show();
                try {
                    await this.getBuildings();
                    await this.getFacilities();
                    this.initialiseList();
                    if (this.$route.query.buildingId && this.$route.query.type) {
                        this.setActiveMarkers(this.$route.query.buildingId, this.$route.query.type, this.$route.query.facilityId);
                    }
                } catch (error) {
                    this.showErrorAlert('Error: Unable to set up Campus Map');
                }
                loader.hide();
            },
            setCurrentPosition() {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        this.setMapCenterPoint(position.coords.latitude, position.coords.longitude);
                    },
                    () => {
                        //console.log('Unable to retrieve your location.');
                    },
                );
            },
            searchFacilities(id) {
                this.toggleView();
                this.searchList(id);
            },
            showBuildingFacilities(buildingId, type, facilityId) {
                this.toggleView();
                this.setActiveMarkers(buildingId, type, facilityId);
                if (facilityId !== undefined) {
                    this.$refs.CMapContainer.openMarker(facilityId);
                }
            },
            toggleView() {
                this.$refs.CMapContainer.openMarker(null);
                this.viewType = this.viewType == 'map' ? 'list' : 'map';
            }
        }
    };
</script>

<style>
    main.campusmapview {
        padding: 0px;
    }
</style>

<style scoped>
    .geo-button {
        width:35px;
        background-color:#6699ff;
        color: #fff;
    }
    .view-button {
        width:130px;
        background-color:#6699ff;
    }
    .reset-button {
        width:130px;
        background-color:#c70039;
    }
    .list-icon {
        height: 20px;
        width: 20px;
    }
    .facility-link {
        color: #000000;
        cursor: pointer;
        text-decoration: none;
    }
</style>
