// This store is to be used in conjunction with the Find-a-PC component

import apiHandler from '@/shared/apiHandler';
import {defineStore, mapActions} from 'pinia';
import config from '../../public/applicationsettings.json';
import moment from 'moment';
import {useAuthenticationStore} from "@/stores/authentication.module";
import {useApplicationSettingsStore} from "@/stores/applicationsettings.module";

// - all moved to within the action calls as there needs to be an active pinia for the call to useApplicationSettingsStore()
//let endpointURL = config.Endpoint.Talend.Url + config.Endpoint.Talend.Path;
//let ClusterAvailabilitiesEP = endpointURL + config.FindAPC.ClusterAvailabilitiesEP;
//let ClusterOpeningHoursEP = endpointURL + config.FindAPC.ClusterOpeningHoursEP;

const defaultState = {};

export const useClusterStore = defineStore('cluster', {
    state: () => ({
        isLoading: false,
        center: defaultState.center,
        zoom: defaultState.zoom,
        clusters: [],
        sortBy: 'name',
        sortOrder: 'asc',
    }),
    actions: {

        ...mapActions(useAuthenticationStore, ['buildAuthHeaders']),

        async initialise() {
            // get the clusters from the map module
            await this.getClusters();
            // append the machine usage stats to the clusters
            await this.updateClusterAvailability();
            // append opening hours data to the clusters
            await this.updateClusterOpeningHours();
        },
        async getClusters() {
            // Fetch facilities data
            let facilitiesResponse = [];
            await apiHandler.apiGet(config.CampusMap.LocationPropsEP)
            .then((result) => {
                facilitiesResponse = result.data?.collections[0]?.objects;
            }).catch((error) => {
                throw error;
            });
            
            let facilityMarkersObj = [];
            // Loop through buildings and append facilities data
            facilitiesResponse.map((obj) => {
                let facilityType = String(obj.type).toLowerCase();
                if (facilityType == 'clu') {
                    let propsObj = obj.properties.reduce(
                        (obj, item) => Object.assign(obj, { [item.key.toLowerCase()]: item.value }), {});
                    let markerObj = {
                        id: obj.externalId,
                        type: facilityType,
                        liveStats: {
                            bookingStatus: config.FindAPC.BookingStatus.NOT_BOOKED,
                            openingStatus: config.FindAPC.OpeningStatus.OPEN,
                            totalAvailable: 0,
                            totalActiveClients: 0
                        },
                    };
                    if (propsObj.name) { markerObj.name = propsObj.name }
                    let { latitude, longitude, name, accessible, ...typeSpecificData } = propsObj;
                    let markerObjMerged = {
                        ...markerObj,
                        ...typeSpecificData
                    };
                    if (facilityMarkersObj) {
                        facilityMarkersObj.push(markerObjMerged);
                    }
                }
            });
            facilityMarkersObj.sort((a, b) => a.displayname.localeCompare(b.displayname));
            let structuredArr = this.buildClusterHierarchy(facilityMarkersObj);
            this.clusters = structuredArr;
        },
        async updateClusterAvailability() {
            // Fetch availability data
            let clusterResponse = [];

            let token = await apiHandler.getSsoToken();
            let subscriptionKey = useApplicationSettingsStore().idfsSubscriptionKey;
            const authHeaders = this.buildAuthHeaders(token, subscriptionKey);

            // - set url, this changes in azure deploy depending on whether dev, or live/QA
            let endpointURL = config.Endpoint.Talend.Url + useApplicationSettingsStore().endpointTalendPath;
            let ClusterAvailabilitiesEP = endpointURL + config.FindAPC.ClusterAvailabilitiesEP;

            await apiHandler.apiGet(ClusterAvailabilitiesEP, authHeaders)
            .then((result) => {
                clusterResponse = result.data.cluster;
            }).catch((error) => {
                throw error;
            });

            // Loop through and append cluster availability data to clusters
            for (const cluster of this.clusters) {
                if (cluster.clusterid !== undefined && cluster.parent === undefined && cluster.children.length == 0) {
                    let clusterAvailability = clusterResponse.filter(obj => {
                        return obj.id == cluster.clusterid;
                    });
                    if (clusterAvailability.length) {
                        cluster.liveStats.availability = clusterAvailability[0];
                    }
                } else {
                    if (cluster.clusterid === undefined && cluster.children.length > 0) {
                        for (const subCluster of cluster.children) {
                            let subClusterAvailability = clusterResponse.filter(obj => {
                                return obj.id == subCluster.clusterid;
                            });
                            if (subClusterAvailability.length) {
                                subCluster.liveStats.availability = subClusterAvailability[0];
                            }
                        }
                    }
                }
            }
        },
        async updateClusterOpeningHours() {
            // Fetch opening hours data
            let OHResponse = [];
            
            let token = await apiHandler.getSsoToken();
            let subscriptionKey = useApplicationSettingsStore().idfsSubscriptionKey;
            const authHeaders = this.buildAuthHeaders(token, subscriptionKey);

            // - set url, this changes in azure deploy depending on whether dev, or live/QA
            let endpointURL = config.Endpoint.Talend.Url + useApplicationSettingsStore().endpointTalendPath;
            let ClusterOpeningHoursEP = endpointURL + config.FindAPC.ClusterOpeningHoursEP;

            await apiHandler.apiGet(ClusterOpeningHoursEP, authHeaders)
            .then((result) => {
                OHResponse = result.data?.openingHours;
            }).catch((error) => {
                throw error;
            });

            // Loop through clusters and append opening hours data
            for (const cluster of this.clusters) {
                if (cluster.clusterid !== undefined && cluster.parent === undefined && cluster.children.length == 0) {
                    let clusterOpeningHours = OHResponse.filter(obj => {
                        return obj.externalId == cluster.openinghours;
                    });
                    let clusterOHStatus = await this.getOpeningHoursStatus(clusterOpeningHours[0]);
                    if (cluster.liveStats) {
                        cluster.liveStats.openingStatus = clusterOHStatus.status;
                        cluster.liveStats.statusText = clusterOHStatus.statusText;
                    }
                } else {
                    if (cluster.clusterid === undefined && cluster.children.length > 0) {
                        let clusterOpeningHours = OHResponse.filter(obj => {
                            return obj.externalId == cluster.openinghours;
                        });
                        let clusterOHStatus = await this.getOpeningHoursStatus(clusterOpeningHours[0]);
                        if (cluster.liveStats) {
                            cluster.liveStats.openingStatus = clusterOHStatus.status;
                        }
                        for (const subCluster of cluster.children) {
                            let subClusterOpeningHours = OHResponse.filter(obj => {
                                return obj.externalId == subCluster.openinghours;
                            });
                            let subClusterOHStatus = await this.getOpeningHoursStatus(subClusterOpeningHours[0]);
                            if (subCluster.liveStats) {
                                subCluster.liveStats.openingStatus = subClusterOHStatus.status;
                                subCluster.liveStats.statusText = subClusterOHStatus.statusText;
                            }
                        }
                        let someClustersOpen = cluster.children.some(c => {
                            return c.liveStats?.openingStatus == config.FindAPC.OpeningStatus.OPEN;
                        });
                        if (someClustersOpen) {
                            cluster.liveStats.openingStatus = config.FindAPC.OpeningStatus.OPEN;
                        } else {
                            cluster.liveStats.openingStatus = config.FindAPC.OpeningStatus.CLOSED;
                        }
                    }
                }
            }
        },
        async updateClusterMachineTotals() {
            for (const cluster of this.clusters) {
                if (cluster.liveStats?.availability?.available) {
                    if (cluster.liveStats.openingStatus == config.FindAPC.OpeningStatus.CLOSED || cluster.liveStats.bookingStatus == config.FindAPC.BookingStatus.BOOKED) {
                        cluster.liveStats.totalAvailable = 0;
                        cluster.liveStats.totalActiveClients = 0;
                    } else {
                        cluster.liveStats.totalAvailable = cluster.liveStats?.availability?.available;
                        cluster.liveStats.totalActiveClients = cluster.liveStats?.availability?.activeClientCount;
                    }
                } else {
                    let availableCount = 0, activeClientCount = 0;
                    for (const c of cluster.children) {
                        if (c.liveStats.openingStatus != config.FindAPC.OpeningStatus.CLOSED && c.liveStats.bookingStatus != config.FindAPC.BookingStatus.BOOKED) {
                            availableCount = availableCount + parseInt(c.liveStats?.availability?.available);
                            activeClientCount = activeClientCount + parseInt(c.liveStats?.availability?.activeClientCount);
                        }
                    }
                    if (cluster.liveStats) {
                        cluster.liveStats.totalAvailable = availableCount;
                        cluster.liveStats.totalActiveClients = activeClientCount;
                    }
                }
            }
        },
        async updateClusterOverallStatus(clusters, parentBooked) {
            // loop through all of the clusters
            for (let cluster of clusters) {
                if (cluster.parent === undefined) {
                    if (cluster.liveStats.bookingStatus == config.FindAPC.BookingStatus.BOOKED) {
                        parentBooked = true;
                    } else {
                        parentBooked = false;
                    }
                }
                // set the booking status of child clusters
                await this.updateClusterOverallStatus(cluster.children, parentBooked);
                // see if any of the child clusters are booked
                cluster.liveStats.hasChildClusterBookings = false;
                if (cluster.parent === undefined) {
                    cluster.liveStats.hasChildClusterBookings = cluster.children.some(c => {
                        return c.liveStats?.bookingStatus == config.FindAPC.BookingStatus.BOOKED;
                    });
                }
                if (cluster.liveStats.openingStatus == config.FindAPC.OpeningStatus.OPEN) { // open today
                    if (cluster.parent && parentBooked) { // full-cluster booking
                        cluster.liveStats.headingStatusClass = "danger";
                        cluster.liveStats.headingTitleText = "BOOKED";
                        cluster.liveStats.parentBooked = true;
                    } else {
                        if (cluster.liveStats.bookingStatus == config.FindAPC.BookingStatus.NOT_BOOKED) { // not booked
                            cluster.liveStats.headingStatusClass = "success";
                            cluster.liveStats.headingTitleText = this.getClusterTotalsHeaderText(cluster);
                        } else if (cluster.liveStats.bookingStatus == config.FindAPC.BookingStatus.ABOUT_TO_BE_BOOKED) { // about to be booked
                            cluster.liveStats.headingStatusClass = "warning";
                            cluster.liveStats.headingTitleText = "BOOKED SOON";
                        } else if (cluster.liveStats.bookingStatus == config.FindAPC.BookingStatus.BOOKED) { // booked
                            cluster.liveStats.headingStatusClass = "danger";
                            cluster.liveStats.headingTitleText = "BOOKED";
                        }
                        if (cluster.children.length && cluster.hasChildClusterBookings) { // booked
                            cluster.liveStats.headingStatusClass = "danger";
                            cluster.liveStats.headingTitleText = "BOOKED";
                        }
                    }
                } else if (cluster.liveStats.openingStatus == config.FindAPC.OpeningStatus.ABOUT_TO_CLOSE) { //about to close
                    cluster.liveStats.headingStatusClass = "warning";
                    cluster.liveStats.headingTitleText = this.getClusterTotalsHeaderText(cluster);
                } else if (cluster.liveStats.openingStatus == config.FindAPC.OpeningStatus.CLOSED) { //closed
                    cluster.liveStats.headingStatusClass = "danger";
                    cluster.liveStats.headingTitleText = "CLOSED";
                } else { // default state
                    cluster.liveStats.headingStatusClass = "danger";
                    cluster.liveStats.headingTitleText = "CLOSED";
                }
            }
        },
        getClusterTotalsHeaderText(cluster) {
            if (cluster.liveStats?.availability?.available) {
                return cluster.liveStats?.availability?.available+' / '+cluster.liveStats?.availability?.activeClientCount;
            } else {
                let availableCount = 0, activeClientCount = 0;
                for (const c of cluster.children) {
                    if (c.liveStats.openingStatus != config.FindAPC.OpeningStatus.CLOSED && c.liveStats.bookingStatus != config.FindAPC.BookingStatus.BOOKED) {
                        availableCount = availableCount + parseInt(c.liveStats?.availability?.available);
                        activeClientCount = activeClientCount + parseInt(c.liveStats?.availability?.activeClientCount);
                    }
                }
                return availableCount+' / '+activeClientCount;
            }
        },
        async getOpeningHoursStatus(oh){
            if(oh!==undefined){
                let wr = oh.weekRules[0];
                let wds = wr.weekDays;
                let now = moment();
                let nowsFullDate = now.format("YYYY-MM-DD");
                let nowsDay = now.day()==0?7:now.day();
                let wdslen = wds.length;
                for(let wdsi=wdslen;wdsi--;){
                    let wd = wds[wdsi];
                    if(parseInt(wd.day)===nowsDay){
                        let hs = wd.hours;
                        let hslen = hs.length;
                        for(let hi=hslen;hi--;){
                            let h = hs[hi];
                            let open = null;
                            if(h.open!==null){let openTime = h.open.split(":");open = moment(nowsFullDate+" "+openTime[0]+":"+openTime[1],"YYYY-MM-DD H:m");}
                            let close = null;
                            if(h.close!==null){let closeTime = h.close.split(":");close = moment(nowsFullDate+" "+closeTime[0]+":"+closeTime[1],"YYYY-MM-DD H:m");}
                            if(open===null){
                                if(close===null){
                                    return {status: config.FindAPC.OpeningStatus.OPEN, statusText: "Open and does not close today"};
                                    /* get next day
                                     loop the week if necessary
                                     needs to account for exceptions*/
                                }else if(now<close){
                                    return {status: config.FindAPC.OpeningStatus.OPEN, statusText: "Open until "+close.format("h:mma")};
                                }else{
                                    return {status: config.FindAPC.OpeningStatus.CLOSED, statusText: "Closed now for today"};
                                    /* get next day
                                     loop the week if necessary
                                     needs to account for exceptions*/
                                }
                            }else if(now<open){ /*not open today yet*/
                                return {status: config.FindAPC.OpeningStatus.CLOSED, statusText: "Closed until "+open.format("h:mma")};
                                /* get next day
                                 loop the week if necessary
                                 needs to account for exceptions*/
                            }else if(close===null){
                                return {status: config.FindAPC.OpeningStatus.OPEN, statusText: "Open and does not close today"};
                            }else if(now>close){ /*closed for today*/
                                return {status: config.FindAPC.OpeningStatus.CLOSED, statusText: "Closed now for today"};
                                /* get next day
                                 loop the week if necessary
                                 needs to account for exceptions*/
                            }else{ /*open but will close*/
                                return {status: config.FindAPC.OpeningStatus.OPEN, statusText: "Open until "+close.format("h:mma")}; /*unless close time doesn't exist and you need to loop!*/
                            }
                        }
                        break;
                    }
                }
                return {status: config.FindAPC.OpeningStatus.CLOSED, statusText: "Closed today"};
            }else{
                return {status: config.FindAPC.OpeningStatus.CLOSED, statusText: "Opening hours unavailable"};
            }
        },
        sortClusterList(sortBy, sortOrder) {
            this.sortBy = sortBy;
            if (sortOrder) { this.sortOrder = sortOrder; }
            if (this.sortBy == 'name') {
                if (this.sortOrder == 'asc') {
                    this.clusters.sort((a, b) => a.displayname.localeCompare(b.displayname));
                } else {
                    this.clusters.sort((b, a) => a.displayname.localeCompare(b.displayname));
                }
            }
            if (sortBy == 'availability') {
                if (this.sortOrder == 'asc') {
                    this.clusters.sort((a, b) => a.liveStats.totalAvailable-b.liveStats.totalAvailable);
                } else {
                    this.clusters.sort((b, a) => a.liveStats.totalAvailable-b.liveStats.totalAvailable);
                }
            }
        },
        changeClusterSortOrder() {
            if (this.sortOrder == 'asc') {
                this.sortOrder = 'desc';
                this.sortClusterList(this.sortBy, this.sortOrder);
            } else {
                this.sortOrder = 'asc';
                this.sortClusterList(this.sortBy, this.sortOrder);
            }
        },
        buildClusterHierarchy(clusters) {
            let clusterResults = [];
            for (const cluster of clusters) {
                if (cluster.children) {
                    let subClusterIds = cluster.children.split(',');
                    let subClusters = clusters.filter((c) => {
                        return subClusterIds.some((f) => {
                          return f == c.id;
                        });
                      });
                    cluster.children = subClusters;
                } else {
                    cluster.children = [];
                }
                if (cluster.parent === undefined) {
                    clusterResults.push(cluster);
                }
            }
            return clusterResults;
        },
        flatToHierarchy(arr) {
            let tree = [],
            mappedArr = {},
            arrElem;

            // Map nodes of array to an object
            for (let i = 0, len = arr.length; i < len; i++) {
                arrElem = arr[i];
                mappedArr[arrElem.id] = arrElem;
                mappedArr[arrElem.id]['children'] = [];
            }

            for (const [id, obj] of Object.entries(mappedArr)) {
                if (Object.prototype.hasOwnProperty.call(obj, "id")) {
                    // If the element is not at the root level, add it to its parent array of children
                    if (obj.parent) {
                        if (mappedArr[obj.parent] !== undefined && Object.prototype.hasOwnProperty.call(mappedArr[obj.parent], "children")) {
                            mappedArr[obj.parent]['children'].push(obj);
                        }
                    }
                    // If the element is at the root level, add it to top level elements array
                    else {
                        tree.push(obj);
                    }
                }
            }
            return tree;
        },
    },
});
