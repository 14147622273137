<template>
    <div class="carddesign card h-100">
        <div class="card-body">
            <h5 class="card-title" v-html="name"></h5>
            <h6 class="card-subtitle mb-2 text-muted" v-html="position"></h6>
            <p class="card-text" v-html="department"></p>
            <a :href="`tel:${telephone}`" class="btn btn-primary" v-if="restricted && restricted === 'false' && telephone">Call&nbsp;<i class="bi bi-telephone"></i></a>&nbsp;
            <a :href="`mailto:${email}`" class="btn btn-primary" v-if="restricted && restricted === 'false' && email">Email&nbsp;<i class="bi bi-envelope"></i></a>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'StaffCard',
        data() {
            return {}
        },
        props: {
            name: String,
            department: String,
            position: String,
            email: String,
            telephone: String,
            restricted: Boolean
        },
        methods: {}
    };
</script>

<style scoped></style>
