<!--
The actual display of the IdCard
    This should be called from/dropped into DigitalIdView
-->
<template>
    <section id="digital-id" class="welcome_area p_120">
        <hr />

        <!-- TODO Check what is sent if barcode has not been set (exists but empty/null, or just isn't in object? -->
        <div  v-if="showPhotoUploadLink">
            <div v-if="fullCard">
                <router-link :to="{ name: 'CameraView2' }">Upload ID Photo</router-link>
            </div>
            <div v-else>
                <div v-if="userCanUpload">
                    <router-link id="upload-link" :to="{ name: 'SelectPhoto' }">
                    <button id="upload-link-btn" class="btn btn-primary button next" >
                        Tap to select a new ID Photo
                    </button>
                    </router-link>
                </div>
            </div>
        </div>

        <section v-if="fullCard" id="personal-idcard">
            <div v-if="isInitialisingUI">Initialising</div>
            <div v-else-if="idCardDataGood" id="idcard-container" class="container">
                <div id="digitalid-main" class="row">
                    <div id="digitalid-body" class="col-sm-11 col-xs-12">
                        <div id="digitalid-content">
                            <!-- LOGO -->
                            <div id="digitalid-nulogo"><img src="/img/nulogo.svg" alt="Newcastle University Logo" /></div>

                            <!-- Details -->
                            <div v-if="idCardDataGood" id="digitalid-details-all">

                                <!-- Photo -->
                                <div v-if="displayPhoto" id="digitalid-photo">
                                    <img :src="IdCardPhotoData" class="thumb digital-id-pic" alt="Cardholders photograph" />
                                    <div id="digitalid-nophoto" v-if="IdCardPhotoMissing">
                                        Could not load your photo
                                    </div>
                                </div>
                                <div v-else id="digitalid-photo"><img src="/img/img_missing.png" class="thumb" alt="photo missing" /></div>

                                <!-- Details -->
                                <div id="digitalid-details">
                                    <!-- Name -->
                                    <div id="idcard-name" class="digitalid-details"><span v-html="`${idCardInfo.knownAs}` + ' ' + `${idCardInfo.lastName}`" class="details-value"></span></div>

                                    <!-- Staff/Student Number -->
                                    <div id="digitalid-number" class="digitalid-details">
                                        <span class="infolabel" v-html="`${idCardInfo.type}` + ' No:'"></span>
                                        <span v-html="`${getIdNumber}`"></span>
                                    </div>

                                    <!-- Exp Date -->
                                    <div id="digitalid-expiry" class="digitalid-details">
                                        <span class="infolabel">Expires:</span>
                                        <span v-html="`${idCardInfo.expiryDate}`"></span>
                                    </div>

                                    <!-- Barcode -->
                                    <div id="digitalid-barcode" v-if="idCardInfo.barcode">
                                        <div  class="barcode">*{{ idCardInfo.barcode }}*</div>
                                        <div class="barcode-text">
                                            {{ idCardInfo.barcode }}
                                        </div>
                                    </div>
                                    <div class="barcode-text missing" v-else>[Awaiting Barcode]</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="digitalid-sidebar" :class="`${idCardInfo.class}` + ' col-sm-1 col-xs-12' ">
                        <div id="digitalid-sidebar-content" v-html="`${idCardInfo.type}`"></div>
                        <div id="digitalid-sidebar-endcontent">
                            <div id="digitalid-sidebar-racingstripes">
                                <div id="digitalid-sidebar-racingstripe" class="racing-stripe"></div>
                                <div id="digitalid-sidebar-racingstripe2" class="racing-stripe"></div>
                            </div>
                            <div id="student-only" v-if="isStudent">
                                <div id="isapg" class="ug-or-pg" v-if="isPG !== false">{{isPG}}</div>
                                <div id="isaug" class="ug-or-pg" v-else-if="isUG !== false">{{isUG}}</div>
                            </div>
                            <div id="staff-only" class="ug-or-pg" v-else>&nbsp;</div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="digital-id-error">Sorry, we could not retrieve your Digital Id information.</div>
        </section>

        <section v-else id="personal-idcard2">
            <!-- Photo -->
            <div v-if="displayPhoto" id="digitalid-photo2">
                <img :src="IdCardPhotoData" class="thumb digital-id-pic" alt="Cardholders photograph" />
                <div id="digitalid-nophoto" v-if="IdCardPhotoMissing">
                    Could not load your photo
                </div>
            </div>
            <div v-else id="digitalid-photo"><img src="/img/img_missing.png" class="thumb thumb2" alt="photo missing" /></div>
            <div id="digitalid-details2">
                <!-- Name -->
                <div id="idcard-name" class="digitalid-details"><span v-html="`${idCardInfo.knownAs}` + ' ' + `${idCardInfo.lastName}`" class="details-value"></span></div>

                <!-- Staff/Student Number -->
                <div id="digitalid-number" class="digitalid-details">
                    <span class="infolabel" v-html="`${idCardInfo.type}` + ' No:'"></span>
                    <span v-html="`${getIdNumber}`"></span>
                </div>

                <!-- Exp Date -->
                <div id="digitalid-expiry" class="digitalid-details">
                    <span class="infolabel">Expires:</span>
                    <span v-html="`${idCardInfo.expiryDate || '[no data]'}`"></span>
                </div>

            </div>
        </section>


        <div v-if="fullCard" id="digitalid-disclaimer" class="attention">
            Disclaimer - this is a <em>Digital ID card</em> and as such WILL NOT open doors with electronic locks, or allow access to restricted areas such as libraries or study-spaces.
        </div>


        <div id="digitalid-reprint-info">
            If your physical Smartcard has been lost or stolen, reprints are available for a small charge from The Philip Robinson Library.
            <br />
            Read more about your <a href="https://services.ncl.ac.uk/itservice/core-services/smartcards/">Smartcard</a>
        </div>
    </section>
</template>


<!--
The shizzles fo' the fizzles
-->
<script>
    import { mapState, mapActions } from 'pinia';
    import {useIdCardInfoStore} from '@/stores/idcardinfo.module';
    import {useAlertStore} from "@/stores/alert.module";
    import {useAuthenticationStore} from "@/stores/authentication.module";
    // import PhotoUpload from "@/components/UploadPhoto.vue";

    export default {
        components: {/*PhotoUpload*/},
        data() {
            return {
                IDCardDataError: false,
            }
        },
        name: "DigitalId",
        created() {
            this.initialiseUI();
            this.missingImg = true;
            this.IDCardDataError = false;
            this.IDCardPhotoError = false;
        },
        computed: {
            ...mapState(useIdCardInfoStore,['idCardPhoto', 'idCardRecord']),
            ...mapState(useAuthenticationStore, ['isAdmin', 'getUsername', 'getName']),
            currentRouteName() {
                return this.$route.name;
            },
            IdCardPhotoData() {
                let img = "/img/img_missing.png";
                if (this.idCardPhoto?.src) {
                    img = this.idCardPhoto.src;
                }
                return img;
            },
            displayPhoto() {
                return this.idCardPhoto?.display ?? false;
            },
            idCardDataGood() {
                return this.IDCardDataError === false;
            },
            idCardInfo() {
                return this.idCardRecord ? this.idCardRecord : false;
            },
            isPG() {
                // console.log('isPG: ' + this.idCardRecord.isPG);
                return this.idCardRecord?.isPG === true ? "PG" : false;
            },
            isUG() {
                // console.log('isUG: ' + this.idCardRecord.isUG);
                return this.idCardRecord?.isUG === true ? "UG" : false;
            },
            isStudent() {
                let retval = false;
                if(this.idCardRecord?.type) {
                    retval = this.idCardRecord?.type.toLowerCase() === "student";
                }
                return retval;
            },
            getIdNumber() {
                if(this.idCardRecord?.type.toLowerCase() === "staff") {
                    return this.idCardInfo.staffNumber || "[no data]";
                } else if (this.idCardRecord?.type.toLowerCase() === "student") {
                    return this.idCardInfo.studentNumber || "[no data]";
                } else {
                    return "invalid record type";
                }
            },
            IdCardPhotoMissing() {
                return this.isCardPhotoMissing();
            },
            isInitialisingUI: function() {
                return this.isInitialisingUI;
            },
            showPhotoUploadLink: function() {
                // - Master selector. Shows Upload link - over-rides 'userCanUpload' button on picture display screen (#1)
                //console.log("showPhotoUploadLink? " + this.idCardInfo?.photoEligible + " " + typeof this.idCardInfo?.photoEligible);
                return /* this.isAdmin ||  */this.idCardInfo?.photoEligible;
            },
            /**
             * Use this to switch between original digital ID view (not liked by library)
             * and the 'new' option/functionality of just uploading a photo for use within the physical ID card.
             * @returns {boolean}
             */
            fullCard: function() {
                return false;
            },
            userCanUpload: function() {
                // - displays 'upload-link-btn' or not. Over-ridden by 'showPhotoUploadLink' above
                //let uname = this.getUsername; // - may or may not be required
                console.log("Upload available? " + this.idCardInfo?.photoEligible + " " + typeof this.idCardInfo?.photoEligible, this.idCardInfo);
                return /* this.isAdmin ||  */this.idCardInfo?.photoEligible;
            },
        },
        methods: {
            ...mapActions(useIdCardInfoStore,['getIdCardPic', 'getIdCardRecord', 'fetchUserPic']),
            ...mapActions(useAlertStore, ['showInformationAlert', 'showErrorAlert', 'hideAlert']),
            async initialiseUI() {
                let loader = this.$loading.show();
                this.initialisingUI = true;

                // Digital Id data
                try {
                    let userToGet = this.getUsername;
                    //console.log("JEANTEST USERNAME: " + userToGet);
                    await this.getIdCardRecord(userToGet);
                } catch (error) {
                    this.IDCardDataError = true;
                    this.showErrorAlert('Error: Unable to retrieve your ID information');
                    //console.log("Unable to retrieve your ID information - error", error);
                }

                // Digital Id picture
                try {
                    await this.fetchUserPic(this.getUsername);
                } catch  (e) {
                    //console.log("Nope, 'fetchUserPic' got the error " + e);
                }

                this.initialisingUI = false;
                loader.hide();
            },
            getUserIdNumber: function() {
                if(this.idCardRecord?.type.toLowerCase() === "staff") {
                    //console.log("STAFF!!");
                    //return "S" + this.idCardInfo.staffNumber;
                    return this.idCardInfo.staffNumber;
                } else {
                    //console.log("STUDENT!!");
                    return this.idCardInfo.studentNumber;
                }
            },
            isCardPhotoMissing() {
                if (this.missingImg !== undefined && this.idCardPhoto.src) {
                    this.missingImg = false;
                }
                return this.missingImg;
            },
        },
    };

</script>

<style scoped>
</style>
<style>
    /*
    @import '../assets/digitalid.css';
    @import '../assets/barcodefont.css';
    */
    @import '../assets/idphoto.css';
</style>


