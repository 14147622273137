import { useAuthenticationStore } from '@/stores/authentication.module';
import {createRouter, createWebHistory} from 'vue-router';
import { useAlertStore } from '@/stores/alert.module';
import strings from '@/shared/strings.json';
import axios from "axios";

// Components:
import AboutView from '@/views/AboutView.vue';
import AdminTestView from '@/views/AdminTestView.vue';
import ArticleRecordView from '@/views/ArticleRecordView.vue';
import ArticleSummaryView from '@/views/ArticleSummaryView.vue';
import CameraView2 from "@/views/CameraView2.vue";
import CampusMapView from '@/views/CampusMapView.vue';
//import CropperView from "@/views/CropperView.vue";
import DirectoryView from '@/views/DirectoryView.vue';
import FindAPCView from '@/views/FindAPCView.vue';
import HelpView from '@/views/HelpView.vue';
import LibraryView from '@/views/LibraryView.vue';
import LoggedoutView from "@/views/LoggedoutView.vue";
import MainView from '@/views/MainView.vue';
//import MyPhotoView from '@/views/MyPhotoView.vue';
import NewsListingView from '@/views/NewsListingView.vue';
import PersonalTutorView from '@/views/PersonalTutorView.vue';
import PrivacyView from '@/views/PrivacyView.vue';
import SearchView from '@/views/SearchView.vue';
import SelectPhoto from "@/components/SelectPhoto.vue";
import StudySpaceView from '@/views/StudySpaceView.vue';
import TimetableView from '@/views/TimetableView.vue';
import UserSettingsView from '@/views/UserSettingsView.vue';
import MyPhotoView from '../views/MyPhotoView.vue';


const systemTitle = strings.application.title;

const routes = [
    /*{
        path: '/:pathMatch(.*)*',
        name: 'Path-not-found',
        component: MainView,
    },*/
    {
        path: '/',
        name: 'MainView',
        component: MainView,
        meta: {
            title: 'Home',
            allowAnonymous: true,
            titlenotloggedin: 'Login',
        },
        /* beforeEnter: () => {
            //window.location.href = "https://nu-studentportal-dev2.powerappsportals.com/dashboard/";
        } */
    },
    {
        path: '/Admin',
        name: 'AdminTestView',
        component: AdminTestView,
        meta: {
            title: 'Admin',
            admin: true,
            allowAnonymous: false
        },
    },
    {
        path: '/About',
        name: 'AboutView',
        component: AboutView,
        meta: {
            title: 'About',
            allowAnonymous: true
        },
    },
    {
        path: '/Article',
        name: 'ArticleSummaryView',
        component: ArticleSummaryView,
        meta: {
            title: 'Article Summary',
            allowAnonymous: false
        },
    },
    {
        path: '/Article/:id',
        name: 'ArticleRecordView',
        component: ArticleRecordView,
        meta: {
            title: 'Article Record',
            allowAnonymous: false
        },
    },
    {
        path: '/Camera2',
        name: 'CameraView2',
        component: CameraView2,
        meta: {
            title: 'Upload Photo',
            allowAnonymous: false
        },
    },
    {
        path: '/CampusMapView',
        name: 'CampusMapView',
        component: CampusMapView,
        meta: {
            title: 'Campus Map',
            allowAnonymous: true,
        },
    },
    {
        path: '/DirectoryView',
        name: 'DirectoryView',
        component: DirectoryView,
        meta: {
            title: 'Staff Directory',
            allowAnonymous: false
        },
    },
    {
        path: '/FindAPCView',
        name: 'FindAPCView',
        component: FindAPCView,
        meta: {title: 'Find a PC',
            allowAnonymous: true
        },
    },
    {
        path: '/Help',
        name: 'HelpView',
        component: HelpView,
        meta: {
            title: 'Help and Feedback',
            allowAnonymous: false
        },
    },
    {
        path: '/Library',
        name: 'LibraryView',
        component: LibraryView,
        meta: {
            title: 'My Library Account',
            allowAnonymous: false
        },
    },
    {
        path: '/Login',
        name: 'LoggedoutView',
        component: LoggedoutView,
        meta: {
            title: 'My Library Account',
            allowAnonymous: false
        },
    },
    {
        /* path: '/DigitalId', */
        /* name: 'DigitalIdView', */
        path: '/MyPhoto',
        name: 'MyPhoto',
        component: () => import('../views/MyPhotoView.vue'),
        /* component: MyPhotoView, */
        meta: {
            title: 'My Photo',
            allowAnonymous: false
        },
    },
    {
        path: '/NewsEvents',
        name: 'NewsListingView',
        component: NewsListingView,
        meta: {
            title: 'News and Events',
            allowAnonymous: false
        },
    },
    {
        path: '/PersonalTutor',
        name: 'PersonalTutorView',
        component: PersonalTutorView,
        meta: {
            title: 'Personal Tutor',
            allowAnonymous: false,
        },
    },
    {
        path: '/Privacy',
        name: 'PrivacyView',
        component: PrivacyView,
        meta: {
            title: 'Privacy Policy',
            allowAnonymous: true,
        }
    },
    {
        path: '/Search',
        name: 'SearchView',
        component: SearchView,
        meta: {
            title: 'Search',
            allowAnonymous: false
        },
    },
    {
        path: '/SelectPhoto',
        name: 'SelectPhoto',
        component: SelectPhoto,
        meta: {
            title: 'Select Photo',
            allowAnonymous: false
        },
    },
    {
        path: '/Settings',
        name: 'UserSettingsView',
        component: UserSettingsView,
        meta: {
            title: 'Settings',
            allowAnonymous: false
        },
    },
    {
        path: '/StudySpace',
        name: 'StudySpaceView',
        component: StudySpaceView,
        meta: {
            title: 'Check Study Space',
            allowAnonymous: false
        },
    },
    {
        path: '/Timetable',
        name: 'TimetableView',
        component: TimetableView,
        meta: {
            title: 'My Timetable',
            allowAnonymous: false
        }
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
    linkActiveClass: 'active'
});

const portalUrl = "https://nu-studentportal-dev2.powerappsportals.com/dashboard/";

router.beforeEach((to) => {

    //console.log("Before each");

    //console.log("Allow anon ", to.meta.allowAnonymous);
    // redirect calls to home page back to the portal
    if(to.name === "MainView") {
        //window.location.href = "https://nu-studentportal-dev2.powerappsportals.com/dashboard/";
    } else if (to.name === "LoggedoutView") {
        return true;
    }

    //close any previous alerts when we switch routes.
    const alertStore = useAlertStore();
    alertStore.hideAlert();

    // Allow unrestricted access to routes labelled as anonymous when signed out:
    if (to.meta.allowAnonymous) {
        return true;
    }


    const authenticationStore = useAuthenticationStore();

    //console.log("JEANTEST authenticationStore:", authenticationStore.account);
    //console.log("JEANTEST account.idTokenClaims.name", authenticationStore.account?.idTokenClaims?.name);
    const now = new Date();
    let timeNow = now.getHours() + ":" + now.getMinutes();
    const nowTS = Math.floor(now.getTime() / 1000);
    const claimExp = authenticationStore.account?.idTokenClaims?.exp;
    let claimExpTime = new Date(claimExp * 1000);
    let claimExpTimeFmt = claimExpTime.getHours() + ":" + claimExpTime.getMinutes() + " " + claimExpTime.getDate() + "/" + claimExpTime.getMonth();
    //console.log(timeNow + " - Token Expiry in " + Math.round((claimExp - nowTS) / 60) + " mins, at " + claimExpTimeFmt);
    //console.log("claimExp: " + claimExp + " now: " + nowTS);
    let tokenExpired = false;
    //eslint-disable-next-line no-constant-condition
    if (!claimExp || claimExp <= nowTS) { // (claimExp - 30 * 60) <= nowTS) {
        //console.log("OH NOES!!!!! Need new claims token");
        tokenExpired = true;
        //router.push('/Login');
        //authenticationStore.logout();
        //window.location.href = "portalUrl";
        //window.location.href = "https://www.google.com";
    } else {
        //console.log("Oh Yaaaas***** Claims token is OK");
    }


    if (to.meta.admin) {
        return authenticationStore.isAdmin;
    }

    // Ensure that only logged-in users with the correct role can access any other routes:
    return authenticationStore.isLoggedIn; // && !tokenExpired;
});

router.afterEach((to) => {
    // Apply page titles from route meta:
    //document.title = to.meta.title ? `${systemTitle}: ${to.meta.title}` : systemTitle;
    document.title = to.meta.title ? `${to.meta.title}` : systemTitle;
});

export default router;
