<template>
    <div class="header-container">
        <h2>Article Summary View</h2>
    </div>
    <the-alert />
    <div>
        Records loaded: {{totalArticleCount}}
        <h5>{{articleDefinition.tableHeader}}</h5>
        <h6>{{articleDefinition.tableSubHeader}}</h6>
        <router-link class="btn btn-primary" :to="{ name: 'ArticleRecordView', params: { id: 0 }}">{{ $strings('application.createButton') }}</router-link>
        <the-table :tableData="article" :tableHeaders="articleDefinition.tableHeaders" :dataKey="articleDefinition.dataKey" :endpoint="articleDefinition.endpoint" :view="articleDefinition.view"></the-table>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'pinia';
    import { useArticleStore } from '@/stores/article.module';
    import { useAlertStore } from '@/stores/alert.module';
    import TheAlert from '@/components/TheAlert.vue';
    import TheTable from '@/components/TheTable.vue';
    import articleDefinition from '@/shared/tableDefinitions/article.json';

    export default {
        name: 'ArticleSummaryView',
        components: {
            TheAlert,
            TheTable,
        },
        data() {
            return {
                articleDefinition: {
                    tableHeader: this.$strings('article.summaryHeader'),
                    tableSubHeader: this.$strings('article.summarySubHeader'),
                    dataKey: articleDefinition.dataKey,
                    endpoint: articleDefinition.endpoint,
                    tableHeaders: articleDefinition.tableHeaders,
                    view: articleDefinition.view,
                },
                isInitialisingUI: false,
            };
        },
        created() {
            this.initialiseUI();
            if (this.$route?.query?.delete) {
                this.showInformationAlert('Record deleted');
            }
        },
        computed: {
            ...mapState(useArticleStore, ['article', 'totalArticleCount']),
            itemsCountPerPage() {
                return 10;
                //   return this.$store.state.applicationSettings.itemsCountPerPage;
            },
            tableIsBusy() {
                return false;
                //   return this.$store.state.congregations.isLoading
                //   || this.$store.state.congregations.isDownloadingFile;
            },
        },
        methods: {
            ...mapActions(useArticleStore, ['getArticle']),
            ...mapActions(useAlertStore, ['showInformationAlert', 'showErrorAlert']),
            async initialiseUI() {
                this.isInitialisingUI = true;

                try {
                    await this.getArticle();
                } catch (error) {
                    this.showErrorAlert('Error: An unknown error occurred');
                }

                this.isInitialisingUI = false;
            },
            formatDate(val) {
                return new Date(val).toLocaleDateString(navigator.language);
            },
        },
    };
</script>
