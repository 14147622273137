<template>
    <div id="mapViewContainer">
        <GMapMap
            :ref="gMapRef"
            :center="center"
            :zoom="zoom"
            style="width: 100%; height: 60vh"
            :options="{
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false
            }"
        >
            <GMapMarker
                v-for="(m, i) in markers"
                :icon="m.icon"
                :key="i"
                :position="m.position"
                :visible="m.visible"
                :clickable="m.clickable"
                :draggable="false"
                @click="openMarker(m.id)"
                @closeclick="openMarker(null)"
            >
                <GMapInfoWindow
                    :closeclick="true"
                    @closeclick="openMarker(null)"
                    :opened="openedMarkerID === m.id"
                >
                    <div>
                        <div v-if="m.type === 'usr'">
                            <span>You are here</span>
                        </div>
                        <div v-if="m.type === 'bld'">
                            <span v-html="m.name"></span><br>
                            <a @click="$emit('search-facilities', m.id)"><u>View facilities</u></a>
                        </div>
                        <div v-if="m.type === 'ent' && m.accessible === false">
                            <span v-html="`Entrance: `+m.name"></span>
                        </div>
                        <div v-if="m.type === 'ent' && m.accessible === true">
                            <span v-html="`Accessible Entrance: `+m.name"></span>
                        </div>
                        <div v-if="m.type === 'clu'">
                            <span v-html="`Cluster: `+m.displayname"></span><br>
                            <router-link :to="{name: 'FindAPCView'}">Return to Find a PC</router-link>
                        </div>
                        <div v-if="m.type === 'fod'">
                            <span v-html="`Food/Drink: `+m.name"></span>
                        </div>
                        <div v-if="m.type === 'mcw'">
                            <span v-html="`Microwave: `+m.name"></span>
                        </div>
                        <div v-if="m.type === 'nrs'">
                            <span v-html="`Nursing Room: `+m.name"></span>
                        </div>
                        <div v-if="m.type === 'pry'">
                            <span v-html="`Quiet/Prayer Space: `+m.name"></span>
                        </div>
                        <div v-if="m.type === 'shr'">
                            <span v-html="`Shower: `+m.name"></span>
                        </div>
                    </div>
                </GMapInfoWindow>
            </GMapMarker>
        </GMapMap>
    </div>
</template>

<script>
    export default {
        name: "MapContainer",
        props: {
            markers: Array,
            center: Object,
            zoom: Number,
            gMapRef: String
        },
        data() {
            return {
                openedMarkerID: null
            }
        },
        mounted() {
            this.$refs[this.gMapRef].$mapPromise.then((map) => {
                map.setOptions({
                    styles: [
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }],
                        },
                        {
                            featureType: 'poi.school',
                            stylers: [{ visibility: 'off' }],
                        },
                        {
                            featureType: 'transit',
                            elementType: 'labels.icon',
                            stylers: [{ visibility: 'off' }],
                        },
                    ]
                });
            });
        },
        methods: {
            openMarker(id) {
                this.openedMarkerID = id;
            }
        }
    };
</script>