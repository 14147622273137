import { defineStore } from 'pinia';
import config from '../../public/applicationsettings.json';

export const useUserSettingsStore = defineStore('userSettings', {
    state: () => ({
        myHomeScreenTiles: undefined,
        myNewsChannels: config.NewsEvents.DefaultChannels,
        feedsUpdatedAt: new Date(),
    }),
    actions: {
        setMyHomeScreenTiles(myHomeScreenTiles) {
            this.myHomeScreenTiles = myHomeScreenTiles;
        },
        setMyNewsChannels(myNewsChannels) {
            this.myNewsChannels = myNewsChannels;
        },
        setFeedsUpdatedAt(feedsUpdatedAt) {
            this.feedsUpdatedAt = feedsUpdatedAt;
        },
        clearState() {
            this.$reset();
        },
    },
    persist: true,
});
