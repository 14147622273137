import { createApp } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import router from '@/router';
import App from '@/App.vue';
import { useApplicationSettingsStore } from '@/stores/applicationsettings.module';
import { useAuthenticationStore } from '@/stores/authentication.module';
//import { useNotificationsStore } from '@/stores/notifications.module';
import { useUserSettingsStore } from '@/stores/usersettings.module';
import stringsPlugin from '@/shared/strings';
import strings from '@/shared/strings.json';
import config from '../public/applicationsettings.json';
import logger from '@/shared/logger';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
/**-----Bootstrap-----------------------------------------------------------------------------**/
/* import '@popperjs/core';
import 'bootstrap/dist/js/bootstrap';
import 'bootstrap/dist/css/bootstrap.css' */
/* import 'jquery/dist/jquery.js/jquery.min.js';
import 'jquery'; */
/* import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.js'; */
//import 'bootstrap';

// import 'bootstrap/dist/js/bootstrap.bundle.min.js';

//import 'bootstrap/js/src/dropdown.js';

import 'bootstrap-icons/font/bootstrap-icons.css';
import './registerServiceWorker';
import '../public/css/bits.css';

import '../public/css/app-portal.css';

import Notifications from '@kyvg/vue3-notification';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import { LoadingPlugin } from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
//import {useMyTimetableStore} from "@/stores/timetables.module";

const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

function startVue() {
    app.use(router)
        .use(pinia)
        .use(stringsPlugin, strings)
        .use(logger)
        .use(LoadingPlugin)
        .use(Notifications)
        .use(VueGoogleMaps, {
            load: {
                key: config.CampusMap.GoogleMapsKey,
            },
        })
        .mount('#app');
}

// Get application settings before starting the Vue instance
// so they're available for components that need them:
const applicationSettingsStore = useApplicationSettingsStore(pinia);
const authenticationStore = useAuthenticationStore(pinia);
//const myTimetableStore = useMyTimetableStore(pinia);
const asyncCalls = [];

asyncCalls.push(applicationSettingsStore.getApplicationSettings());
asyncCalls.push(authenticationStore.getSsoSettings());

Promise.all(asyncCalls).then(() => {
    startVue();
    const userSettingsStore = useUserSettingsStore();
    userSettingsStore.$persist();
    //const notificationsStore = useNotificationsStore(pinia);
    /* if (notificationsStore) {
        notificationsStore.$persist();
    } */
    //myTimetableStore.$persist();
});
