<template>
    <div class="header-container">
        <h2>{{ this.$route.meta.title }}</h2>
    </div>
    <div>
        Search here
    </div>
</template>

<script>
export default {
  name: 'SearchView',
}
</script>
