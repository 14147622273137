<template>
    <div class="header-container">
        <h2>{{ this.$route.meta.title }}</h2>
    </div>
    <the-alert />
    <content-card />
</template>

<script>
    import { mapState, mapActions } from 'pinia';
    import { useAlertStore } from '@/stores/alert.module';
    import { useCardStore } from '@/stores/card.module';
    import TheAlert from '@/components/TheAlert.vue';
    import ContentCard from '@/components/ContentCard.vue';

    export default {
        name: 'AboutView',
        components: {
            TheAlert,
            ContentCard,
        },
        created() {
            this.initialiseUI();
        },
        computed: {
            ...mapState(useCardStore, ['cards']),
        },
        methods: {
            ...mapActions(useCardStore, ['getCards']),
            ...mapActions(useAlertStore, ['showInformationAlert', 'showErrorAlert']),
            async initialiseUI() {
                let loader = this.$loading.show();
                try {
                    await this.getCards(this.$route.name, true);
                } catch (error) {
                    this.showErrorAlert('Error: Unable to retrieve cards');
                }
                loader.hide();
            },
        },
    };
</script>
