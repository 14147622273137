<template>
    <li v-if="asPortal && !isLoggedIn" class="divider-vertical" aria-hidden="true"></li>
    <li v-if="asPortal" class="dropdown">
        <a @click="doNothing" class="dropdown-toggle" aria-roledescription="link" :title="`${getName} : ${getUsername}`" data-bs-toggle="dropdown" role="button" aria-expanded="false" aria-label="User name">
            <span class="username">{{  getUsersName }}</span>
            <span class="caret"></span>
        </a>
        <ul class="dropdown-menu dropdown-menu-lg-end dropdown-menu-logout">
            <li>
                <a aria-label="Sign out" aria-roledescription="link" @click="Logout" title="Sign out">
                    Sign out
                </a>
            </li>
        </ul>
    </li>

    <div v-else  id="auth">
        <ul v-if="isLoggedIn" id="nav-auth-right" class="navbar-nav ml-auto">
            <li class="nav-item b-nav-dropdown dropdown">
                <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-reference="parent" role="button">
                    {{ account.name }}
                </a>
                <ul tabindex="-1" class="dropdown-menu dropdown-menu-right">
                    <li role="presentation">
                        <router-link role="menuitem" class="dropdown-item" :to="{ name: 'UserSettingsView' }">
                            <i class="bi bi-gear" style="font-size:20px"></i> Settings
                        </router-link>
                    </li>
                    <li role="presentation">
                        <a @click.prevent="Logout()" role="menuitem" href="#" target="_self"
                           class="dropdown-item">
                            <i class="bi-box-arrow-left" style="font-size:20px"></i> Log Out
                        </a>
                    </li>
                </ul>
            </li>
        </ul>
        <ul v-else id="nav-unauth-right" class="navbar-nav ml-auto">
            <li class="nav-item">
                <a @click="Login()" href="#" target="_self"
                   class="nav-link"
                   aria-current="page">
                    <i class="bi-box-arrow-right" style="font-size:20px"></i>
                    Log In
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
    import { PublicClientApplication } from '@azure/msal-browser';
    import { mapState, mapActions } from 'pinia';
    import { useAuthenticationStore } from '@/stores/authentication.module';

    export default {
        name: "HeaderAuth",
        data() {
            return {
                msalInstance: undefined
            };
        },
        computed: {
            ...mapState(useAuthenticationStore, ['msalConfig', 'isLoggedIn', 'account', 'getUsername', 'getName']),
            asPortal() {
                //console.log("current route (header) ", this.$router.currentRoute);
                return true;
            },
            getUsersName() {
                let uName = this.getName;
                let maxlength = 18;
                if (typeof uName === 'string' || uName instanceof String ) {
                    if (uName.length > maxlength) {
                        uName = uName.slice(0, maxlength) + "...";
                    }
                } else {
                    uName = "Name Missing";
                }
                return uName;
            }
        },
        async mounted() {
            console.log("Mounted HeaderAuth");
            this.msalInstance = new PublicClientApplication(this.msalConfig);
            await this.msalInstance.initialize(); // must be called before any other MSAL plugin methods

            try {
                await this.msalInstance.handleRedirectPromise() // provides account, tokens and state after login callback
                    .then(async (response) => {
                        console.log('Redirect promise response:');
                        console.log(response);

                        // sets active account in MSAL and Pinia/LocalStorage
                        await this.checkAndSetActiveAccount(response?.account);

                        console.log('Active account object:');
                        console.log(this.msalInstance.getActiveAccount());
                        console.log('All account objects:');
                        console.log(this.msalInstance.getAllAccounts());

                        // perform a redirect within the SPA if state parameter is set, otherwise check for direct route path in URL
                        if (response?.state?.length > 1) {
                            await this.$router.push(response.state);
                        } else {
                            await this.handleDirectRoute();
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
            }
            catch (error) {
                this.$logger.logExceptionWarning(error);
            }

            // Auto login #2
            if (!this.isLoggedIn) { //this.$router.currentRoute.value.name !== 'MainView') {
                // - auto trigger login
                await this.Login();
            }
        },
        methods: {
            ...mapActions(useAuthenticationStore, ['setAccount']),
            doNothing() {
                return true;
            },
            async Login() {
                if (!this.msalInstance.getActiveAccount() || this.msalInstance.getAllAccounts().length === 0) { //} || this.msalInstance.getAllAccounts().length > 1) {
                    await this.clearAppStorage();
                    const routePath = await this.getDirectRoute();
                    await this.msalInstance.loginRedirect({
                        scopes: [this.msalConfig.auth.apiScope],
                        state: routePath
                    });
                }
            },
            async Logout() {
                //this.$logger.clearTaggedUser();
                const account = this.account?.homeAccountId ? this.msalInstance.getAccount({ homeAccountId: this.account.homeAccountId }) : null;
                if (account) {
                    await this.clearAppStorage();
                    // perform logout and redirection
                    this.msalInstance.logoutRedirect({
                        account,
                        postLogoutRedirectUri: this.msalConfig.postLogoutRedirectUri
                    });
                } else {
                    console.error("Account not found");
                }
            },
            async checkAndSetActiveAccount(loggedInAccount = undefined) {
                // activeAccount should not be set after initial login
                let activeAccount = this.msalInstance.getActiveAccount();
                let allAccounts = this.msalInstance.getAllAccounts();
                // If activeAccount does not exist then this is a new login attempt
                if (!activeAccount && loggedInAccount && allAccounts.length > 0) {
                    if (allAccounts.some((acc) => acc.homeAccountId == loggedInAccount.homeAccountId) === true) {
                        // Set active account in MSAL plugin to ensure token requests use the correct account
                        this.msalInstance.setActiveAccount(loggedInAccount);
                        // Set active account in Pinia/LocalStorage
                        this.setAccount(loggedInAccount);
                    }
                } else {
                    // Refresh existing login - set active account in Pinia/LocalStorage
                    console.log('Refreshing Pinia account object');
                    if (activeAccount) {
                        this.setAccount(activeAccount);
                    }
                }
            },
            async clearAppStorage() {
                localStorage.removeItem("mytimetablestore");
                localStorage.removeItem("userSettings");
                localStorage.removeItem("weeksofyearstore");
            },
            async getDirectRoute() {
                let directRoute = "/";
                if (this.$router.currentRoute.value.path === '/' &&
                    this.$router.currentRoute.value.name === undefined &&
                    window.location.pathname.length > 1) {
                    directRoute = window.location.pathname;
                }
                return directRoute;
            },
            async handleDirectRoute() {
                if (this.$router.currentRoute.value.path === '/' &&
                    this.$router.currentRoute.value.name === undefined &&
                    window.location.pathname.length > 1) {
                    let newPath = window.location.pathname;
                    if (window.location.search.length > 1) {
                        newPath += window.location.search;
                    }
                    this.$router.push(newPath);
                }
            }
        },
    };
</script>
<style scoped>
    .ml-auto {
        margin-left: auto !important;
    }
    #auth {
        display: block;
    }
    .open>.dropdown-menu {
        display: block;
    }
</style>
