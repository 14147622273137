import apiHandler from '@/shared/apiHandler';
import config from '../../public/applicationsettings.json';
import {defineStore, mapActions} from 'pinia';
import {useAuthenticationStore} from "@/stores/authentication.module";
import {useApplicationSettingsStore} from "@/stores/applicationsettings.module";
import { formatUserId } from '@/shared/stringFunctions';

export const useLibraryStore = defineStore('libraryData', {
    state: () => ({
        loans: {},
 //       holds: {}
    }),

    actions: {

        ...mapActions(useAuthenticationStore, ['buildAuthHeaders']),

        async getLibraryLoans(userid) {
            this.isLoading = true;

            // - set url, this changes in azure deploy depending on whether dev, or live/QA
            let endpointURL = config.Endpoint.Talend.Url + useApplicationSettingsStore().endpointTalendPath;
            let LibraryLoanPath = endpointURL + config.LibraryDataLive.Path + config.LibraryDataLive.QString;

            let token = await apiHandler.getSsoToken();
            let subscriptionKey = useApplicationSettingsStore().idfsSubscriptionKey;
            const authHeaders = this.buildAuthHeaders(token, subscriptionKey);

            let userToAttach = formatUserId(userid);

            await apiHandler.apiGet(`${LibraryLoanPath}` + userToAttach, authHeaders)
                .then ((result) => {

                    //console.log (result);
                    //console.log (result.data);

                    if (result.data.patron.loans){
                        this.loans = {
                            items: result.data.patron.loans.item,
                            noOfItems: result.data.patron.loans.item.length,
                            holditems: result.data.patron.holds.item,
                            noOfHoldItems: result.data.patron.holds.item.length,
                            cashbalance: result.data.patron.cash.balance
                        };
                    }
                    else{
                        this.loans = {
                            items:[],
                            noOfItems: 0
                        }
                    }    
                    //console.log ("this.loans");
                    //console.log (this.loans);
                }).catch((error) => {
                    throw error;
                });
        },
    },
});
