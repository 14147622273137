<template>
    <div id="camera-block" class="camera">
        <video id="idcardvideo" autoplay class="feed" />
        <div id="button-block">
            <button class="snap bi-camera" v-on:click="$emit('takePicture')"></button>
        </div>
    </div>
</template>



<script>

    export default {
        name: 'PictureTaker',
        emits: 'takePicture',
        frameGrabbed: false,
        offset_data: null,
        videoPlayer: null,
        setup() {

        },
        created() {
            this.init();
            this.frame = document.querySelector('#faceframe');
        },
        mounted() {
            this.frame = document.querySelector('#the-cropper');
            this.videoPlayer = document.querySelector("video");
        },
        components: {
            /*PictureDisplayer,*/
        },
        methods: {
            async init() {
                if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
                    let constraints = {
                        video: true,
                        audio: false,
                    }
                    try {
                        navigator.mediaDevices.getUserMedia(constraints).then(stream =>
                        {
                            this.videoPlayer.srcObject = stream;
                            this.videoPlayer.setAttribute('autoplay', '');
                            this.videoPlayer.setAttribute('muted', '');
                            this.videoPlayer.setAttribute('playsinline', '');
                            this.videoPlayer.play();
                        });
                    } catch (e) {
                        alert("Could not initialise camera feed");
                    }
                } else {
                    alert("Could not get Media Devices");
                }
            },
            getDim: function() {
                let stream = this.videoPlayer.srcObject;
                let streamSettings = stream.getVideoTracks()[0].getSettings();
                // actual width & height of the camera video
                let stream_width = streamSettings.width;
                let stream_height = streamSettings.height;
            }
        }
    }
</script>



<style scoped>
    .camera {
        display: block;
        box-sizing: border-box;
        overflow: hidden;
        max-width: 600px;
        /*height: 720px;*/
        position: relative;
    }

    .feed {
        display: block;
        background-color: #171717;
        width: 100%;
        object-fit: cover;
    }

    #button-block {
        position: absolute;
        bottom: 0;
        top: 0; /** temporary to test if iOS is cropping camera view, or totally hijacking it. **/
        width: 100%;
    }
    .snap {
        width: 42px;
        height: 42px;
        background-color: rgba(255,255,255, 0.7);
        border: solid 2px #FFF;
        border-radius: 50%;
        margin: 0 auto;
        display: block;
        font-size: 26px;
        color: #FFF;
        /*right: 0;
        position: absolute;
        bottom: 0;
        left: 50%;*/
    }

    @media (max-width: 575px) {
        .camera {
            max-width: 100%;
            /*height: 475px;*/
        }
        /*.feed {
            width: 340px;
            height: 425px;
        }*/
    }
    @media (max-width: 340px) {
        .camera {
            max-width: 100%;
            /*height: 365px;*/
        }
        /*.feed {
            width: 100px;
            height: 315px;
        }*/
    }
</style>
