<template>
    <div class="carddesign card h-100">
        <img class="card-img-top" :src="imageURL ? imageURL : imagePlaceholder" alt="Card image cap" @error="displayImagePlaceholder">
        <div class="card-body">
            <h5 class="card-title" v-html="title"></h5>
            <p class="card-text" v-html="description"></p>
            <a :href="link" target="_blank" class="btn btn-primary">Read More&nbsp;<i class="bi bi-arrow-right"></i></a>
        </div>
        <div class="card-footer">
            <small class="text-muted">Posted {{ formatDate(publishDate) }} in <b>{{channel}}</b></small>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: 'NewsCard',
        data() {
            return {
                imagePlaceholder: '/img/news-placeholder.png'
            };
        },
        props: {
            title: String,
            description: String,
            imageURL: String,
            link: String,
            publishDate: String,
            channel: String
        },
        methods: {
            formatDate(pubDate) {
                return moment(pubDate).fromNow();
            },
            displayImagePlaceholder(event) {
                event.target.src = this.imagePlaceholder;
            }
        }
    };
</script>

<style scoped>
    /* Small devices (landscape phones, 576px and up) */
    @media (max-width: 960px) {
        .card-img-top {
            width: 100%;
            height: 25vw;
            object-fit: cover;
        }
    }
    @media (min-width: 960px) {
        .card-img-top {
            width: 100%;
            height: 15vw;
            object-fit: cover;
        }
    }
</style>
