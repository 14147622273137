<template>
    <the-alert />
    <div class="header-container">
        <h2>{{ this.$route.meta.title }}</h2>
    </div>
    <personal-tutor></personal-tutor>
</template>

<script>
    import {mapActions} from 'pinia';
    import { useAlertStore } from '@/stores/alert.module';
    import TheAlert from '@/components/TheAlert.vue';
    import PersonalTutor from '@/components/PersonalTutor.vue';

    export default {
        name: 'PersonalTutorView',
        components: {
            TheAlert,
            PersonalTutor,
        },
        created() {},
        mounted() {},
        computed: {},
        methods: {
            ...mapActions(useAlertStore, ['showInformationAlert', 'showErrorAlert']),
        },
    };
</script>
