<!--
    Displays the buttons 'OK' and 'Cancel' that display once a 'snap' has been taken.
    OK will crop the snap to the given frame.
    Cancel will hide the snap and display the video again for another snap to be taken.
-->
<template>
    <div id="pic-controls'">
        <div class="container">
            <div class="row">
                <div class="col-6 text-end yes">
                    <button class="cropbutton cropbutton-ok bg-success" v-on:click="$emit('cropIt')" id="btn-crop-ok"><i class="bi-check-lg"></i> <span> OK</span></button>
                </div>
                <div class="col-6  no">
                    <button class="cropbutton cropbutton-cancel bg-danger" v-on:click="$emit('hideSnap')" id="btn-crop-cancel"><i class="bi-x-lg"></i> <span> Re-Take</span></button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        name: "CameraControls",
        methods: {

        },
    }
</script>


<style scoped>
    .cropbutton {
        width: 90px;
        /*height: 90px;*/
        border-radius: 10px;
        color: #EEE;
        border: 0 transparent solid;
    }
    .cropbutton-ok {

    }
    .cropbutton-cancel {

    }
</style>
