<template>
    <div class="carddesign card h-100">
        <div class="card-header" v-html="$strings('main.tiles.LibraryAccount.title')"></div>
        <div class="card-body">
            <p class="card-text" v-html="$strings('main.tiles.LibraryAccount.description')"></p>
            <router-link class="btn btn-primary"
                :to="{ name: $strings('main.tiles.LibraryAccount.link') }">{{ $strings('main.tiles.LibraryAccount.title') }}&nbsp;<i class="bi bi-arrow-right"></i></router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'LibraryAccountTile',
        methods: {}
    };
</script>

<style scoped>
</style>
