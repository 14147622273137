// This store is to be used in conjunction with the ContentCards component

import apiHandler from '@/shared/apiHandler';
import { defineStore } from 'pinia';
import moment from 'moment';
import config from '../../public/applicationsettings.json';

export const useCardStore = defineStore('card', {
    state: () => ({
        cards: {}
    }),
    actions: {
        async getCards(route, visible) {
            const lastRefresh = moment(this.cards[route]?.updatedAt);
            const dateNow = moment();
            const elapsedHours = dateNow.diff(lastRefresh, 'hours');
            let cardCatId = 0;

            // Refresh card data if more than 1 hour has passed
            if (this.cards[route]?.updatedAt === undefined || elapsedHours > 1) {
                //Get WP post category ID using current route
                route && await apiHandler.apiGet(`${config.MobileData.ProxyURL}?path=${config.MobileData.CardPath}&type=cat&id=${route}`)
                    .then((result) => {
                        cardCatId = result.data.length ? result.data[0].id : 0;
                    }).catch((error) => {
                        throw error;
                    });

                //Get WP posts for provided route
                if (cardCatId > 0) {
                    await apiHandler.apiGet(`${config.MobileData.ProxyURL}?path=${config.MobileData.CardPath}&type=post&id=${cardCatId}`)
                        .then((result) => {
                            this.cards[route] = {
                                display: visible,
                                updatedAt: new Date(),
                                items: result.data
                            };
                        }).catch((error) => {
                            throw error;
                        });
                } else {
                    //console.log("cardCatId fail with a value of: ", cardCatId);
                }
            }
        },
        toggleVisibility(route) {
            this.cards[route].display = this.cards[route].display ? false : true;
        },
    },
});
