<template>
    <div v-if="clusterTimetableActivities!==undefined&&clusterTimetableActivities.length>0">
        <div class="p-1 mb-2">
            <i class="bi bi-calendar-event"></i>&nbsp;Timetable
        </div>
        <div class="container">
            <div class="row row-cols-1 row-cols-sm-2 row-cols-md-4 justify-content-start">
                <div class="col" v-for="activity in clusterTimetableActivities" :key="activity.ActIndex">
                    <div class="carddesign card">
                        <h5 class="card-header">{{activity.Name}}</h5>
                        <div class="card-body">
                            <p class="card-text">{{formatDateAsTime(activity.ScheduledStartTimeObj)}} {{decorateDurationInHours(activity.durationInHours)}} {{decorateActivityTypeAsDesignate(activity.ActivityType_Name)}}</p>
                            <p class="card-text" v-for="location in activity.locations" :key="location.EXTERNALID">{{location.Name}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: 'ClusterTimetable',
        props: {
            clusterTimetableActivities: Array,
            currentTimetableWeek: Number,
            currentDayOfWeek: Number,
        },
        methods: {
            /*displayActivity(activity) {
                var timeFormat = "HH:mm:ss";
                let currentTime = moment(new Date(), "HH:mm:ss");
                let beforeTime = moment(activity.ScheduledStartTimeObj, "HH:mm:ss");
                let afterTime = moment(activity.ScheduledEndTimeObj, "HH:mm:ss");

                return moment({
                    hour:currentTime.hour(),
                    minute:currentTime.minute(),
                    second:currentTime.second()
                }).isBetween(moment({
                    hour:beforeTime.hour(),
                    minute:beforeTime.minute(),
                    second:beforeTime.second()
                }), moment({
                    hour:afterTime.hour(),
                    minute:afterTime.minute(),
                    second:afterTime.second()
                }));
            },*/
            formatDateAsTime(value) {
                return moment(value).format('h:mma');
            },
            decorateDurationInHours(hours, args) {
                if (args === void 0) { args = { numberOnly: false, brackets: true, separator: false }; }
                var postFix = "";
                var preFix = "";
                if (!args.numberOnly) {
                    postFix += hours > 1 ? " hours" : " hour";
                }
                if (args.brackets) {
                    preFix = "(";
                    postFix += ")";
                }
                else {
                    if (args.separator) {
                        postFix += " -";
                    }
                }
                return preFix + hours + postFix;
            },
            decorateActivityTypeAsDesignate(actType, args) {
                if (args === void 0) { args = { brackets: true, separator: false }; }
                if (actType === "") {
                    return "";
                }
                else {
                    actType = "";
                    if (actType === "") {
                        return actType;
                    }
                    if (args.brackets) {
                        /*return "("+actType+")";*/
                        return actType;
                    }
                    else {
                        if (args.separator) {
                            return actType + " -";
                        }
                        else {
                            return actType;
                        }
                    }
                }
            }
        }
    };
</script>

<style scoped>
    .container {
        margin-left: 0!important;
        margin-right: 0!important;
    }
    .bg-secondary {
        --bs-bg-opacity: 1;
        background-color: rgba(var(--bs-secondary-rgb),var(--bs-bg-opacity))!important;
    }
    .button-title {
        display: block;
        float: left;
        width: 100%;
    }
    .button-badge {
        display: block;
        float: right;
        margin-right: 2em;
    }
</style>
