import axios from 'axios';
import { defineStore } from 'pinia';
import { BrowserCacheLocation, LogLevel } from "@azure/msal-browser";
import logger from '@/shared/logger';

export const useAuthenticationStore = defineStore('authentication', {
    state: () => ({
        account: undefined,
        msalConfig: undefined,
        userType: undefined
    }),
    getters: {
        isLoggedIn(state) {
            /* if(state.account) {
                console.log("**state account: ", state.account.environment);//);
            } */
            return !!state.account;
        },
        isAdmin(state) {
            return !!state.account?.idTokenClaims?.roles?.includes("AppAdmins");
        },
        isStaff(state) {
            return !!state.account?.idTokenClaims?.roles?.includes("AppStaff");
        },
        isStudent(state) {
            return !!state.account?.idTokenClaims?.roles?.includes("AppStudents");
        },
        getUsername(state) {
            return state.account?.idTokenClaims?.upn;
        },
        getName(state) {
            if (state.account) {
                //console.log("state account:", state.account);
            }
            return state.account?.name;
        },
        getPhotoID(state) {
            // - return whatever we decide on to name the stored photo as. Must also update in UploadPhoto.vue
            //console.log("getting photo ID " + state.account?.localAccountId);
            return state.account?.localAccountId;
        },
    },
    actions: {
        buildAuthHeaders(token, SubscriptionKey) {
            let authHeaders;
            if (typeof token === "object" && token?.accessToken && Object.keys(token.accessToken).length > 0) {
                authHeaders = {
                    headers: {
                        "Ocp-Apim-Subscription-Key": SubscriptionKey
                        , "Authorization": `Bearer ${token.accessToken}`
                    }
                };
            } else {
                authHeaders = {
                    headers: {
                        "Ocp-Apim-Subscription-Key": SubscriptionKey
                        , "Authorization": ""
                    }
                };
            }
            return authHeaders;
        },
        setAccount(account) {
            this.account = account;
        },
        logout() {
            this.account = undefined;
            axios.defaults.headers.common['Authorization'] = null;
        },
        async getSsoSettings() {
            const response = await axios.get('/applicationsettings.json');
            this.msalConfig = {
                auth: {
                    clientId: response.data.Sso.ClientId,
                    authority: response.data.Sso.Authority,
                    apiScope: response.data.Sso.ApiScope,
                    postLogoutRedirectUri: response.data.Sso.PostLogoutRedirectUri,
                    redirectUri: response.data.Sso.RedirectUri,
                    redirectUriBlank: response.data.Sso.RedirectUriBlank
                },
                cache: {
                    cacheLocation: BrowserCacheLocation.LocalStorage,
                    //storeAuthStateInCookie: true,
                },
                system: {
                    loggerOptions: {
                        logLevel: LogLevel.Trace,
                        loggerCallback: (level, message, containsPii) => {
                            /*if (containsPii) {
                                return;
                            }*/
                            switch (level) {
                                case LogLevel.Error:
                                    logger.logError(`[${this.getUsername}] : ${message}`);
                                    //console.error(message);
                                    return;
                                case LogLevel.Info:
                                    logger.logInformation(`[${this.getUsername}] : ${message}`);
                                    //console.info(message);
                                    return;
                                case LogLevel.Verbose:
                                    //logger.logInformation(`[${this.getUsername}] : ${message}`);
                                    //console.debug(message);
                                    return;
                                case LogLevel.Warning:
                                    logger.logWarning(`[${this.getUsername}] : ${message}`);
                                    //console.warn(message);
                                    return;
                                default:
                                    //console.log(message);
                                    return;
                            }
                        },
                        piiLoggingEnabled: true,
                    }
                }
            };
        },
    },
});
