/**
 * Seems a long way to create a static variable that keeps count of the number of times the image cropper
 *  in DigitalID has been viewed.
 *  Required so the animation only shows on first view.
 */
import { defineStore } from 'pinia';

export const useCropperObserverStore = defineStore('cropperObserver', {
    state: () => ({
        timesViewed: 0,
    }),
    methods() {
        function timesViewed() {
            return this.timesViewed;
        }
        function isFirstView() {
            return this.timesViewed === 1;
        }
        function addView() {
            return ++this.timesViewed;
        }
    },
    actions: {
        getViews: function() {
            return this.timesViewed;
        },
        getIsFirst: function() {
            return this.timesViewed === 1;
        },
        incViews: function() {
            return ++this.timesViewed;
        }
    }
});
