<template>
    <h2 class="accordion-header" :id="`${prefix}heading-${id}`">
        <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="`#${prefix}collapse-${id}`"
            aria-expanded="false"
            :aria-controls="`${prefix}collapse-${id}`"
        >
            <span class="button-title">{{ displayName }}</span>
            <span :class="`badge button-badge bg-${statusClass}`">{{ statusText }}</span>
        </button>
    </h2>
</template>

<script>
    export default {
        name: 'ClusterLink',
        props: {
            id: Number,
            displayName: String,
            statusClass: {
                type: String,
                default: "warning"
            },
            statusText: {
                type: String,
                default: "NO DATA"
            },
            prefix: {
                type: String,
                default: ""
            },
        },
        methods: {}
    };
</script>

<style scoped>
    .bg-secondary {
        --bs-bg-opacity: 1;
        background-color: rgba(var(--bs-secondary-rgb),var(--bs-bg-opacity)) !important;
    }
    .button-title {
        display: block;
        float: left;
        width: 100%;
    }
    .button-badge {
        display: block;
        float: right;
        margin-right: 2em;
    }
</style>
