import apiHandler from '@/shared/apiHandler';
import {defineStore, mapActions} from 'pinia';
import config from '../../public/applicationsettings.json';
import {useAuthenticationStore} from "@/stores/authentication.module";
import {useApplicationSettingsStore} from "@/stores/applicationsettings.module";
import { formatUserId } from '@/shared/stringFunctions';

export const usePersonalTutorStore = defineStore('personaltutorData', {
    state: () => ({
        personaltutors: {}
    }),
    actions: {

        ...mapActions(useAuthenticationStore, ['buildAuthHeaders']),

        //Test userIds c2042993, c0050313;
        async getPersonalTutors(userId){
            let userToQuery = formatUserId(userId);
            this.isLoading = true;

            let token = await apiHandler.getSsoToken();
            let subscriptionKey = useApplicationSettingsStore().idfsSubscriptionKey;
            const authHeaders = this.buildAuthHeaders(token, subscriptionKey);
            authHeaders.responseType = "application/json";

            // - set url, this changes in azure deploy depending on whether dev, or live/QA
            let endpointURL = config.Endpoint.Talend.Url + useApplicationSettingsStore().endpointTalendPath;
            let PersonalTutorDataHost = endpointURL + config.PersonalTutorData.Host;

            let url='';
            if(userToQuery !== ''){
                url=`${PersonalTutorDataHost}`+'?username='+userToQuery;
            }
            if(url !== ''){
                await apiHandler.apiGet(url, authHeaders)
                .then( (result) =>
                {
                    this.personaltutors = JSON.parse(result.data);
                })
                .catch((error) =>
                {
                    throw error;
                });
            }
            return this.personaltutors;
        },
    },
});
