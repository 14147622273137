
import axios from 'axios';
import apiHandler from '@/shared/apiHandler';
import {defineStore, mapActions} from 'pinia';
import moment from 'moment';
import config from '../../public/applicationsettings.json';
import {useApplicationSettingsStore} from "@/stores/applicationsettings.module";
import {useAuthenticationStore} from "@/stores/authentication.module";
import {useRoute} from "vue-router";
//import myTtWeeks from '../../public/weeks.json';

//const WeeksOfYearEndpoint = config.WeeksOfYearData.PathLive;
const WeeksOfYearEndpoint = config.WeeksOfYearData.PathIDFS;

export const useWeeksOfYearStore = defineStore('weeksofyearstore', {
    state: () => ({
        academicweeks: {
            updatedAt: undefined,
        },
        physicalWeekNo: null,
        weekZero: null,
        oneHourInMicro: 60 * 60 * 1000,
        oneDayInMicro: 60 * 60 * 24 * 1000,
        loadFromFile: !true,
    }),
    actions: {
        ...mapActions(useAuthenticationStore, ['buildAuthHeaders']),
        /**
         *
         * @returns {Promise<void>}
         */
        async getWeeks() {

            if (this.loadFromFile) {
                /**
                 * loaded weeks from file (../../public/weeks.json) rather than network.
                 * See what happens with errors now. Do they move onto the next step, ie timetable data?
                **/
                await axios.get("/weeks.json")
                .then(async (myTtWeeks) => {
                    console.log("Weeks local GET: ", myTtWeeks);
                    this.academicweeks = {
                        rawData: myTtWeeks.data.Data,
                        allweeks: myTtWeeks.data.Data.weeks.week,
                    };
                    // - add timestamp values of weekStart and weekEnd to the object
                    await this.addTimestamps();

                    // - create a value thisWeek simplify getting current week information
                    this.academicweeks.thisWeek = await this.setCurrentWeek();
                    console.log('Check current week is set: ', this.academicweeks);
                    this.physicalWeekNo = this.academicweeks.thisWeek[0].Week;
                    this.academicweeks.anyWeek = this.academicweeks.thisWeek;
                })
                .catch((error) => {
                    throw error;
                });
            } else { // - load weeks data from idfs the original way.

                const lastRefresh = moment(this.academicweeks?.updatedAt);
                const dateNow = moment();

                //config.Endpoint.Talend.Url    Endpoint.Talend.Path    timetable.
                //https://idfs.ncl.ac.uk        /mobile/dev             /backend/timetables/weeks
                const weeksOfYearIDFS = config.Endpoint.Talend.Url + useApplicationSettingsStore().endpointTalendPath + WeeksOfYearEndpoint;
                //console.log("!!****URL: " + weeksOfYearIDFS);

                const route = useRoute();
                let forceFail = false;
                const auth = useAuthenticationStore();
                const userIsAdmin = auth.isAdmin;
                if (userIsAdmin) {
                    if (route?.query?.failweeks) {
                        forceFail = true;
                    }
                }
                console.log("forceFail: " + forceFail);

                let token = await apiHandler.getSsoToken();
                let subscriptionKey = useApplicationSettingsStore().idfsSubscriptionKey;
                let authHeaders = {};
                if (forceFail) {
                    authHeaders = {};
                } else {
                    authHeaders = this.buildAuthHeaders(token, subscriptionKey);
                }
                //const authHeaders = this.buildAuthHeaders(token, subscriptionKey);
                authHeaders.responseType = "application/json";

                const elapsedHours = dateNow.diff(lastRefresh, 'hours');

                const justRefresh = false;

                if (forceFail || justRefresh || this.academicweeks?.updatedAt === undefined || elapsedHours >= 2) {
                    await apiHandler.apiGet(`${weeksOfYearIDFS}`, authHeaders)
                        .then(async (result) =>
                        {
                            //console.log("Yes! We ARE fresh-fetching WEEKS!");
                            // - converting rec'd string data(?) into json
                            let res = JSON.parse(result.data);
                            //console.log(result.data);
                            this.academicweeks = {
                                rawData: res.Data,
                                updatedAt: new Date(),
                                allweeks: res.Data.weeks.week,
                            };

                            // - add timestamp values of weekStart and weekEnd to the object
                            await this.addTimestamps();

                            // - create a value thisWeek simplify getting current week information
                            this.academicweeks.thisWeek = await this.setCurrentWeek();
                            this.physicalWeekNo = this.academicweeks.thisWeek[0].Week;
                            this.academicweeks.anyWeek = this.academicweeks.thisWeek;

                        }).catch((error) => {
                            this.academicweeks.updatedAt = undefined;
                            throw error;
                        });
                } else {
                    console.log("Not fresh-fetching WEEKS!");
                }
            }
        },
        async setCurrentWeek() {
            // - filter see https://stackoverflow.com/questions/2722159/how-to-filter-object-array-based-on-attributes
            // - provided date format is f*cked. javascript Date wants either "YYYY-MM-DD" or "MM/DD/YYYY" (stoopid dumericans)
            // - Do we need a check that timestamps have been added to the allweeks object? Nahhhhhh
            let today = Date(); //Date();
            /*
                Weeks start on Monday 00:00:00 and end on Sunday 00:00:00
                so any time between 00:00:00.0001 and 23:59:59.999 (is that correct?)
                will be neither greater than start time, nor less than end time.
                Need to set the start of the week to one day (minus a millisecond)
                earlier
            */
            let oneDayLessOneThou = (24 * 60 * 60 * 1000) - 1;
            // get a timestamp for today's date
            let todayStamp = Date.parse(today);
            return this.academicweeks.allweeks.filter(function (el) {
                //let startStamp = el.TimestampStart - oneDayLessOneThou;
                return el.TimestampStart <= todayStamp &&
                    (el.TimestampEnd + oneDayLessOneThou) >= todayStamp;
            });
        },
        addTimestamps: async function() {
            let self = this;
            let YEAR = 2;
            let MONTH = 1
            let DAY = 0;
            let oneDayInMicro = this.oneDayInMicro; //60 * 60 * 24 * 1000;
            this.academicweeks.allweeks.forEach(function(el)
            {
                //NOTE: javascript Date months start at 0 for January, hence [MONTH] - 1
                let startArray = el.WeekStart.split("/");
                let startDate = new Date(startArray[YEAR], startArray[MONTH] - 1, startArray[DAY]);
                el.TimestampStart = startDate.getTime();

                let endArray = el.WeekEnd.split("/");
                let endDate = new Date(endArray[YEAR], endArray[MONTH] - 1, endArray[DAY]);
                el.TimestampEnd = endDate.getTime();

                el.dayDates = [];
                for (let i = 0; i < 7; i++) {
                    //let d = new Date(el.TimestampStart + (oneDayInMicro * (i)));
                    el.dayDates[i] = el.TimestampStart + (oneDayInMicro * (i)); //d.getDate().toString().padStart(2, '0') + "/" + d.getMonth().toString().padStart(2, '0');
                }
                if (parseInt(el.Week) === 0) {
                    self.weekZero = el;
                    self.weekZero.zeroMoment = moment(el.TimestampStart);
                    self.weekZero.plusOneDay = self.weekZero.zeroMoment.add(1, "day");
                }

                return el;
            });
        },
        setWeekToNumber(weekNo) {
            let anyWeek = this.academicweeks.allweeks.filter(function (el) {
                return parseInt(el.Week) === weekNo;
            });
            this.academicweeks.anyWeek = anyWeek;
            return anyWeek;
        },
    },
    //persist: true,
});

/*
Example of data returned from WeeksOfYearEndpoint
"Data": {
    "weeks": {
        "week": [{
                    "Week": "-1",
                    "WeekNo": "-2",
                    "WeekStart": "22/08/2022",
                    "WeekEnd": "28/08/2022",
                    "YearWeek": "34"
                }, {
                    "Week": "0",
                    "WeekNo": "-1",
                    "WeekStart": "29/08/2022",
                    "WeekEnd": "04/09/2022",
                    "YearWeek": "35"
                }, {
                    "Week": "1",
                    "WeekNo": "0",
                    "WeekStart": "05/09/2022",
                    "WeekEnd": "11/09/2022",
                    "YearWeek": "36"
                }, {
        ........
                }, {
                    "Week": "51",
                    "WeekNo": "50",
                    "WeekStart": "21/08/2023",
                    "WeekEnd": "27/08/2023",
                    "YearWeek": "34"
                }, {
                    "Week": "52",
                    "WeekNo": "51",
                    "WeekStart": "28/08/2023",
                    "WeekEnd": "03/09/2023",
                    "YearWeek": "35"
                }
            ]
        }
    }
}
 */
