import apiHandler from '@/shared/apiHandler';
import { defineStore } from 'pinia';
import logger from '@/shared/logger';
import config from '../../public/applicationsettings.json';

export const useArticleStore = defineStore('articles', {
    state: () => ({
        channels: [],
        content: [],
        topContent: [],
        isLoading: false,
    }),
    actions: {
        async getChannels(includeContent, filter) {
            this.isLoading = true;
            try {
                let result = await apiHandler.apiGet(`${config.MobileData.NewsFeedProxy}`);
                if (result?.data?.channel?.length) {
                    this.channels = result.data.channel.map((channel) => {
                        let { item, ...data} = channel;
                        return data;
                    });
                    if (includeContent) {
                        let filteredContent = [];
                        result.data.channel.map((channel) => {
                            if (!filter || filter.includes(channel.category)) {
                                channel.item.map((content) => {
                                    content.channel = { name: channel.title, category: channel.category };
                                    filteredContent.push(content);
                                });
                            }
                            return channel;
                        });
                        Array.prototype.concat.apply([], filteredContent);
                        filteredContent.sort((a,b) => new Date(b.pubDate) - new Date(a.pubDate));
                        this.content = filteredContent;
                        let top3Content = filteredContent.splice(0, 3);
                        this.topContent = top3Content;
                    }
                }
                logger.logInformation("Channels retrieved successfully");
            }
            catch (error) {
                logger.logExceptionWarning(error);
                throw (error);
            }
            finally {
                this.isLoading = false;
            }
        },
        clearState() {
            this.$reset();
        },
    },
});
