<template>
    <div class="container-fluid">
        <div class="row">
            <h3>{{ 'Check Study Space Availability' }}</h3>
        </div>
    </div>

    <section id="loans" class="welcome_area p_120">
        <div id="study-space">
            <div id="studyspace-container" class="container">
                
                <table v-if="studySpacesForPage" class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Location</th>
                            <th scope="col">Occupied</th>
                            <th scope="col">Capacity</th>
                            <th scope="col">Available</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="sspace in studySpacesForPage" :key="sspace.capacity">
                            <td>{{ sspace[0].replace("_", " ").charAt(0).toUpperCase() + sspace[0].replace("_", " ").slice(1) }}</td>
                            <td>{{ sspace[1].occupied }}</td>
                            <td>{{ sspace[1].capacity }}</td>
                            <td v-if="(sspace[1].capacity - sspace[1].occupied)*100/sspace[1].capacity > 75" style="background-color:green;">{{ sspace[1].capacity - sspace[1].occupied }}</td>
                            <td v-if="(sspace[1].capacity - sspace[1].occupied)*100/sspace[1].capacity <= 75 && (sspace[1].capacity - sspace[1].occupied)*100/sspace[1].capacity >= 25" style="background-color:yellow;">{{ sspace[1].capacity - sspace[1].occupied }}</td>
                            <td v-if="(sspace[1].capacity - sspace[1].occupied)*100/sspace[1].capacity <= 25 && (sspace[1].capacity - sspace[1].occupied)*100/sspace[1].capacity > 0" style="background-color:red;">{{ sspace[1].capacity - sspace[1].occupied }}</td>
                            <td v-if="sspace[1].capacity == 0" style="background-image: url('img/noentry.jpg');"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapState, mapActions } from 'pinia';
    import { useStudySpaceStore } from '@/stores/studyspace.module';
    import { useAlertStore } from '@/stores/alert.module';
    import TheAlert from '@/components/TheAlert.vue';

    export default {
        name: "StudySpace",

        created() {
            this.initialiseUI();
        },

        computed: {
            ...mapState(useStudySpaceStore, ['studyspaces']),

            studySpacesForPage() {
                const listOfSegments = [];

                if (this.studyspaces.segments){

                    for (let i=0; i < Object.keys (this.studyspaces.segments).length; i++){
                        //console.log (Object.keys (this.studyspaces.segments) [i]);
                        //console.log (Object.values (this.studyspaces.segments) [i]);

                        listOfSegments.push ([Object.keys (this.studyspaces.segments) [i], Object.values (this.studyspaces.segments) [i]]);
                    }
                }

                return listOfSegments.length > 0 ? listOfSegments : false;
                //return listOfSegments;
            }
        },
        
        methods: {
            ...mapActions(useStudySpaceStore, ['getStudySpaces']),
            ...mapActions(useAlertStore, ['showInformationAlert', 'showErrorAlert']),

            async initialiseUI() {
                this.isInitialisingUI = true;

                try {
                    await this.getStudySpaces();
                } catch (error) {
                    this.showErrorAlert('Error: Unable to retrieve studyspaces');
                }

                this.isInitialisingUI = false;
            },
        },
    };
</script>
