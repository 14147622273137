<template>  
    <div>
        <nav v-if="useAppFooter" class="navbar d-flex justify-content-center navbar-dark bg-dark-nu fixed-bottom navbar-expand">
            <ul class="navbar-nav navbar-footer">
                <li class="nav-item">
                    <a href="https://www.ncl.ac.uk" rel="noopener" target="_blank" class="nav-link">
                    © {{currentYear}} Newcastle University</a>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link footer-additional" :to="{ name: 'PrivacyView' }">Privacy</router-link>
                </li>
                <li class="nav-item">
                    <a href="https://www.ncl.ac.uk/info/accessibility/" rel="noopener" target="_blank" class="nav-link footer-additional">Accessibility</a>
                </li>
                <li class="nav-item">
                    <a href="https://www.ncl.ac.uk/itservice/" rel="noopener" target="_blank" class="nav-link footer-additional">IT Service</a>
                </li>
                <li class="nav-item">
                    <a href="https://www.ncl.ac.uk/data.protection/" rel="noopener" target="_blank" class="nav-link footer-additional">Corporate Data Protection</a>
                </li>
            </ul>
        </nav>
        <nav v-else class="navbar d-flex justify-content-center navbar-dark bg-dark-nu fixed-bottom">
            <div class="portal-padder">
                <div class="navbar-nav container">
                    <div class="nav-item">
                        <a class="nav-link" href="https://www.ncl.ac.uk/info/accessibility/"><span>Accessibility - Website Information and Feedback - Newcastle University (ncl.ac.uk)</span></a>
                    </div>
                    <div class="nav-item">
                        <span>Copyright © </span>{{ currentYear }}<span>. All rights reserved.</span>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>

export default {
    name: 'TheFooter',
    computed: {
        currentYear() {
            return new Date().getFullYear();
        },
        useAppFooter() {
            return false;
        }
    },
    methods: {
    },
};
</script>

<style scoped>
</style>
