import { defineAsyncComponent } from "vue";

export async function getHomeTiles(userType) {
    let sharedTiles = [],
        staffTiles = [],
        studentTiles = [];
    sharedTiles = await getHomeTileNames(require.context('@/components/homescreen/shared', true, /\.vue$/i, "sync"), "shared");
    if (userType == 'staff' || userType == 'all') {
        staffTiles = await getHomeTileNames(require.context('@/components/homescreen/staff', true, /\.vue$/i, "sync"), "staff");
    }
    if (userType == 'student' || userType == 'all') {
        studentTiles = await getHomeTileNames(require.context('@/components/homescreen/student', true, /\.vue$/i, "sync"), "student");
    }
    return [ ...sharedTiles, ...staffTiles, ...studentTiles ].sort((a, b) => a.name.localeCompare(b.name));
}

export async function getHomeTileNames(requireContext, componentType) {
    let componentNames = requireContext
        .keys()
        .map((file) => {
            return { "name": file.replace(/(^.\/)|(\.vue$)/g, ""), "type": componentType };
        });
    return componentNames;
}

export function importHomeTiles(componentNames) {
    let sharedTiles = {},
        staffTiles = {},
        studentTiles = {};
    sharedTiles = importHomeTilesByType(componentNames, 'shared');
    staffTiles = importHomeTilesByType(componentNames, 'staff');
    studentTiles = importHomeTilesByType(componentNames, 'student');
    return { ...sharedTiles, ...staffTiles, ...studentTiles };
}

export function importHomeTilesByType(componentNames, subDir) {
    let components = {};
    if (Array.isArray(componentNames)) {
        componentNames.forEach((component) => {
            if (component.type == subDir) {
                components[component.name] = defineAsyncComponent(() =>
                    import("@/components/homescreen/" + subDir + '/' + component.name + ".vue")
                    //components[component.name] = require("@/components/homescreen/" + subDir + '/' + component.name + ".vue").default
                );
            }
        });
    }
    return components;
}
