<template>
    <div id="listViewContainer">
        <div class="accordion" id="listView">
            <div v-for="(b, i) in facilities" :key="b.id" class="accordion-item">
                <div v-show="b.visible === true">
                    <h2 class="accordion-header" :id="`heading-${b.id}`">
                        <button
                            class="accordion-button"
                            :class="{ collapsed: facilities.length > 1 }"
                            type="button"
                            data-bs-toggle="collapse"
                            :data-bs-target="`#collapse-${b.id}`"
                            :aria-expanded="i === 0"
                            :aria-controls="`collapse-${b.id}`"
                        >
                            {{ b.name }}
                        </button>
                    </h2>
                    <div
                        :id="`collapse-${b.id}`"
                        class="accordion-collapse collapse"
                        :class="{ show: facilities.length === 1 }"
                        :aria-labelledby="`heading-${b.id}`"
                        data-bs-parent="#listView"
                    >
                        <div v-if="b.facilities?.ent?.length > 0">
                            <div class="accordion-body">
                                <a @click="$emit('show-building-facilities', b.id, 'ent')" class="facility-link">
                                    <i class="bi bi-door-closed"></i>&nbsp;Entrances
                                </a>
                            </div>
                        </div>
                        <div v-if="b.facilities?.acc?.length > 0">
                            <div class="accordion-body">
                                <a @click="$emit('show-building-facilities', b.id, 'acc')" class="facility-link">
                                    <img src="img/icons/acc-list-icon.svg" class="list-icon" />&nbsp;Accessible Entrances
                                </a>
                            </div>
                        </div>
                        <div v-for="(f) in b.facilities?.fod" :key="f.id">
                            <div class="accordion-body">
                                <a @click="$emit('show-building-facilities', b.id, 'fod', f.id)" class="facility-link">
                                    <img src="img/icons/fod-list-icon.svg" class="list-icon" />&nbsp;<span v-html="f.name"></span>
                                </a>
                            </div>
                        </div>
                        <div v-for="(f) in b.facilities?.mcw" :key="f.id">
                            <div class="accordion-body">
                                <a @click="$emit('show-building-facilities', b.id, 'mcw', f.id)" class="facility-link">
                                    <img src="img/icons/mcw-list-icon.svg" class="list-icon" />&nbsp;Microwave: <span v-html="f.name"></span>
                                </a>
                            </div>
                        </div>
                        <div v-for="(f) in b.facilities?.nrs" :key="f.id">
                            <div class="accordion-body">
                                <a @click="$emit('show-building-facilities', b.id, 'nrs', f.id)" class="facility-link">
                                    <img src="img/icons/nrs-list-icon.svg" class="list-icon" />&nbsp;Nursing Room: <span v-html="f.name"></span>
                                </a>
                            </div>
                        </div>
                        <div v-for="(f) in b.facilities?.pry" :key="f.id">
                            <div class="accordion-body">
                                <a @click="$emit('show-building-facilities', b.id, 'pry', f.id)" class="facility-link">
                                    <i class="bi bi-volume-mute"></i>&nbsp;Quiet/Prayer Space: <span v-html="f.name"></span>
                                </a>
                            </div>
                        </div>
                        <div v-for="(f) in b.facilities?.shr" :key="f.id">
                            <div class="accordion-body">
                                <a @click="$emit('show-building-facilities', b.id, 'shr', f.id)" class="facility-link">
                                    <img src="img/icons/shr-list-icon.svg" class="list-icon" />&nbsp;Shower: <span v-html="f.name"></span>
                                </a>
                            </div>
                        </div>
                        <div v-if="b.facilities?.ent?.length===0 &&
                                    b.facilities?.fod?.length===0 &&
                                    b.facilities?.mcw?.length===0 &&
                                    b.facilities?.shr?.length===0 &&
                                    b.facilities?.pry?.length===0 &&
                                    b.facilities?.nrs?.length===0">
                            <div class="accordion-body"><i class="bi bi-patch-exclamation"></i> No facilities available for this location</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ListContainer",
        props: {
            facilities: Array
        }
    };
</script>

<style scoped>
    .header-container {
        padding-top: 15px;
    }
    .geo-button {
        width:35px;
        background-color:#6699ff;
    }
    .view-button {
        width:130px;
        background-color:#6699ff;
    }
    .reset-button {
        width:130px;
        background-color:#c70039;
    }
    .list-icon {
        height: 20px;
        width: 20px;
    }
    .facility-link {
        color: #000000;
        cursor: pointer;
        text-decoration: none;
    }
</style>