import apiHandler from '@/shared/apiHandler';
import {defineStore, mapActions} from 'pinia';
import config from '../../public/applicationsettings.json';
import {useAuthenticationStore} from "@/stores/authentication.module";
import {useApplicationSettingsStore} from "@/stores/applicationsettings.module";



export const useStudySpaceStore = defineStore('studyspaceData', {
    state: () => ({
        studyspaces: {}
    }),
    actions: {

        ...mapActions(useAuthenticationStore, ['buildAuthHeaders']),

        async getStudySpaces(){
            this.isLoading = true;

            let token = await apiHandler.getSsoToken();
            let subscriptionKey = useApplicationSettingsStore().idfsSubscriptionKey;
            const authHeaders = this.buildAuthHeaders(token, subscriptionKey);

            // - set url, this changes in azure deploy depending on whether dev, or live/QA
            let endpointURL = config.Endpoint.Talend.Url + useApplicationSettingsStore().endpointTalendPath;
            let StudySpacePath = endpointURL + config.StudySpaceLive.Host;

            await apiHandler.apiGet(`${StudySpacePath}`, authHeaders)
                .then ((result) => {
                    this.studyspaces = {
                        segments: result.data.segments,
                    };
                }).catch((error) => {
                    throw error;
                });
        }
    },
});
