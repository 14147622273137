<template>
    <the-alert />
    <div>Take an ID photo, crop  and upload</div>
    <picture-taker v-on:takePicture="this.takeSnap" />
    <picture-display2 v-on:hideSnap="this.hideSnap" v-on:cropIt="this.cropSnap" v-on:uploadCrop="this.uploadCrop" v-on:reCrop="this.reCrop"/>
</template>

<script>
    import TheAlert from "@/components/TheAlert.vue";
    import PictureTaker from "@/components/PictureTaker.vue";
    import PictureDisplay2 from "@/components/PictureDisplay2.vue";
    export default {
        name: "CameraView2",
        components: {TheAlert, PictureTaker, PictureDisplay2},
        computed: {},
        created() {
            this.init();
        },
        methods: {
            reCrop: function() {
                this.setCameraDisplay(2);
                /**
                 * HIDE
                 * croppedSnap = document.getElementById("croppedSnap");
                 * or rather 'final-image' as that holds the controls as well
                 * SHOW
                 * snap-container
                 */
            },
            uploadCrop: function() {
                //console.log("UPLOADING!!!!");
                alert("To Be Implemented");
            },
            takeSnap: function() {
                /** take a snapshot from the camera video
                 *  hide the video feed
                 *  display the snapshot with the cropping controls
                **/
                const srcRect = this.getSourceRect();

                const theSnap = document.getElementById("snap-canvas");
                if (theSnap) {
                    let snapContainer = theSnap.parentElement;
                    snapContainer.width = theSnap.width = srcRect.width;
                    snapContainer.height = theSnap.height = srcRect.height;
                    snapContainer.style.width = snapContainer.width + 'px';

                    const ctx = theSnap.getContext("2d");
                    ctx.imageSmoothingEnabled = true;
                    ctx.imageSmoothingQuality = "high";

                    ctx.drawImage(document.querySelector("video#idcardvideo"), 0, 0, srcRect.width, srcRect.height);  //, srcRect.width, srcRect.height);
                    //this.displaySnap(true);
                    this.setCameraDisplay(2);
                }
            },
            getSourceRect: function () {
                /**
                 * Get actual dimensions of the camera stream.
                 * Get the dimensions of the display element
                 * Get the ratio of the two ie
                 * Video 1280px wide
                 * Screen display 360px
                 * ratio 1280/360 = 3.55:1
                 * A box 80px in from the left, 160px wide and 200px high translates to
                 *      284px in                568px wide     710px high on the video stream
                 */
                let srcElement = document.getElementById("idcardvideo");
                let rect = srcElement.getBoundingClientRect();
                return rect;
            },
            setCameraDisplay: function(stage = 1) {
                /** stage 1:video to take snap. 2:snap taken ready to crop. 3:cropped image ready for upload **/
                let video = document.getElementById("camera-block");
                let snap = document.getElementById("snap-container");
                let crop = document.getElementById("final-image");
                switch (stage) {
                    case 3:
                        crop.style.display = "block";
                        snap.style.display = "none";
                        video.style.display = "none";
                        break;
                    case 2:
                        crop.style.display = "none";
                        snap.style.display = "block";
                        video.style.display = "none";
                        break;
                    case 1:
                    default:
                        crop.style.display = "none";
                        snap.style.display = "none";
                        video.style.display = "block";
                }
            },
            displaySnap: function(show = true) {
                let thePic = document.querySelector("#snap-container");
                let theVideo = document.querySelector("video#idcardvideo");
                let cropCanvas = document.getElementById('croppedSnap');
                if (thePic) {
                    if (show) {
                        thePic.style.display = "inline-block";
                        cropCanvas.style.display = "block";
                        theVideo.style.display = "none";
                    } else {
                        thePic.style.display = "none";
                        theVideo.style.display = "inline-block";
                        if (cropCanvas) {
                            cropCanvas.style.display = "none";
                            let ctx = cropCanvas.getContext('2d');
                            ctx.clearRect(0, 0, cropCanvas.width, cropCanvas.height);
                            cropCanvas.width = 0;
                            cropCanvas.height = 0;
                        } else {
                            //
                        }
                    }
                }
            },
            hideSnap: function() {
                //this.displaySnap(false);
                this.setCameraDisplay(1);
            },
            cropSnap: function() {
                this.setCameraDisplay(3);
                /**
                 * get crop region and copy it into
                 */
            },
            init: function() {
                //console.log("Initialising CameraView2.vue");
            },
        },

    }
</script>

<style scoped>

</style>
