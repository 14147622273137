//strings.js
//vue plugin to store standardised text in a simple way, to avoid polluting components and making changes easier.

export default {
    install: (app, options) => {
        app.config.globalProperties.$strings = (key) => {
            return key.split('.').reduce((o, i) => {
                if (o) return o[i];
            }, options)
        }
    }
}