import { PublicClientApplication, InteractionRequiredAuthError, CacheLookupPolicy } from '@azure/msal-browser';
import axios from 'axios';
import { useAuthenticationStore } from '@/stores/authentication.module';
import logger from '@/shared/logger';

export default {
    async getSsoToken() {
        const authenticationStore = useAuthenticationStore();

        const msalInstance = new PublicClientApplication(authenticationStore.msalConfig);
        await msalInstance.initialize();
        
        try {
            let accountObj = authenticationStore.account;
            if (accountObj) {
                // User has logged in, but currently has no token, now try to get a token silently
                return await msalInstance.acquireTokenSilent({
                    account: accountObj,
                    scopes: [authenticationStore.msalConfig.auth.apiScope],  // ['offline_access', 'openid', 'profile', 'email', authenticationStore.msalConfig.auth.apiScope],
                    //cacheLookupPolicy: CacheLookupPolicy.RefreshToken,
                    //forceRefresh: true, // Used for debugging - should be avoided in production
                }).then((tokenResponse) => {
                    // Acquire token silent success
                    return {
                        "accessToken": tokenResponse.accessToken,
                        "idToken": tokenResponse.idToken
                    };
                }).catch(async (error) => {
                    // Silent token request failed - interaction required, performing token redirect
                    if (error instanceof InteractionRequiredAuthError) {
                        await msalInstance.acquireTokenRedirect({
                            scopes: [authenticationStore.msalConfig.auth.apiScope],
                            state: window.location.pathname
                        });
                    }
                });
            }
        } catch (error) {
            //console.log("Unhandled redirect error", error)
            logger.logException(error);
        }
    },
    async apiGet(apiUrl, data, config) {
        // Config param is an object with any required headers, e.g. { headers: { "Authorization": `Bearer ${token}` } }
        return axios.get(apiUrl, data, config)
            .then((result) => {
                return result;
            })
            .catch((error) => {
                throw error;
            })
            .finally(() => {
            });
    },
    async apiPost(apiUrl, data, config) {
        return axios.post(apiUrl, data, config)
            .then((result) => {
                return result;
            })
            .catch((error) => {
                throw error;
            })
            .finally(() => {
            });
    },
    async apiPut(apiUrl, data, config) {
        return axios.put(apiUrl, data, config)
            .then((result) => {
                return result.data;
            })
            .catch((error) => {
                //can log this at the api level, but throw up to the store
                throw error;
            })
            .finally(() => {
            });
    },
    async apiDelete(apiUrl, config) {
        return axios.delete(apiUrl, config)
            .then((result) => {
                return result.data;
            })
            .catch((error) => {
                throw error;
            })
            .finally(() => {
            });
    }
};
