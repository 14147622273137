<!--
    Displays the frame to use as a guide for selecting the crop region of the 'snap'
    Frame can be resized to a certain aspect ratio
    Frame can be grabbed, and dragged and dropped to new position.
-->
<template>
    <div id="fake-cropper">
        <div id="anim-finger-icon"></div>
        <div id="fake-cropper-handle" class="cropper-handle" v-on:mousedown="startResize" v-on:touchstart="startResize" ></div>
    </div>
    <div id="the-cropper" v-on:mousedown="cropperMouseDown" v-on:mouseup="mouseUp" v-on:touchend="mouseUp" v-on:touchstart="cropperMouseDown">
        <div id="cropper-handle" class="cropper-handle" v-on:mousedown="startResize" v-on:touchstart="startResize" ></div>
    </div>

</template>


<script>
/* eslint-disable */

    //import PictureDisplay2 from "@/components/PictureDisplay2.vue";

    import {mapActions, mapState} from "pinia";
    import {useCropperObserverStore} from "@/stores/cropperobserver.module";

    export default {
        name: "PictureCropper",
        /*emits: 'hideSnap',*/
        mounted() {
            this.showCount++;
            //console.log("showCount: " + this.showCount);
            this.isMounted();
            this.isFirstView = true;
            this.resizing = false;
            this.grabbing = false; // don't really need, never tested?
            this.dragging = false;
            this.cropTooBig = false;
            this.cropper = document.getElementById("the-cropper");
            this.cropperTop = null;
            //this.cropperRight = null; // - not REALLY used
            // - great if we can get a way of having this passed through from it's containing component
            this.cropperContainer = null; //this.cropper.nextElementSibling;
            this.cavasBoundingRect = null; //this.cropperContainer.getBoundingClientRect();
            this.canvasW; // = this.cavasBoundingRect.width;
            this.canvasH; // = this.cavasBoundingRect.height;
            this.cropperMaxRight; // the max right co-ord allowable before extending off canvas right edge
            //this.cropperMaxB; // the max bottom co-ord allowable before extending off canvas bottom edge
            this.mouseOffsetX; // start position of the mouseX in relation to the cropper top left corner. Used for dragging calculations
            this.mouseOffsetY; // start position of the mouseY in relation to the cropper top left corner. Used for dragging calculations
            this.cropperOffsetX;
            this.cropperOffsetY;
            this.ratio = 0.75; // (height/width for portrait. w/h for landscape) this comes in at 300x400, the ratio of most staff pics I could see.
            //this.resizeSnapContainer(400,200);
            this.init();
        },
        computed: {
            ...mapState(useCropperObserverStore, ['timesViewed']),
        },
        created() {
            this.showCount = 0;
            window.addEventListener('mouseup', this.mouseUp);
            window.addEventListener('touchend', this.mouseUp);
            //console.log("timesViewed " + this.timesViewed);
        },
        methods: {
            ...mapActions(useCropperObserverStore, ["incViews", "getViews", "getIsFirst"]),
            /** Open this up to calling components to send the canvas element for dimensions etc **/
            setCanvas: function () {
                this.cropperContainer = this.cropper.nextElementSibling;
                this.cavasBoundingRect = this.cropperContainer.getBoundingClientRect();
                this.canvasW = this.cavasBoundingRect.width;
                this.canvasH = this.cavasBoundingRect.height;
                this.cropperMaxRight = Math.floor(this.cavasBoundingRect.right);
                this.cropperMaxBottom = Math.floor(this.cavasBoundingRect.bottom);
                this.getCropperEdges();
            },
            getCropperEdges() {
                this.cropperTop = this.cropper.getBoundingClientRect().top; // !! this will need resetting on drag/drop !!
                this.cropperRight = this.cropper.getBoundingClientRect().right;
            },
            getCanvas: function() {
                // TODO: pass this through from the parent so it's not hard coded.
                return document.getElementById("snap-canvas");
            },


            /** drag 'n' drop stuff ****************************************************/
            cropperMouseDown: function (e) {
                if (!this.resizing) {
                    e.preventDefault();
                    this.grabbing = true;
                    this.dragging = true;
                    this.setCanvas();
                    this.setMouseOffset(e);
                    e.target.style.cursor = 'grabbing';
                    window.addEventListener('mousemove', this.cropperMouseMove);
                    window.addEventListener('touchmove', this.cropperMouseMove);
                    document.body.style.userSelect = "none";
                }
            },
            setMouseOffset: function (e) {
                let cropperRect = this.cropper.getBoundingClientRect();
                let pageX = e.pageX || e.targetTouches[0].pageX;
                let pageY = e.pageY || e.targetTouches[0].pageY;
                this.getCropperEdges();

                this.mouseOffsetX = pageX - cropperRect.left;
                this.mouseOffsetY = pageY - cropperRect.top;
                this.cropperOffsetX = this.mouseOffsetX + this.cavasBoundingRect.left;
                this.cropperOffsetY = this.mouseOffsetY + this.cavasBoundingRect.top;
            },
            cropperMouseUp: function (e) {
                if (this.dragging) {
                    this.grabbing = false;
                    this.dragging = false;
                    this.cropper.style.cursor = 'grab';
                    document.body.style.userSelect = "initial";
                } else {
                    let pageX = e.pageX || e.targetTouches[0].pageX;
                }
            },
            cropperMouseMove: function(e) {
                e.preventDefault();
                let pageX = e.pageX || e.targetTouches[0].pageX;
                let pageY = e.pageY || e.targetTouches[0].pageY;
                this.cropper.style.left = (pageX - this.cropperOffsetX) + 'px';
                this.cropper.style.top = (pageY - this.cropperOffsetY) + 'px';
                this.constrainWithin(this.cropper, this.cropperContainer);
            },


            /** resize stuff ****************************************************/
            startResize: function (e) {
                e.preventDefault();
                this.resizing = true;
                if (this.cropperContainer === null) {
                    this.setCanvas();
                }
                window.addEventListener('mousemove', this.resizeCropper);
                window.addEventListener('touchmove', this.resizeCropper);
            },
            resizeCropper: function(e) {
                /**
                 * cropper is being dragged
                 * read the width and calculate the height based on ratio
                 * check right and bottom don't extend past the snap border
                 */
                this.cropTooBig = false;
                let pageX = e.pageX || e.targetTouches[0].pageX;
                let newWidth = pageX - this.cropper.getBoundingClientRect().left;
                let newHeight = Math.floor(newWidth / this.ratio);
                let cropperBottom = this.cropperTop + newHeight;
                if (pageX > this.cropperMaxRight || cropperBottom > this.cropperMaxBottom) {
                    this.cropTooBig = true;
                }
                //this.checkConstraints(this.cropper, this.cropper.nextElementSibling);
                if (!this.cropTooBig) {
                    this.cropper.style.width = newWidth + 'px';
                    this.cropper.style.height = newHeight + 'px'; //e.pageY - this.cropper.getBoundingClientRect().top + 'px';
                }
                //this.constrainWithin(this.cropper, this.cropper.nextElementSibling);
            },


            mouseUp: function (e) {
                this.cropperContainer = null;
                if (this.resizing) {
                    this.resizing = false;
                    window.removeEventListener('mousemove', this.resizeCropper);
                    window.removeEventListener('touchmove', this.resizeCropper);
                } else if (this.dragging) {
                    this.cropperMouseUp(e);
                    window.removeEventListener('mousemove', this.cropperMouseMove);
                    window.removeEventListener('touchmove', this.cropperMouseMove);
                }
            },
            constrainWithin: function(childEl, containerEl) {
                const childRect = childEl.getBoundingClientRect();
                const containerRect = containerEl.getBoundingClientRect();
                //console.log("child ", childRect);
                //console.log("parent: ", containerRect);
                if (isNaN(childRect.width) || childRect.width > containerRect.width) {
                    //childRect.width = containerRect.width;
                    childEl.style.width = (containerRect.width - 0) + 'px';
                    childEl.style.left = '0px';
                }
                if (isNaN(childRect.height) || childRect.height > containerRect.height) {
                    //childRect.height = containerRect.height;
                    childEl.style.height = (containerRect.height - 0) + 'px';
                    childEl.style.top = '0px';
                }

                if (childEl.offsetLeft <= 0) { //} || this.faceFrame.offsetTop <= 0) {
                    childEl.style.left = '0px';
                }
                if (childEl.offsetTop <= 0) { //} || this.faceFrame.offsetTop <= 0) {
                    childEl.style.top = '0px';
                }

                /** drop to the right of the canvas right edge **/
                if((childEl.offsetLeft + childRect.width) > containerRect.width) {
                    //childEl.style.width = ((containerRect.width - childEl.offsetLeft) - 0) + 'px';
                    childEl.style.left = (containerRect.width - childRect.width) + 'px';
                }
                /** drop below bottom of canvas **/
                if((childEl.offsetTop + childRect.height) > containerRect.height) {
                    //childEl.style.height = ((containerRect.height - childEl.offsetTop) - 0) + 'px';
                    childEl.style.top = (containerRect.height - childRect.height) + 'px';
                }
            },
            init: function () {
                //console.log("Initialising PictureCropper2.vue");
                //console.log("the cropper element from created.init : ", document.getElementById("the-cropper"));

                //console.log("INITIALISING!!");
                /** do not display animation if the user has already seen it **/
                this.isFirstView = this.getIsFirst() || true;
                //console.log("First View????? " + this.isFirstView);
                let theFakeCropper = document.getElementById("fake-cropper");
                if (theFakeCropper) {
                    if (this.isFirstView) {

                        let realCropper = document.getElementById("the-cropper");
                        if (realCropper && this.isFirstView) {
                            realCropper.classList.add("hidden");
                        }
                        theFakeCropper.addEventListener("animationend", (ev) =>
                        {
                            theFakeCropper.classList.add("no-animation");
                            if (realCropper) {
                                realCropper.classList.remove("hidden");
                            }
                        });
                    } else {
                        this.removeAnimation();
                        //console.log("Removed animation dude");
                    }
                }
                this.incViews();
                /** ==================================== **/
            },
            isMounted: function() {
                //console.log("Mounted PictureCropper2.vue");
                //console.log("element: ", document.getElementById("the-cropper"));
            },
            removeAnimation: function() {
                //console.log("Removing animations");
                document.getElementById("fake-cropper").classList.add('no-animation');
                document.getElementById("fake-cropper-handle").classList.add('no-animation');
            }
        },
    }
</script>



<style scoped>

    /** added full- to these animations to switch them off - using shorter animation frames now.
     * Can delete either once we're sure which we prefer
    **/
    @keyframes full-animDrag {
        0%   { left:0px; top:0px; display: inline-block; } /* start move frame to second position */
        15%  { left:20%; top:20%;/* width: 120px; height:150px;*/ }
        /* 15 - 20: fingerMove - move finger down */
        20%  { width: 120px; height:150px; } /* grow frame */
        38%  { width: 180px; height:225px; }
        42%  { width: 180px; height:225px; } /* shrink frame */
        60%  { width: 120px; height:150px; }
        /* 60 - 65: fingerMove - move finger down */
        75%  { left:20%; top:20%;} /* start move frame back to start */
        99%  { opacity: 100%; }
        100% { left:0px; top:0px; opacity: 0; }
    }
    @keyframes full-fingerMove {
        0%   { opacity: 100%; display: inline-block; }
        15%  { left: 40%; top: 40%; } /* move finger to bottom right of frame */
        20%  { left: 95%; top: 95%; background-image: url("~@/assets/icons/finger-drag-move.png"); }
        21%  { background-image: url("~@/assets/icons/finger-drag-resize.png"); }
        /* 20 - 60: animDrag - grow and shrink frame */
        60%  { left: 95%; top: 95%; } /* move finger back to middle of frame */
        65%  { left: 40%; top: 40%; background-image: url("~@/assets/icons/finger-drag-resize.png"); }
        66%  { background-image: url("~@/assets/icons/finger-drag-move.png"); }
        99%  { opacity: 100%; }
        100% { opacity: 0; }
    }

    @keyframes animDrag {
        0%   { left:0px; top:0px; display: inline-block; } /* start move frame to second position */
        35%  { left:20%; top:20%;/* width: 120px; height:150px;*/ }
        /* 15 - 20: fingerMove - move finger down */
        60%  { width: 120px; height:150px; } /* grow frame */
        78%  { width: 180px; height:225px; }
        /*42%  { width: 180px; height:225px; }*/ /* shrink frame */
        /*60%  { width: 120px; height:150px; }*/
        /* 60 - 65: fingerMove - move finger down */
        /*75%  { left:20%; top:20%;}*/ /* start move frame back to start */
        75%  { opacity: 100%; }
        100% { width: 180px; height:225px; left:20%; top:20%; /*left:0px; top:0px;*/ opacity: 0; }
    }
    @keyframes fingerMove {
        0%   { opacity: 100%; display: inline-block; }
        35%  { left: 40%; top: 40%; } /* move finger to bottom right of frame */
        49%  { left: 95%; top: 95%; background-image: url("~@/assets/icons/finger-drag-move.png"); }
        60%  { background-image: url("~@/assets/icons/finger-drag-resize.png"); }
        /* 20 - 60: animDrag - grow and shrink frame */
        /*90%  { left: 95%; top: 95%; }*/ /* move finger back to middle of frame */
        95%  { /*left: 40%; top: 40%;*/ background-image: url("~@/assets/icons/finger-drag-resize.png"); }
        /*66%  { background-image: url("~@/assets/icons/finger-drag-move.png"); }*/
        75%  { opacity: 100%; }
        100% { left: 95%; top: 95%; opacity: 0; }
    }

    .no-animation {
        animation: none !important;
        display: none;
    }
    .hidden {
        display: none;
    }
    #fake-cropper{
        animation-name: animDrag;
        animation-duration: 3s;
        animation-timing-function: linear;
        animation-delay: 500ms;
        position: absolute;
        width: 120px;
        height: 150px;
        border: solid 3px #CCC;
        top: 0;
        left: 0;
        cursor: grab;
        overflow: visible;
        aspect-ratio: 16/9;
    }
    #anim-finger-icon {
        animation-name: fingerMove;
        animation-duration: 3s;
        animation-timing-function: linear;
        animation-delay: 500ms;
        width: 64px;
        height: 64px;
        background-color: transparent;
        position: relative;
        top: 40%;
        left: 40%;
        /** https://stackoverflow.com/questions/51812496/vue-cli-3-use-background-image-in-style-tag **/
        background-image: url("~@/assets/icons/finger-drag-move.png");
        background-size: contain;
        background-repeat: no-repeat;
        opacity: 0;
    }
    #the-cropper{
        position: absolute;
        width: 120px;
        height: 150px;
        border: solid 3px #CCC;
        top: 0;
        left: 0;
        cursor: grab;
        overflow: visible;
        aspect-ratio: 16/9;
    }
    #cropper-handle, .cropper-handle {
        border: solid 1px #FFF;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #999;
        position: absolute;
        bottom: -10px;
        right: -10px;
        cursor: nwse-resize;
        z-index: 10;
    }
</style>
