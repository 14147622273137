<!-- eslint-disable max-len -->
<template>
    <!-- Crimson shiz -->
    <!--*************************************************************-->
    <!--*************************************************************-->
    <!--*************************************************************-->
    <div class="portal-NOT navbar-inverse navbar-static-top">
        <div class="container-NOT navbar-portal" >
            <nav class="navbar navbar-expand-lg navbar-dark navbar-space-between">
                    <a class="navbar-brand" href="https://ncl.ac.uk" target="newcastle_university">
                        <!--<div class="ncl-logo" id="digitalid-nulogo-head"><img src="/img/nulogo.svg" alt="Newcastle University Logo" /></div>-->
                        <img src="img/Newcastlelogo.png" alt="Newcastle University" style="width: auto; margin: 0 10px;">
                        <h1 class="siteTitle"></h1>
                    </a>

                <button class="navbar-toggler"
                        id="navbar-toggler0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#nav-top-collapse0"
                        aria-controls="nav-top-collapse0"
                        aria-expanded="false"
                        aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="nav-top-collapse0">
                    <ul class="nav navbar-nav weblinks navbar-expand-lg navbar-nav">
                        <li class="divider-inbox" aria-hidden="true"></li>
                        <li class="weblink">
                            <a class="nav-link" aria-label="Home" :href="$strings('portal.URL')" aria-roledescription="link" title="return to the Student Portal Dashboard">
                                Home <!--Portal Dashboard-->
                            </a>
                        </li>
                        <li class="divider-vertical" aria-hidden="true"></li>
                        <li class="weblink ">
                            <a aria-label="FAQs" :href="$strings('portal.URL') + /knowledgebase/" aria-roledescription="link" title="FAQs">
                                Help
                            </a>
                        </li>
                        <li class="divider-vertical" aria-hidden="true"></li>
                        <HeaderAuth />
                    </ul>
                </div>
            </nav>
        </div>
    </div>

    <div v-if="!isLoggedIn" class="container">
        <div>
            <h3>Login</h3>
        </div>
        <div>
            <div v-html="$strings('main.welcomeNoAuth')"></div>
        </div>
    </div>
    <!-- og stuff -->
    <div v-if="showOgLogo">
        <div id="uni-main-logo" class="row mx-0">
            <div class="col-sm">
                <a href="https://www.ncl.ac.uk/" target="_blank">
                    <div class="ncl-logo" id="digitalid-nulogo-head"><img src="/img/nulogo.svg" alt="Newcastle University Logo" /></div>
                </a>
            </div>
            <div class="col-sm"></div>
        </div>
    </div>
    <!--*************************************************************-->
    <!--*************************************************************-->
    <!--*************************************************************-->
    <div id="mainmenu" v-if="showMainMenu" class="container">
        <nav class="navbar navbar-expand-lg navbar-dark bg-dark-nu">
            <a class="navbar-brand" href="">
                <span style="text-decoration:line-through">{{ $strings('application.title') }}</span>
                <span v-if="environment === 'dev'" class="badge badge-warning">DEV</span>
                <span v-if="environment === 'qa'" class="badge badge-warning">QA</span>
            </a>
            <button class="navbar-toggler"
                    id="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#nav-collapse"
                    aria-controls="nav-collapse"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="nav-collapse">
                <ul v-if="isLoggedIn" id="nav-auth-left" class="navbar-nav" v-on:click="closeNav">
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'MainView' }">Home</router-link>
                    </li>
                    <li class="nav-item" v-if="isLoggedIn">
                        <router-link class="nav-link" :to="{ name: 'MyPhoto' }">My Photo</router-link>
                    </li>
                    <li class="nav-item" v-if="isLoggedIn">
                        <router-link class="nav-link"
                                     :to="{ name: 'CampusMapView' }">Campus Map</router-link>
                    </li>
                    <li class="nav-item" v-if="isLoggedIn">
                        <router-link class="nav-link"
                                     :to="{ name: 'FindAPCView' }">Find a PC</router-link>
                    </li>
                    <li class="nav-item" v-if="isLoggedIn">
                        <router-link class="nav-link"
                                     :to="{ name: 'NewsListingView' }">News</router-link>
                    </li>
                    <li class="nav-item" v-if="isLoggedIn">
                        <router-link class="nav-link"
                                     :to="{ name: 'TimetableView' }">My Timetable</router-link>
                    </li>
                    <li class="nav-item" v-if="isLoggedIn">
                        <router-link class="nav-link"
                                     :to="{ name: 'DirectoryView' }">Directory</router-link>
                    </li>
                    <li class="nav-item" v-if="isLoggedIn">
                        <router-link class="nav-link"
                                     :to="{ name: 'LibraryView' }">Library</router-link>
                    </li>
                    <li class="nav-item" v-if="isStudent">
                        <router-link class="nav-link"
                                     :to="{ name: 'StudySpaceView' }">Study Space</router-link>
                    </li>
                    <li class="nav-item" v-if="isStudent">
                        <router-link class="nav-link"
                                     :to="{ name: 'PersonalTutorView' }">Personal Tutor</router-link>
                    </li>
                    <!--li class="nav-item" v-if="isLoggedIn">
                        <router-link class="nav-link"
                                     :to="{ name: 'SearchView' }">Search</router-link>
                    </li-->
                    <li class="nav-item" v-if="isLoggedIn">
                        <router-link class="nav-link"
                                     :to="{ name: 'AboutView' }">About</router-link>
                    </li>
                    <li class="nav-item" v-if="isLoggedIn">
                        <router-link class="nav-link"
                                     :to="{ name: 'HelpView' }">Help</router-link>
                    </li>
                    <!--<li class="nav-item" v-if="isAdmin">
                        <router-link class="nav-link" :to="{ name: 'AdminTestView' }">Admin</router-link>
                    </li>-->
                </ul>
                <div id="menuhider" title="hide menu" v-on:click="hideNavbar">x</div>
            </div>
            <!--<HeaderAuth />-->
        </nav>
    </div>
</template>

<script>

/** NOTE
 * navbar-nav on:click to close the menu on clicking a new
 * inititally wanted to addEventListener to each <li>, but hit some DOM build problems
 * getElementsByClass would populate an HTMLCollection object, but report .length as zero when it came to iteration.
 * If anyone KNOWS the reason, or solution, it's not too late, but adding the v-on:click to the <ul> seems to be working fine
 */

    import HeaderAuth from "@/components/HeaderAuth";
    import { mapState } from 'pinia'
    import { useAuthenticationStore } from '@/stores/authentication.module';
    import { useApplicationSettingsStore } from '@/stores/applicationsettings.module';

    export default {
        name: "TheHeader",
        components: {
            HeaderAuth
        },
        watch: {
        },
        computed: {
            ...mapState(useAuthenticationStore, ['isLoggedIn', 'isAdmin', 'isStaff', 'isStudent']),
            ...mapState(useApplicationSettingsStore, ['environment']),
             currentYear() {
                return new Date().getFullYear();
            },
            showMainMenu() {
                return this.isLoggedIn && this.isAdmin;
            },
            showOgLogo() {
                return false;
            },
        },
        methods: {
            closeNav: function() {
                let theToggler = document.getElementById("navbar-toggler");
                if (theToggler) {
                    //console.log(theToggler);
                    theToggler.click();
                }
            },
            hideNavbar: function() {
                let mainmenu = document.getElementById("mainmenu");
                if (mainmenu) {
                    mainmenu.classList.add("hidden");
                }
            },
        },
    };
</script>

<style scoped>
    #menuhider {
        position: absolute;
        top: 0;
        right: 1em;
        cursor: pointer;
    }
    .open>.dropdown-menu {
        display: block;
    }
</style>
