
import apiHandler from '@/shared/apiHandler';
import {defineStore, mapActions} from 'pinia';
import moment from 'moment-timezone';
import config from '../../public/applicationsettings.json';

import { useRoute } from 'vue-router';
import {useIdCardInfoStore} from "@/stores/idcardinfo.module";
import {useAuthenticationStore} from "@/stores/authentication.module";
import {useTimetableStore} from "@/stores/timetable.module";
import {useWeeksOfYearStore} from "@/stores/weeksofyear.module";
import {useApplicationSettingsStore} from "@/stores/applicationsettings.module";
import { formatUserId } from '@/shared/stringFunctions';


const TEACHING = 1;
const EXAM = 2;

const TimetableHost = config.TimetableDataIDFS;

/*
 Decent sno to test with (?sno=nnnnnnn)
 230688900 - Law
 220064440 - Law 2
 230748329 - Dental PG (very few)
 230269215 - APL
 220147525 - Psychology
 220312684 - SchoolX
 And staff with nid
 nca84
 */
export const useMyTimetableStore = defineStore('mytimetablestore', {
    //persist: true,
    state: () => ({
        modules: [],
        weeks: [],
        timetabledata: {
            ttmodules: {
                Name: undefined,
                Description: undefined,
                HostKey: undefined,
                displayColours: {
                    bgCol: undefined,
                    textCol: undefined
                },
                thisweek: undefined,
            },
            updatedAt: undefined,
            checkDate: undefined,
            tnow: undefined,
        },
        examdata: {
            updatedAt: undefined,
        },
        myRawData: {},
        Activities: {},
        weekActivities: {},
        letterColMap: {
            /* assign a value to the first 3 letters of a module to generate a background colour */
            A:10, B:20, C:29, D:39, E:49, F:59, G:69, H:78, I:88, J:98, K:108, L:118, M:127, N:137, O:147, P:157, Q:167, R:176, S:186, T:196, U:206, V:216, W:225, X:235, Y:245, Z:255,
        },
        moduleColours: {

        },
        /**
         * userId should not need to be hard coded once the actual users are logged in.
         */
        userId: "230029424", //"220423131", //"230211133", //"namb10", //"220678199", //"230029424" /* two exams */ //"130086439", //"120269079", //"220678199" /* has exam */, //"200367048", //"220678199",
        tt1: useTimetableStore(),
        timeAdjust: 60*60*24*365*70,
        weeksOfYear: useWeeksOfYearStore(),
        physicalWeekNumber: null,
        weekZero: null,
        ttErrors: [],
        ErrorResponse: 0,
    }),
    actions: {

        ...mapActions(useAuthenticationStore, ['buildAuthHeaders']),

        async setWeekOfYear() {
            if (this.weeksOfYear.physicalWeekNo === null) {
                await this.weeksOfYear.getWeeks();
            }
            this.physicalWeekNumber = this.weeksOfYear.physicalWeekNo;
        },
        async getTimetableErrors() {
            //console.log(this.ttErrors);
            return "Message from getTimetableErrors";
            //return this.ttErrors;
        },
        /**
         *
         * @param tt_type "exam" or "TEACHING"
         * @returns {Promise<void>}
         */
        async getTimetable(tt_type = 1) {

            let self = this;
            /* console.log("tt_type is " + tt_type); */

            const auth = useAuthenticationStore();
            // - will be one of 'staffid' or 'studentnumber' depending on status of current user
            let userIdentifier;

            const route = useRoute();
            let adminHack = false;
            let forceFail = false;
            const userIsAdmin = auth.isAdmin;
            const allowQStrOverride = auth.isStaff && config?.Application?.Environment === "dev";
            // - allow admin to test with other user IDs by adding nid=nid123 or sno=123456789 to the url qStr
            if (userIsAdmin || allowQStrOverride) {
                /* console.log("Yup, you're Admin " + route.query.sno); */
                if (route?.query?.sno) {
                    this.userId = route.query.sno;
                    userIdentifier = "studentnumber";
                    adminHack = true;
                } else if (route?.query?.nid) {
                    this.userId = route.query.nid;
                    userIdentifier = "staffid";
                    adminHack = true;
                }
                if (route?.query?.failtt) {
                    forceFail = true;
                }

            }
            /* console.log("Here 1"); */
            let refreshData = route?.query?.forcerf !== undefined;

            const cacheExpiryHours = 1;
            const cacheExpiryMins = 5;

            // - accessing other stores' actions and state. https://pinia.vuejs.org/core-concepts/actions.html
            // - get user details if user hasn't visited ID Card screen yet
            const userName = formatUserId(auth.getUsername);

            await this.setWeekOfYear();
            // - student timetables use 'studentnumber' as the identifier, staff uses n-id, need to sniff out here and set accordingly
            let userIsStaff;
            try {
                userIsStaff = auth.isStaff;
            } catch (e) {
                //console.log("auth", auth);
                console.log("timetables error: ", e);
                throw e;
            }

            if (!adminHack) {
                if (userIsStaff) {
                    userIdentifier = "staffid";
                } else {
                    userIdentifier = "studentnumber";
                }
                this.userId = userName;
            }

            let isMasquerading = false; // - MUST be false before deploying
            /* console.log("Admin hack is: " + adminHack); */
            // - Only allow hard coded masquerading for testing on localhost. This makes sure it's not active on deploy.
            if (location.hostname === "localhost" || userIsAdmin) {
                isMasquerading = false; // - allow override only on localhost
                if (isMasquerading && !adminHack) {
                    console.log("Masquerading!!");
                    let fakeStaff = false;// - quick switch for masquerading as staff or student
                    if (fakeStaff) {
                        console.log("Faking staff");
                        // - userId must be an n-id
                        userIdentifier = "staffid";
                        this.userId = "nid38"; //"naj133"; "npg63"; userName;
                    } else {
                        console.log("Faking Student");
                        // - userId must be a student number - long int such as 220423131 or 230323322
                        userIdentifier = "studentnumber";
                        this.userId = "220309422"; //220627935 230029424 230707348 230323322 220423131 230323322; // <- test student with only one module in semester 2 showing (has two optional not pulling through from/in canvas)
                    }
                }
            }
            const userQStr = userIdentifier + "=" + this.userId;
            // - only allow one of two types of timetable type
            tt_type = tt_type === EXAM ? EXAM : TEACHING;

            const lastRefresh = moment(this.timetabledata?.updatedAt);
            const lastExamRefresh = moment(this.examdata?.updatedAt);
            const dateNow = moment();
            const elapsedHours = dateNow.diff(lastRefresh, 'hours');
            const elapsedMins = dateNow.diff(lastRefresh, 'minutes');
            const elapsedExamHours = dateNow.diff(lastExamRefresh, 'hours');

            this.timetabledata.tnow = new Date();

            // - set url, this changes in azure deploy depending on whether dev, or live/QA
            let endpointURL = config.Endpoint.Talend.Url + useApplicationSettingsStore().endpointTalendPath;

            let forceRefresh = forceFail|| refreshData || isMasquerading; // <- set this to 1 === 1 to force a refresh of data every visit
            console.log("force Refresh: " + forceRefresh + " userQStr " + userQStr);
            if (tt_type === EXAM) { // exams
                //console.log("exams!!");
                if (lastExamRefresh === undefined || elapsedExamHours > cacheExpiryHours || forceRefresh) {
                    /* console.log("Getting fresh data exams"); */
                    this.examdata = {};
                    let examurl = `${endpointURL}${TimetableHost.ApiPath}${TimetableHost.ExamDataPath}?${userQStr}`;
                    //console.log("Exam: " + examurl);
                    let token = await apiHandler.getSsoToken();
                    let subscriptionKey = useApplicationSettingsStore().idfsSubscriptionKey;
                    const authHeaders = this.buildAuthHeaders(token, subscriptionKey);
                    authHeaders.responseType = "application/json";

                    //console.log("examURL: " + examurl);
                    await apiHandler.apiGet(examurl, authHeaders).then(
                        (result) => {
                            //console.log("!!=======*******Exams");
                            this.examdata = {};
                            this.examdata = JSON.parse(result.data); //JSON.parse(fakedata);
                            //console.log("!!*********exam Data: ", this.examdata);
                            this.examdata.updatedAt = new Date();
                            // - remove AM PM from 24hr clock time
                            this.fixExamTime();
                        }
                    ).catch(
                        (error) => {
                            this.examdata = {
                                exams: {}
                            };
                            throw error;
                        }
                    );
                } else {
                    //console.log("Using cache. this.examdata.updatedAt : " + this.examdata?.updatedAt + " elapsedExamHours: " + elapsedExamHours + " forceRefresh: " + forceRefresh);
                }
            } else if (tt_type === TEACHING) { // failsafe get
                //console.log("teaching!!");
                // https://idfs.ncl.ac.uk/mobile/timetables/teaching?studentnumber=<student_number>
                let teacherQstr = "?staffid=naj133";
                let teachingUrl = `${endpointURL}${TimetableHost.ApiPath}${TimetableHost.CwkDataPath}`;
                let testStaffUrl = teachingUrl + teacherQstr;
                let staffId = "npg63"; //"naj133";
                let testStaffUrlOld = "https://m.ncl.ac.uk/itservice/timetableCaches/staffTimetableCache/" + staffId;
                //console.log('testStaffUrlOld ' + testStaffUrlOld);

                //this.timetabledata = {};
                console.log("lastRefresh: " + lastRefresh + " elapsedMins " + elapsedMins + " cacheExpiryMins " + cacheExpiryMins + " this.timetabledata.updatedAt " + this.timetabledata.updatedAt);
                console.log("Force Refresh: " + forceRefresh + " this.timetabledata ", this.timetabledata);
                if (lastRefresh === undefined || elapsedMins >= cacheExpiryMins || this.timetabledata.updatedAt === undefined || forceRefresh) {
                    console.log("Getting fresh timetable data");
                    let token = await apiHandler.getSsoToken();
                    let subscriptionKey = useApplicationSettingsStore().idfsSubscriptionKey;
                    let authHeaders = {};
                    if(forceFail) {
                        authHeaders = {};
                    } else {
                        authHeaders = this.buildAuthHeaders(token, subscriptionKey);
                    }

                    authHeaders.responseType = "application/json";

                    /*
                    let doTest = false;
                    if (doTest) {
                        await apiHandler.apiGet(testStaffUrlOld, authHeaders).then(
                            (result) =>
                            {
                                let staffTt = JSON.parse(result.data);
                                //console.log("staffTimetable: ", staffTt);
                            }).catch((error) =>
                            {
                                throw error;
                            }
                        );
                    }
                    */

                    let cwkUrl = `${endpointURL}${TimetableHost.ApiPath}${TimetableHost.CwkDataPath}`;
                    let qStr = `?${userQStr}`;
                    cwkUrl += qStr;
                    if (forceFail) {
                        cwkUrl += "&ignoreThisItsDigitalTesting"
                    }
                    let tStart = Date.now();
                    this.ttErrors = [];
                    this.ErrorResponse = 0;
                    console.log("URL: " + cwkUrl);
                    await apiHandler.apiGet(cwkUrl, authHeaders).then(
                        //await apiHandler.apiGet(testStaffUrlOld, authHeaders).then(
                        (result) => {
                            this.myRawData = {};
                            tStart = Date.now();
                            let lastDataFetch = new Date(tStart).toLocaleString();
                            console.log("LDF: " + lastDataFetch);
                            const myData = JSON.parse(result.data);
                            this.myRawData = myData.Data;

                            if (this.myRawData.Acts === "") {
                                //console.log("It's an empty string");
                                this.ErrorResponse = 404;
                                this.ttErrors.push({status: 418});
                            }

                            this.verifyData();

                            this.Activities = {};
                            this.Activities = myData.Data.Acts.Act;

                            tStart = Date.now();
                            this.getWeekOneDate();
                            this.fixProperDates();

                            this.timetabledata = {
                                ttmodules: this.myRawData.Modules.Module, //myData.Data.Modules.Module,
                                updatedAt: new Date(),
                                checkDate: new Date(),
                                tnow: new Date(),
                                lastDataFetched: lastDataFetch,
                            };

                            tStart = Date.now();
                            //this.getModuleColours(this.timetabledata.ttmodules);

                            tStart = Date.now();
                            this.fixActivities();

                            // - send activities for iCal download file creation
                            //timetabledownload.buildCoursework(this.Activities);

                            return this.timetabledata;
                        }
                    ).catch(
                        (error) => {
                            if (error.response.status) {
                                console.log("****" + error.response.status + "************************************");
                                this.ErrorResponse = error.response.status;
                                this.ttErrors.push(error.response);
                            } else {
                                console.log("****unknownErrorResponse************************************");
                                this.ErrorResponse = 418;
                                this.ttErrors.push({status: 418});
                            }
                            this.myRawData = {};
                            this.timetabledata = {};
                            //console.log("response status:" + error.response.status); // use this to check for 404
                            //this.ttErrors.response = error.response;
                            //this.ttErrors.push(error.response.status);
                            //console.log("ttErrors: ", this.ttErrors);
                            //console.log("teaching timetable fetch error: ", error);

                            //throw error;
                        }
                    );
                } else {
                    this.timetabledata.tnow = new Date();
                    console.log("Using cache.");
                    console.log("time then " + this.timetabledata?.updatedAt);
                    console.log(typeof this.timetabledata?.updatedAt);
                    console.log("time tnow  " + this.timetabledata?.tnow );
                    console.log(typeof this.timetabledata?.tnow);
                    console.log("time check tnow  " + this.timetabledata?.checkDate );
                    console.log(typeof this.timetabledata?.checkDate);
                    console.log(" elapsedHours: " + elapsedHours + " forceRefresh: " + forceRefresh);
                }
            } else {
                //console.log("Neither exam nor teaching");
                throw new Error("Timetable type " + tt_type + " not recognised");
            }
        },
        verifyData: function() {
            // - horrible, but may be necessary unless idfs fix Single Object vs Array of Objects in the data pull
            // - if(!test) is truthful on "" and unset,
            //console.log("Modules");
            //console.log("Modules pre empty assign: ", this.myRawData.Modules);
            if (!this.myRawData.Modules) {
                this.myRawData.Modules = {};
                //console.log("Modules pre empty assign: ", this.myRawData.Modules);
            }
            this.myRawData.Modules.Module               = this.setObjArrays(this.myRawData.Modules.Module, "Modules");

            //console.log("ActsModules");
            if (!this.myRawData.ActsModules) { this.myRawData.ActsModules = {}; }
            this.myRawData.ActsModules.ActModule        = this.setObjArrays(this.myRawData.ActsModules.ActModule, "ActsModules");

            //console.log("Acts");
            if (!this.myRawData.Acts) { this.myRawData.Acts = {}; }
            this.myRawData.Acts.Act                     = this.setObjArrays(this.myRawData.Acts.Act, "Acts");

            //console.log("WeekAndDays");
            if (!this.myRawData.WeekAndDays) { this.myRawData.WeekAndDays = {}; }
            this.myRawData.WeekAndDays.WeekAndDay       = this.setObjArrays(this.myRawData.WeekAndDays.WeekAndDay, "WeekAndDays");

            //console.log("ActsLocations");
            if (!this.myRawData.ActsLocations) { this.myRawData.ActsLocations = {}; }
            this.myRawData.ActsLocations.ActLocation    = this.setObjArrays(this.myRawData.ActsLocations.ActLocation, "ActsLocations");

            //console.log("Locations " + typeof this.myRawData.Locations);
            if (!this.myRawData.Locations) { this.myRawData.Locations = {}; }
            this.myRawData.Locations.Location           = this.setObjArrays(this.myRawData.Locations.Location, "Locations");
            //console.log("Location2 " + typeof this.myRawData.Locations, this.myRawData.Locations.Location);

            //console.log("ActsStaff");
            if (!this.myRawData.ActsStaff) { this.myRawData.ActsStaff = {}; }
            this.myRawData.ActsStaff.ActStaff           = this.setObjArrays(this.myRawData.ActsStaff.ActStaff, "ActsStaff");
            //console.log(this.myRawData.ActsStaff.ActStaff);

            //console.log("StaffMembers");
            if (!this.myRawData.StaffMembers) { this.myRawData.StaffMembers = {}; }
            this.myRawData.StaffMembers.StaffMember     = this.setObjArrays(this.myRawData.StaffMembers.StaffMember, "StaffMembers");

        },
        setObjArrays: function(obj, objName) {
            let newobj;
            if (obj && typeof obj !== "string") {
                if (obj.length === undefined) {
                    //console.log("Not an array");
                    newobj = [];
                    newobj.push(obj);
                    //obj = newobj;
                    //console.log("Raw Data", this.myRawData.Modules);
                } else {
                    newobj = obj;
                    //console.log("It is an array already");
                }
                //console.log("Setting", obj);
            } else {
                newobj = [];
                //console.log(objName + " is empty");
                this.ttErrors[objName] = "Incomplete data, " + objName + " empty";
            }
            return newobj;
        },
        getWeekOneDate: function() {
            this.weekZero = this.weeksOfYear.weekZero;
        },
        fixProperDates: function() {
            //console.log("fixProperDates");
            if (this.myRawData.WeekAndDays !== undefined) {
                let self = this;
                //console.log(this.myRawData.WeekAndDays);
                let cnt = 0;
                //console.log("cnt: " + cnt);
                this.myRawData.WeekAndDays.WeekAndDay.forEach(function(aDay)
                {
                    cnt++;
                    //console.log("RD", aDay);
                    let dayInYear = (parseInt(aDay.TimetableWeek) * 7) + parseInt(aDay.DayInWeek);
                    // -- adding one hour to the date to compensate for BST/GMT
                    // -- -- GMT to GMT, or BSt to BST will only give 01:00 which doesn't matter as wwe only need the date part
                    // -- -- -- but BST to GMT would translate to 23:00 the previous day which is bad
                    //console.log("day in year: " + dayInYear);
                    aDay.ActualDateMoment = moment(self.daysToMs(dayInYear) + self.weeksOfYear.oneHourInMicro + self.weekZero.TimestampStart);
                    aDay.ActualDate = aDay.ActualDateMoment.format('Do MMM YYYY');
                    //dateNum + self.dateOrdinal(dateNum) + " " + startDate.toLocaleString('default', {month: 'short'}) + " " + startDate.getFullYear();
                    aDay.daysToAdd = dayInYear;

                });
                //console.log("cnt: " + cnt);
            } else {
                //console.log("this.myRawData.WeekAndDays is undefined");
            }
        },
        /**
         *
         * @param days int, how many days to calculate millisecs from
         * @returns {number} number of millisecs in days
         */
        daysToMs: function(days) {
            return days * this.weeksOfYear.oneDayInMicro;
        },
        /**
         * iterate the ttmodules array in timetabledata, calculate a background colour based on the first three letters
         *  - of the module code (see letterColMap, ie GEO = 69,49,147 or #453193), get a contrast colour for the text,
         *   - and add this structure to that module's existing model
         * @param mods
         */
        getModuleColours: function(mods) {
            //console.log("mods: " + typeof mods + ' ' + mods.length, mods);
            if (mods.length === undefined) {
                //console.log("undefined");
                let newmods = [];
                newmods.push(mods);
                mods = newmods;
            }
            //console.log("mods: " + typeof mods + ' ' + mods.length, mods);
            let parentObj = this; // - need to reference this inside forEach, which sets its own 'this' to the current iterant
            let modStr;
            let schoolColours = Array();
            mods.forEach(function(module) {
                modStr = module.Name.substring(0,3);
                module.displayColours = {};
                if (typeof schoolColours[modStr] === 'undefined') {
                    let modCols;
                    modCols = parentObj.getRGB(modStr); //, self);
                    schoolColours[modStr] = {
                        bgCol: '#' + modCols.RHex + modCols.GHex + modCols.BHex,
                        textCol: parentObj.getContrast(modCols),
                    }
                }
                try {
                    module.displayColours = schoolColours[modStr];
                } catch (err) {
                   //console.log('error: ', err);
                }
                return module;
            });
        },
        /**
         Split First three letters of module code and get a value for that letter from letterColMap (0-255)
         - convert that to a hex value and return an object containing both the decimal and hex values for RGB.
         *
         * @param moduleLetters
         * @returns {{R: number, G: number, B: number, RHex: string, GHex: string, BHex: string}}
         */
        getRGB: function (moduleLetters) {
            //let RHex, GHex, BHex, R, G, B;
            let RGBCols = { RHex: "AA", GHex: "AA", BHex: "AA", R: 204, G: 204, B: 204 };

            if (typeof moduleLetters == "string" && moduleLetters.length === 3) {
                RGBCols.R = this.letterColMap[moduleLetters.charAt(0)];
                RGBCols.RHex = RGBCols.R.toString(16).padStart(2,"0");
                RGBCols.G = this.letterColMap[moduleLetters.charAt(1)];
                RGBCols.GHex = RGBCols.G.toString(16).padStart(2,"0");
                RGBCols.B = this.letterColMap[moduleLetters.charAt(2)];
                RGBCols.BHex = RGBCols.B.toString(16).padStart(2,"0");
            }
            return RGBCols;
        },
        /**
         * Works out the luminescence of a colour and return a contrast colour for the text over it (either light or dark)
         * @param modCols Object with R,G,B decimal values
         * @returns {string} hex code for text colour
         */
        getContrast: function (modCols) {
            // - luminescence of a colour: ((0.299 * R)+(0.587 * G)+(0.114 * B)) / 255
            let luminescence = 0;
            try {
                luminescence = 0.299 * modCols.R;
                luminescence += 0.587 * modCols.G;
                luminescence += 0.114 * modCols.B;
                luminescence /= 255;
            } catch(err) {
                luminescence = 0;
            }
            // - higher luminescence = brighter colour needs dark font colour for contrast and vice-versa
            return luminescence > 0.5 ? "#222" : "#EEE";
        },
        getWeekData: function(wkno) {
            // - takes < 20ms
            let thisWeek = false;
            let self = this;
            let cnt = 0;
            let start = Date.now();
            if (this.myRawData.WeekAndDays?.WeekAndDay !== undefined) {
                thisWeek = this.myRawData.WeekAndDays.WeekAndDay.filter(function (wad)
                {
                    return parseInt(wad.TimetableWeek) === wkno;
                });
                if (thisWeek.length && !thisWeek[0].Activity) {
                    thisWeek.forEach(function (tw)
                    {
                        //console.log("tw: " + ++cnt, tw);
                        if (!tw.Activity) {
                            tw.Activity = {};
                            let filterVal = tw.Activity_Hostkey;
                            let actualDate = tw.ActualDate; // ddth Mmm YYYY - used for display
                            let actualDateMoment = tw.ActualDateMoment;
                            //console.log("ADM 0", actualDateMoment);
                            if (actualDateMoment === undefined) {
                                return false;
                            }
                            //console.log("Acts len: " + self.myRawData.Acts.Act.length);
                            tw.Activity = self.myRawData.Acts.Act.filter(function (act)
                            {
                                if (act.Hostkey === filterVal) {
                                    try {
                                        if (!act.ActivityDate) {
                                            act.ActivityDate = actualDate;
                                            // - 2024-01-31T00:00:00+00:00
                                            //console.log("ADM 1", actualDateMoment);
                                            if (actualDateMoment !== null && actualDateMoment !== undefined && typeof actualDateMoment === "string") {
                                                actualDateMoment = moment(actualDateMoment);
                                            }
                                            act.StartDate = self.addHoursToDate(actualDateMoment.format("YYYY-MM-DDT00:00:00"), act.ScheduledStartTime);
                                            act.StartMoment = moment(act.StartDate); //actualDateMoment;
                                        }
                                    }
                                    catch (e) {
                                        //console.log("ERROR!", actualDateMoment);
                                    }
                                }
                                return act.Hostkey === filterVal;
                            });
                        }
                    });
                } else {
                    /* console.log("Skipping tw.Activity"); */
                }
            } else {
                /* console.log("Week no." + wkno + " wad undefined"); */
            }
            let timeTaken = Date.now() - start;
            /* console.log("getWeekData time: " + timeTaken + "ms"); */
            if (thisWeek) {
                thisWeek.forEach(function(tw) {
                    // - add proper dates in here?
                });
            }
            return thisWeek;
        },
        getTTData: function(wkno) {
            let thisWeek = this.getWeekData(wkno);
            // just need one of the following
                this.timetabledata.thisweek = thisWeek;
                this.weekActivities = thisWeek;
                //return thisWeek;
        },
        /**
         * Time is already 24hr
         * 14:00 PM makes no sense
         * remove the AM/PM portion
         */
        fixExamTime: function() {
            this.examdata.academicPeriod?.exams.forEach(function(exam) {
                let regex = /^[0-9]{2}:[0-9]{2} [A/P]M$/;
                if (exam.time.match(regex)) {
                    exam.time = exam.time.substring(0, 5); // 09:30 AM become 09:30
                }
            });
        },
        fixActivities: function() {
            let self = this;
            let DT;
            this.Activities.forEach(function(act) {
                /** Hours - from fixActHours **/
                //===================================================================================
                if(act.ScheduledStartTime) {
                    try {
                        DT = moment.tz(act.ScheduledStartTime, "Europe/London"); //new Date(act.ScheduledStartTime);
                    } catch (e) {
                       //console.log("ERROR in fixActHours ", e);
                    }
                    act.fixedStartDT = self.addHoursToDate(act.StartDate, act.ScheduledStartTime);
                    act.fixedEndDT = self.addHoursToDate(act.StartDate, act.ScheduledEndTime);
                    //console.log("act.fixedStartDT ", act.fixedStartDT);
                    //console.log("act.fixedEndDT ", act.fixedEndDT);
                    // create a timestamp of start and end time for easier manipulation
                    act.DT = DT;

                    // - set start and end date/times as moments (startMoment endMoment) to make comparison and part creation simpler
                    self.setMoments(act);

                    // - create a more readable/usable start and end time
                    act.StartHour = self.getHourFromDT(DT);
                    act.StartMinute = self.getMinuteFromDT(DT);
                    act.ActivityDate = "";
                    if (act.StartDate) {
                        let startDate = new Date(act.StartDate);
                        let dateNum = startDate.getDate();
                        act.ActivityDate = dateNum + self.dateOrdinal(dateNum) + " " + startDate.toLocaleString('default', {month: 'short'}) + " " + startDate.getFullYear();
                    }
                }
                /** Modules **/
                act.actModule = {};
                let filterVal = act.Hostkey;
                act.actModule = self.myRawData.ActsModules.ActModule.filter(function(anActModule) {
                    return anActModule.Activity_Hostkey === filterVal;
                });

                //===================================================================================
                /** Locations - see fixLocationData below **/
                let myLocation;
                try {
                    let myActLocation = self.myRawData.ActsLocations.ActLocation.filter(function (anActModule)
                    {
                        return anActModule.Activity_Hostkey === filterVal;
                    });

                    myLocation = self.myRawData.Locations.Location.filter(function (aLocation)
                    {
                        return aLocation.Hostkey === myActLocation[0].Location_Hostkey;
                    });
                } catch (e) {
                    myLocation = [{
                        Description: "-",
                        Hostkey: "",
                        Name: "Not Set"
                    }];
                }
                if (myLocation === undefined || myLocation.length === 0) {
                    myLocation = [{
                        Description: "-",
                        Link: "",
                        Hostkey: "",
                        Name: "Not Set"
                    }];
                } else {
                    myLocation[0].Link = self.setLocationLink(myLocation[0]);
                }

                act.Location = myLocation[0];

                //===================================================================================
                let myActStaffMember;
                let myActStaffMembers;
                /** Tutor/Staff Members - see fixActivityTutorData below **/
                try {
                    let myActStaff = self.myRawData.ActsStaff.ActStaff.filter(function (anActStaff)
                    {
                        return anActStaff.Activity_Hostkey === filterVal;
                    });
                    // Staff_Hostkey id n-id of staff member
                    //console.log("myActStaff: " + myActStaff.length, myActStaff);
                    let nids = [];
                    for (let staff in myActStaff) {
                        nids.push(myActStaff[staff].Staff_Hostkey);
                    }
                    myActStaffMembers = self.myRawData.StaffMembers.StaffMember.filter(function (aStaffMember)
                    {
                        return nids.includes(aStaffMember.Hostkey);
                    });
                    myActStaffMember = self.myRawData.StaffMembers.StaffMember.filter(function (aStaffMember)
                    {
                        return aStaffMember.Hostkey === myActStaff[0].Staff_Hostkey;
                    });
                } catch (e) {
                    //Staff_Name: "Not Set"
                    myActStaffMember = [{
                        Hostkey: "-",
                        Staff_Description: "",
                        Staff_Name: ""
                    }];
                }
                act.StaffMember = myActStaffMember[0];
                act.AllStaff = myActStaffMembers;
            });
            this.sortActivities();
        },
        sortActivities: function() {
            let tStart = Date.now();
            //console.log(tStart);
            this.Activities.sort((a, b) => a.fixedStartDT.localeCompare(b.fixedStartDT))
            //console.log("Sort took " + (Date.now() - tStart) + " milliseconds");
        },
        /**
         *
         * @param act - an Activity
         */
        setMoments: function(act) {
            if (act.fixedStartDT) {
                act.startMoment = this.tt1.getMomentObjFromDateString(act.fixedStartDT);
            } else {
                act.startMoment = null;
            }
            if (act.fixedStartDT) {
                act.endMoment = this.tt1.getMomentObjFromDateString(act.fixedEndDT);
            } else {
                act.endMoment = null;
            }
        },
        /**
         * Object is made up of
         *  StartDate: "2023-09-25T00:00:00+01:00"
         *  ScheduledStartTime: "1900-01-01T09:30:00+00:00"
         *  these two need to be joined to have a single string incorporating both date and time
         * @param datePart - the one with the correct date
         * @param timePart - the one with the correct time
         */
        addHoursToDate: function(datePart, timePart) {
            let retval = datePart;
            let date = datePart.split("T");
            let time = timePart.split("T");
            if (date.length && time.length > 1) {
                retval = date[0] + "T" + time[1];
            }
            return retval;
        },
        setLocationLink: function(loc) {
            let link = "";
            if (loc.EXTERNALID) {
                link = "<a href='/CampusMapView" + "?buildingId=" + loc.EXTERNALID + "&type=bld'><i class=\"bi bi-geo-alt-fill\"></i>View on Map</a>";
            }
            return link;
        },
        dateOrdinal: function(dateNum)
        {
            let ordinal;
            switch (dateNum) {
                case 1:
                case 21:
                case 31:
                    ordinal = "st";
                    break;
                case 2:
                case 22:
                    ordinal = "nd";
                    break;
                case 3:
                case 23:
                    ordinal = "rd";
                    break;
                default:
                    ordinal = "th";
            }
            return ordinal;
        },
        getHourFromDT: function(DT) {
            let hours;
            try {
                hours = DT.hours()
            } catch (e) {
                hours = 0;
            }
            return hours;
           //return DT.getHours();
        },
        getMinuteFromDT: function(DT) {
            let mins;
            try {
                mins = DT.minutes()
            } catch (e) {
                mins = 0;
            }
            return mins;
        },
        /**
         *
         * @param modKey : string
         * @returns {{displayColours: {textCol: string, bgCol: string}, Hostkey: string, Description: string, Name: string}}
         */
        getModuleInfo: function(modKey) {
            let retVal;
            let theModule = this.myRawData.Modules.Module.filter(function(aModule) {
                return aModule.Hostkey === modKey;
            });
            if (theModule.length) {
                retVal = theModule[0];
            } else {
                retVal = {
                    Description: "", // - Description Missing
                    Hostkey: "", // - Hostkey Missing
                    Name: "", // - Name Missing
                    displayColours: {
                        bgCol: "#EEE",
                        textCol: "#333",
                    },
                }
            }

            return retVal;
        },
    },
});
