<template>
    <div class="clearfix header-container">
        <div class="float-start">
            <h2>{{ this.$route.meta.title }}</h2>
        </div>
        <div class="float-end">
            <input type="radio" class="btn-check" name="sortby" id="sortname" autocomplete="off" checked v-on:change="toggleListSortField('name', 'asc')">
            <label class="btn btn-primary" for="sortname">Name</label>&nbsp;
            <input type="radio" class="btn-check" name="sortby" id="sortavailability" autocomplete="off" v-on:change="toggleListSortField('availability', 'desc')">
            <label class="btn btn-primary" for="sortavailability">Availability</label>&nbsp;
            <input type="button" class="btn-check" id="btn-check" autocomplete="off" v-on:click="changeClusterSortOrder()">
            <label class="btn btn-primary" for="btn-check">
                <i class="bi bi-arrow-up" v-if="sortOrder == 'asc'"></i>
                <i class="bi bi-arrow-down" v-if="sortOrder == 'desc'"></i>
            </label>
        </div>
    </div>
    <hr style="margin-bottom:0;">

    <!-- TOP LEVEL CLUSTER CONTAINER -->
    <div id="listViewContainer">
        <!-- TOP LEVEL CLUSTER ELEMENT -->
        <div class="accordion" id="listView" v-show="clusters.length">
            <!-- TOP LEVEL ACCORDION ITEMS -->
            <div v-for="(c, i) in clusters" :key="c.id" class="accordion-item">
                <!-- TOP LEVEL CLUSTER ITEM LINK -->
                <ClusterLink
                    :displayName="c.displayname"
                    :statusClass="c.liveStats?.headingStatusClass"
                    :statusText="c.liveStats?.headingTitleText"
                    :id="i"
                />
                <!-- TOP LEVEL CLUSTER BODY -->
                <div
                    :id="`collapse-${i}`"
                    class="accordion-collapse collapse p-1"
                    :aria-labelledby="`heading-${i}`"
                    data-bs-parent="#listView"
                >
                    <div class="accordion-body p-2">
                        <div v-if="c.children.length === 0">
                            <ClusterStats 
                                :openingStatusText="c.liveStats?.statusText"
                                :openingStatus="c.liveStats?.openingStatus"
                                :bookingStatus="c.liveStats?.bookingStatus"
                                :availablePCs="c.liveStats?.availability?.activeClientCount"
                                :buildingId="c.buildingexid"
                                :clusterId="c.id"
                                :showOpeningTimes="true"
                                :showMapLink="true"
                            />
                            <ClusterTimetable
                                :clusterTimetableActivities="getVisibleActivities(c.clusterTimetableActivities)"
                                :currentTimetableWeek="currentTimetableWeek"
                                :currentDayOfWeek="currentDayOfWeek"
                            />
                        </div>
                        <div v-if="c.children.length > 0">
                            <div class="accordion-body p-2">
                                <ClusterStats
                                    :openingStatusText="c.liveStats?.statusText"
                                    :openingStatus="c.liveStats?.openingStatus"
                                    :bookingStatus="c.liveStats?.bookingStatus"
                                    :availablePCs="c.liveStats?.totalAvailable"
                                    :buildingId="c.buildingexid"
                                    :clusterId="c.id"
                                    :showOpeningTimes="false"
                                    :showMapLink="false"
                                />
                                <ClusterTimetable
                                    :clusterTimetableActivities="getVisibleActivities(c.clusterTimetableActivities)"
                                    :currentTimetableWeek="currentTimetableWeek"
                                    :currentDayOfWeek="currentDayOfWeek"
                                />
                            </div>
                            <!-- NESTED CLUSTER CONTAINER -->
                            <div class="accordion accordion-flush" :id="`sub-clusters-${i}`">
                                <div v-for="(s, j) in c.children" :key="s.id" class="accordion-item">
                                    <!-- NESTED CLUSTER ITEM LINK -->
                                    <ClusterLink
                                        :displayName="s.displayname"
                                        :statusClass="s.liveStats?.headingStatusClass"
                                        :statusText="s.liveStats?.headingTitleText"
                                        :id="j"
                                        :prefix="`sub${i}-`"
                                    />
                                    <!-- NESTED CLUSTER BODY -->
                                    <div
                                        :id="`sub${i}-collapse-${j}`"
                                        class="accordion-collapse collapse"
                                        :aria-labelledby="`sub${i}-heading-${j}`"
                                        :data-bs-parent="`#sub-clusters-${i}`"
                                    >
                                        <div class="accordion-body p-2">
                                            <ClusterStats
                                                :openingStatusText="s.liveStats?.statusText"
                                                :openingStatus="s.liveStats?.openingStatus"
                                                :bookingStatus="s.liveStats?.bookingStatus"
                                                :availablePCs="s.liveStats?.availability?.activeClientCount"
                                                :buildingId="s.buildingexid"
                                                :clusterId="s.id"
                                                :showOpeningTimes="true"
                                                :showMapLink="true"
                                                :parentBooked="s.liveStats?.parentBooked"
                                            />
                                            <ClusterTimetable
                                                :clusterTimetableActivities="getVisibleActivities(s.clusterTimetableActivities)"
                                                :currentTimetableWeek="currentTimetableWeek"
                                                :currentDayOfWeek="currentDayOfWeek"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'pinia';
    import { useClusterStore } from '@/stores/cluster.module';
    import { useWeeksOfYearStore } from "@/stores/weeksofyear.module";
    import ClusterLink from '@/components/find-a-pc/ClusterLink.vue';
    import ClusterStats from '@/components/find-a-pc/ClusterStats.vue';
    import ClusterTimetable from '@/components/find-a-pc/ClusterTimetable.vue';
    import moment from 'moment';

    export default {
        name: "FindAPC",
        components: {
            ClusterLink,
            ClusterStats,
            ClusterTimetable,
        },
        async created() {
            await this.getWeeks();
        },
        computed: {
            ...mapState(useClusterStore, ['clusters', 'sortBy', 'sortOrder']),
            ...mapState(useWeeksOfYearStore, ['academicweeks']),
            currentDayOfWeek() {
                return moment().isoWeekday();
            },
            currentTimetableWeek() {
                return this.academicweeks?.thisWeek ? parseInt(this.academicweeks.thisWeek[0].Week) : 0;
            }
        },
        methods: {
            ...mapActions(useClusterStore, ['sortClusterList', 'changeClusterSortOrder']),
            ...mapActions(useWeeksOfYearStore, ['getWeeks']),
            toggleListSortField(sortBy, orderBy) {
                this.sortClusterList(sortBy, orderBy);
            },
            getVisibleActivities(clusterTimetableActivities) {
                var timeFormat = "HH:mm:ss";
                let currentTime = moment(new Date(), timeFormat);

                let visibleActivities = [];
                if (clusterTimetableActivities!==undefined&&clusterTimetableActivities.activityTimetableWeekMap[this.currentTimetableWeek]!==undefined&&clusterTimetableActivities.activityTimetableWeekMap[this.currentTimetableWeek][this.currentDayOfWeek]!==undefined) {
                    for (let activity of clusterTimetableActivities.activityTimetableWeekMap[this.currentTimetableWeek][this.currentDayOfWeek]) {
                        let beforeTime = moment(activity.ScheduledEndTimeObj, timeFormat);
                        if (currentTime.isBefore(moment({
                                hour:beforeTime.hour(),
                                minute:beforeTime.minute(),
                                second:beforeTime.second()
                            }), "HH:mm:ss")) {
                            visibleActivities.push(activity);
                        }
                    }
                }
                return visibleActivities;
            },
            formatDateAsTime(value) {
                return moment(value).format('h:mma');
            },
        }
    };
</script>

<style scoped>
    .header-container {
        padding-top: 15px;
    }
    .view-button {
        width:150px;
        background-color:#6699ff;
    }
    .reset-button {
        width:150px;
        background-color:#c70039;
    }
    .list-icon {
        height: 20px;
        width: 20px;
    }
    .facility-link {
        color: #000000;
        cursor: pointer;
        text-decoration: none;
    }
</style>
