<template>
    <the-alert />
    <find-A-PC />
</template>

<script>
    import { mapState, mapActions } from 'pinia';
    import { useAlertStore } from '@/stores/alert.module';
    import { useClusterStore } from '@/stores/cluster.module';
    import { useTimetableStore } from '@/stores/timetable.module';
    import FindAPC from '@/components/FindAPC.vue';
    import TheAlert from '@/components/TheAlert.vue';

    export default {
        name: 'FindAPCView',
        components: {
            FindAPC,
            TheAlert
        },
        computed: {
            ...mapState(useClusterStore, ['clusters']),
        },
        created() {
            this.initialiseUI();
        },
        methods: {
            ...mapActions(useClusterStore, ['initialise', 'updateClusterOverallStatus', 'updateClusterMachineTotals']),
            ...mapActions(useTimetableStore, ['updateClusterTimetables']),
            ...mapActions(useAlertStore, ['showInformationAlert', 'showErrorAlert']),
            async initialiseUI() {
                let loader = this.$loading.show();
                try {
                    // populate cluster data
                    await this.initialise();
                    // add the cluster timetable data to the clusters
                    await this.updateClusterTimetables(this.clusters);
                    // update status of clusters
                    await this.updateClusterOverallStatus(this.clusters);
                    // update cluster machine totals
                    await this.updateClusterMachineTotals();
                } catch (error) {
                    this.showErrorAlert('Error: Unable to retrieve cluster information');
                }
                loader.hide();
            },
        },
    };
</script>
