// This store is to be used in conjunction with TheAlert component

import { defineStore } from 'pinia';

const INFORMATION = 0;
const WARNING = 1;
const ERROR = 2;

export const useAlertStore = defineStore('alert', {
    state: () => ({
        display: false,
        level: undefined,
        header: undefined,
        messages: []
    }),
    actions: {
        showAlert(level, header, messages) {
            this.level = level;
            this.header = header;
            this.messages = messages ?? [];
            this.display = true;
        },
        showInformationAlert(header, messages) {
            this.showAlert(INFORMATION, header, messages);
        },
        showWarningAlert(header, messages) {
            this.showAlert(WARNING, header, messages);
        },
        showErrorAlert(header, messages) {
            this.showAlert(ERROR, header, messages)
        },
        hideAlert() {
            this.level = undefined;
            this.header = undefined;
            this.messages = [];
            this.display = false;
        }
    },
});
