// This store is to be used in conjunction with the Campus Map component

import apiHandler from '@/shared/apiHandler';
import { defineStore } from 'pinia';
import config from '../../public/applicationsettings.json';

const defaultState = {
    center: { lat: 54.979289, lng: -1.615337 },
    zoom: 17
};

export const useMapStore = defineStore('map', {
    state: () => ({
        isLoading: false,
        center: defaultState.center,
        zoom: defaultState.zoom,
        markers: [],
        activeMarkers: [],
        activeList: [],
    }),
    actions: {
        initialiseList(){
            this.activeMarkers = [...this.markers];
            this.activeList = [...this.markers];
            this.resetMap();
        },
        setMapCenterPoint(lat, lng) {
            let position = { lat: lat, lng: lng };
            let markerObj = {
                id: 'usrloc',
                type: 'usr',
                icon: "img/icons/map-loc-icon.png",
                visible: true,
                clickable: true,
                position: position,
            }
            this.activeMarkers.push(markerObj);
            this.center = position;
            this.zoom = defaultState.zoom;
        },
        searchList(buildingId) {
            this.activeList = [];
            this.markers.map((marker) => {
                if (buildingId == marker.id) {
                    this.activeList.push(marker);
                }
                return marker;
            });
        },
        setActiveMarkers(buildingId, type, facilityId) {
            this.activeMarkers = [];
            let selectedBuilding = this.markers.filter((marker) => {
                return marker.id == buildingId
            });
            let { facilities, ...bld } = selectedBuilding[0];
            this.activeMarkers.push(bld);
            this.center = { lat: bld.position.lat, lng: bld.position.lng };
            this.zoom = 19;
            facilities[type].map((marker) => {
                if (facilityId !== undefined) {
                    if (marker.id == facilityId) {
                        this.activeMarkers.push(marker);
                        this.center = { lat: marker.position.lat, lng: marker.position.lng };
                        this.zoom = 20;
                    }
                } else {
                    this.activeMarkers.push(marker);
                }
            });
        },
        async getBuildings() {
            let buildingMarkersArr = [];
            await apiHandler.apiGet(config.CampusMap.BuildingPropsEP)
            .then((result) => {
                result.data?.locations?.map((building) => {
                    if (building&&building.externalid.substring(0,3)==="BLD"){
                        let buildingName = building.name;
                        if (building.groups) {
                            building.groups.map(item => {
                                if (item.name == 'CentralLocationsFeed') {
                                    let propsObj = item.properties.reduce(
                                        (obj, item) => Object.assign(obj, { [item.key.toLowerCase()]: item.value }), {});
                                    if (propsObj&&propsObj.gpslat!==undefined&&propsObj.gpslng!==undefined){
                                        let markerObj = {
                                            id: propsObj.externalid,
                                            type: "bld",
                                            visible: true,
                                            clickable: true,
                                            icon: "img/icons/bld-map-icon.png",
                                            position: {
                                                lat: parseFloat(propsObj.gpslat), lng: parseFloat(propsObj.gpslng)
                                            },
                                            name: buildingName //propsObj.shortenedname ? String(propsObj.shortenedname) : String(buildingName)
                                        }
                                        buildingMarkersArr.push(markerObj);
                                    }
                                }
                            });
                        }
                    }
                });
            }).catch((error) => {
                throw error;
            });
            buildingMarkersArr.sort((a, b) => a.name.localeCompare(b.name));
            this.markers = buildingMarkersArr;
        },
        async getFacilities() {
            // Fetch facilities data
            let facilitiesResponse = [];
            await apiHandler.apiGet(config.CampusMap.LocationPropsEP)
            .then((result) => {
                facilitiesResponse = result.data?.collections[0]?.objects;
            }).catch((error) => {
                throw error;
            });
            // Loop through buildings and append facilities data
            this.markers.map((building) => {
                let facilityMarkersObj = { acc: [], clu: [], ent: [], fod: [], mcw: [], nrs: [], pry: [], shr: [] };
                let facilitiesForBuilding = facilitiesResponse.filter(obj => {
                    let propsObj = obj.properties.reduce(
                        (obj, item) => Object.assign(obj, { [item.key.toLowerCase()]: item.value }), {});
                    return building.id == propsObj.buildingexid;
                });

                facilitiesForBuilding.map(obj => {
                    let facilityType = String(obj.type).toLowerCase();
                    let propsObj = obj.properties.reduce(
                        (obj, item) => Object.assign(obj, { [item.key.toLowerCase()]: item.value }), {});
                    let markerObj = {
                        id: obj.externalId,
                        type: facilityType,
                        visible: true,
                        clickable: true,
                        position: {
                            lat: parseFloat(propsObj.latitude), lng: parseFloat(propsObj.longitude)
                        },
                    }
                    if (propsObj.name) { markerObj.name = propsObj.name; }
                    if (propsObj.accessible!==undefined) { markerObj.accessible = String(propsObj.accessible).toLowerCase() === 'true' ? true : false; }
                    if (facilityType == 'ent' && markerObj.accessible) {
                        markerObj.icon = "img/icons/acc-map-icon.png";
                    } else {
                        markerObj.icon = `img/icons/${facilityType}-map-icon.png`;
                    }
                    let { latitude, longitude, name, accessible, ...typeSpecificData } = propsObj;
                    let markerObjMerged = {
                        ...markerObj,
                        ...typeSpecificData
                    };
                    if (facilityMarkersObj[facilityType]) {
                        facilityMarkersObj[facilityType].push(markerObjMerged);
                        if (facilityType == 'ent' && markerObjMerged.accessible == true) {
                            facilityMarkersObj['acc'].push(markerObjMerged);
                        }
                    }
                });
                building.facilities = facilityMarkersObj;
                return building;
            });
        },
        resetMap() {
            let restoredState = { ...this, ...defaultState };
            Object.assign(this, restoredState);
        }
    },
});
