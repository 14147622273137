<template>
    <div>
        <table class="table table-bordered">
            <caption></caption>
            <thead>
                <tr>
                    <th scope="col" v-for="(header, index) in tableHeaders" :key="index">{{header.text}}</th>
                    <th scope="col">Edit</th>
                    <th scope="col">Delete</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(dataRow, index) in tableData" :key="index">
                    <td v-for="(header, index) in tableHeaders" :key="index">{{ dataRow[header.id] }}</td>
                    <td>
                        <router-link class="btn btn-primary" name="edit" :to="{ name: this.view, params: { id: dataRow[dataKey] }}">{{ $strings('application.editButton') }}</router-link>
                    </td>
                    <td><button name="delete" @click.prevent="deleteRow(dataRow[dataKey])">delete</button></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        name: 'TheTable',
        props: {
            tableData: Array,
            tableHeaders: Array,
            dataKey: String,
            endpoint: String,
            view: String,
        },
        methods: {
            deleteRow(id) {
                //console.log(id);
                //store delete
            },
        }
    };
</script>

<style scoped>
</style>

