export function stripTags(html) {
    let doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
}


/**
 * strip '@newcastle.ac.uk' if exists from userId
 * @param userId
 */
export function formatUserId(userId) {
    let retVal = "";
    if (typeof userId === 'string' || userId instanceof String) {
        let unameParts = userId.split("@");
        retVal = unameParts[0];
    }
    return retVal;
}
