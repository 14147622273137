<template>
    <div>
        <div class="header-container">
            <h2>{{ this.$route.meta.title }}</h2>
        </div>
        
        <div>
            <p>
                This privacy policy will explain how Newcastle University uses the personal data we collect from you when you use
                our website.</p>
            <p>Topics:</p>

            <ul>
                <li>What data do we collect?</li>
                <li>How do we collect your data?</li>
                <li>How will we use your data?</li>
                <li>How do we store your data?</li>
                <li>Marketing</li>
                <li>What are your data protection rights?</li>
                <li>What are cookies?</li>
                <li>How do we use cookies?</li>
                <li>What types of cookies do we use?</li>
                <li>How to manage your cookies</li>
                <li>Privacy policies of other websites</li>
                <li>Changes to our privacy policy</li>
                <li>How to contact us</li>
                <li>How to contact the appropriate authorities</li>
            </ul>

            <h4>What data do we collect?</h4>
            <p>Newcastle University collects the following data:</p>
            <ul>
                <li>Personal identification information (Name, email address, phone number, etc.)</li>
            </ul>

            <h4>How do we collect your data?</h4>
            <p>You directly provide Newcastle University with most of the data we collect. We collect data and process data when you:</p>
            <ul>
                <li>Register online or place an order for any of our products or services.</li>
                <li>Voluntarily complete a customer survey or provide feedback on any of our message boards or via email.</li>
                <li>Use or view our website via your browser's cookies.</li>
            </ul>

            <h4>How will we use your data?</h4>
            <p>Newcastle University collects your data so that we can:</p>
            <ul>
                <li>Process and manage your account.</li>
            </ul>
            
            <h4>How do we store your data?</h4>
            <p>Newcastle University securely stores your data at  <strong>[enter the location and describe security precautions taken].</strong></p>
            <p>Newcastle University will keep your <strong>[enter type of data] for [enter time period]</strong>. Once this time period has expired, we
                will delete your data by <strong>[enter how you delete users' data].</strong></p>
            
            <h4>What are your data protection rights?</h4>
            <p>Newcastle University would like to make sure you are fully aware of all of your data protection rights. Every user is
                entitled to the following:</p>
            <p><strong>The right to access</strong> – You have the right to request Newcastle University for copies of your personal
                data. We may charge you a small fee for this service.</p>
            <p><strong>The right to rectification</strong> – You have the right to request that Newcastle University correct any
                information you believe is inaccurate. You also have the right to request Newcastle University to complete the
                information you believe is incomplete.</p>
            <p><strong>The right to erasure</strong> – You have the right to request that Newcastle University erase your personal data,
                under certain conditions.</p>
            <p><strong>The right to restrict processing</strong> – You have the right to request that Newcastle University restrict the
                processing of your personal data, under certain conditions.</p>
            <p><strong>The right to object to processing</strong> – You have the right to object to Newcastle University's processing of
                your personal data, under certain conditions.</p>
            <p><strong>The right to data portability</strong> – You have the right to request that Newcastle University transfer the data
                that we have collected to another organization, or directly to you, under certain conditions.</p>

            <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights,
                please contact us at our email:</p>
            <p>Call us at:</p>
            <p>Or write to us:</p>
            <h4>Cookies</h4>
            <p>Cookies are text files placed on your computer to collect standard Internet log information and visitor behavior
                information. When you visit our websites, we may collect information from you automatically through cookies or
                similar technology</p>
            <p>For further information, visit <a href="https://allaboutcookies.org/" target="_blank">allaboutcookies.org</a>.</p>

            <h4>How do we use cookies?</h4>
            <p>Newcastle University uses cookies in a range of ways to improve your experience on our website, including:</p>
            <ul>
                <li>Keeping you signed in</li>
                <li>Understanding how you use our website</li>
            </ul>

            <h4>What types of cookies do we use?</h4>
            <p>There are a number of different types of cookies, however, our website uses:</p>
            <ul>
                <li>Functionality – Newcastle University uses these cookies so that we recognise you on our website and remember your
                    previously selected preferences. These could include what language you prefer and location you are in. A mix
                    of first-party and third-party cookies are used.</li>
            </ul>

            <h4>How to manage cookies</h4>
            <p>You can set your browser not to accept cookies, and the above website tells you how to remove cookies from your
                browser. However, in a few cases, some of our website features may not function as a result.</p>

            <h4>Privacy policies of other websites</h4>
            <p>The Newcastle University website contains links to other websites. Our privacy policy applies only to our website, so if
                you click on a link to another website, you should read their privacy policy.</p>

            <h4>Changes to our privacy policy</h4>
            <p>Newcastle University keeps its privacy policy under regular review and places any updates on this web page. This privacy
                policy was last updated on <strong>1st January 2023</strong></p>

            <h4>How to contact us</h4>
            <p>If you have any questions about Newcastle University's privacy policy, the data we hold on you, or you would like to
                exercise one of your data protection rights, please do not hesitate to contact us.</p>
            <p>Email us at: <a href="mailto:blank@newcastle.ac.uk?subject=Privacy policy enquiry">blank@newcastle.ac.uk</a></p>
            <p>Call us: (+44)191 111 1111</p>
            <p>Or write to us at: <strong>Newcastle University, NE1 7RU, United Kingdom</strong></p>

            <h4>How to contact the appropriate authority</h4>
            <p>Should you wish to report a complaint or if you feel that Newcastle University has not addressed your concern in a
                satisfactory manner, you may contact the Information Commissioner's Office.</p>
            <p>Email: <a href="mailto:blank@newcastle.ac.uk?subject=Privacy policy enquiry">blank@newcastle.ac.uk</a></p>
            <p>Address: <strong>Newcastle University, NE1 7RU, United Kingdom</strong></p>
        </div>

    </div>
</template>

<script>

export default {
  name: 'PrivacyView',
}
</script>
