<template>
    <div class="header-container">
        <h2>{{ this.$route.meta.title }}</h2>
    </div>
    <the-alert />
    <div v-if="hasErrorResponse()">
        <div id="data404">
            <div>No timetable data exists for this account yet.</div>
            <ul>
                <li>You must have registered to view student timetables.</li>
                <li>Please allow up to 24 hours for timetables to show.</li>
                <li>(First year student timetables for some subject areas may remain incomplete until welcome week).</li>
                <li>Check your timetable regularly as it can be subject to change up until the start of term.</li>
                <li>For more information look at <a href="https://www.ncl.ac.uk/timetable/">Timetable information</a>.</li>
            </ul>
        </div>
    </div>
    <div v-else>
        <div v-for="err in this.getTimetableErrors" :key=err>
            <span>{{err}}</span>
        </div>
        <div v-if="getErrors.length || getError404" >
            We got errors {{getErrors.length}} error4040={{error404}}
            {{isError404()}}
            <ul>
                <li v-for="error in getErrors" v-bind:key="error">{{error}}</li>
            </ul>
        </div>
        <div v-else>
            <exam-timetables v-if="showExams"></exam-timetables>
            <div v-if="showModules">
                <my-modules />
            </div>
            <section v-if="showNotes" id="timetable-notes">
                <div>
                    <div>Student timetable info and sources</div>
                    <ul>
                        <li>Get week numbers for year </li>
                        <li>Get student's modules from https://m.ncl.ac.uk/itservice/timetableCaches/studentTimetableCache/xxxxxxxxx</li>
                        <li>Student ID is currently hard-coded (otherwise script would use your user number to retrieve a student's timetable
                            <ul>
                                <li>xxxxxxxxx is the student number ie 220678199 200367048(graduated 23/24) / </li>
                                <li>Valid numbers can be gleaned from https://timetableservices.ncl.ac.uk/Scientia/XmlReportEngineLive1/Default.aspx?AllStudents-JSON</li>
                            </ul>
                        </li>
                    </ul>
                    <div>Timetable Options</div>
                    <ul>
                        <li>Filter week (up to 4 in advance?). Week No. plus Start Date</li>
                        <li>Filter on module. Only show info for that module</li>
                    </ul>
                </div>
            </section>
            <section id="timetable-cards" class="ttable-width">
                <content-card />
            </section>

            <section id="timetabledata-layout">
                <timetable-data />
            </section>

            <section v-if="showiCal" id="icaldownload">
                <i-cal-downloader></i-cal-downloader>
            </section>
        </div>

    </div>

</template>

<script>

/*
import { useAlertStore } from '@/stores/alert.module';
import { useCardStore } from '@/stores/card.module';

import ContentCard from '@/components/ContentCard.vue';
*/
import TheAlert from '@/components/TheAlert.vue';
import ExamTimetables from '@/components/timetables/ExamTimetable.vue';
import TimetableData from '@/components/timetables/Timetables.vue';
import MyModules from '@/components/timetables/Modules.vue';
import ICalDownloader from "@/components/timetables/ICalDownloader.vue";
import { mapState, mapActions } from 'pinia';
import {useAlertStore} from "@/stores/alert.module";
import { useMyTimetableStore } from '@/stores/timetables.module';
import ContentCard from "@/components/ContentCard.vue";
//import {useWeeksOfYearStore} from "@/stores/weeksofyear.module";

export default {
    name: 'TimetableView',
    components: {
        ContentCard,
        TheAlert,
        TimetableData,
        MyModules,
        ExamTimetables,
        ICalDownloader,
    },
    created() {
        this.initialiseTT();
        this.error404 = false;
    },
    computed: {
        ...mapState(useMyTimetableStore, ['mytimetablestore', "ttErrors", "ErrorResponse", "timetabledata"]),
        showExams() {
            return false;
        },
        showModules() {
            return false;
        },
        showNotes() {
            return false;
        },
        showiCal() {
            return false;
        },
        getError404() {
            return this.error404;
        }
    },
    methods: {
        ...mapActions(useAlertStore, ['showInformationAlert', 'showErrorAlert']),
        ...mapActions(useMyTimetableStore, ['getTimetable', 'getTimetableErrors']),
        //...mapActions(useWeeksOfYearStore, ['getWeeks']), // function from store
        getErrorCount() {
            //console.log("Errors: ", this.ttErrors);
            return this.ttErrors.length > 0;
        },
        getErrors() {
            //console.log("\\\\\\ ** TT Errors ", this.ttErrors);
            return this.ttErrors;
        },
        hasErrorResponse() {
            //console.log("Error: " + this.ErrorResponse);
            return this.ErrorResponse !== 0;
        },
        isError404() {
            console.log("is 404: " + typeof this.ErrorResponse);
            return this.ErrorResponse === 404;
        },
        getErrorResponse() {
            return this.ErrorResponse;
        },
        async initialiseTT() {
            //this.isInitialisingUI = true;
            let loader = this.$loading.show();
            /** teaching timetables **/
            try {
                await this.getTimetable(1);
            } catch (error) {
                //console.log("Caught Error: ", error);
                if (error?.response?.status === "404") {
                    this.error404 = true;
                }
                //console.log("TTerr:", error);
                //this.showErrorAlert('No timetable data found.');
            }
            /*
            try {
                /!** exams - no longer wanted **!/
                await this.getTimetable(2);
                } catch (error) {
                this.showErrorAlert('Error: Unable to retrieve exam timetable (from view)');
            } */
            //this.isInitialisingUI = false;
            loader.hide();
        },
    },
};
</script>

<style scoped>
#timetable-notes {
    display: none;
}
#timetable-cards {
    margin-top: 2em;
}
</style>
