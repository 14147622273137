<template>
    <the-alert />
    <div class="mb-4 image-selector">
        <div>
            <button @click="chooseImage" id="btn-image-select">
                Select Image
            </button>
            <input class="file-input" ref="fileInput" type="file" accept="image/jpg, image/jpeg, image/jfif, image/png, image/bmp, image/webp, image/gif" @input="onSelectFile" />
        </div>

        <div id="pic-controls" class="pic-container">
            <button ref="movePicControlN" data-nudgens="10" data-nudgeew="0" id="arrow-north" class="bi-chevron-compact-up chevrons chevron-ns chevron-n"></button>
            <div id="pic-middle" class="pic-container-middle">
                <button ref="movePicControlW" data-nudgens="0" data-nudgeew="10" id="arrow-west" class="bi-chevron-compact-left chevrons chevron-ew chevron-w"></button>

                <div id="selected-img-disp" ref="imageFrame" class="base-image-input" @click="chooseImage">
                    <div v-if="!imageData" class="placeholder">
                        <div v-if="userCanUpload">
                            <div style="margin-bottom:1em;">Tap to select an image</div>
                            <div>Files Accepted:</div>
                            <div>.jpg .bmp .png .gif .jfif .webp</div>
                        </div>
                        <div v-else-if="photoEligible">
                            <div style="padding: 0 2em; text-align:center;">Sorry, once your Newcastle University ID Card has been printed, you cannot upload an ID picture through this interface.</div>
                        </div>
                        <div v-else>
                            <div style="padding: 0 2em; text-align:center;">You must be logged in to a valid Newcastle University account to upload an ID picture here</div>
                        </div>
                    </div>
                    <img id="source-image" ref="sourceImage" v-if="imageData" :src="imageData" draggable="false" alt="uploaded image space"/>
                </div>

                <button ref="movePicControlE" data-nudgens="0" data-nudgeew="-10" id="arrow-east" class="bi-chevron-compact-right chevrons chevron-ew chevron-e"></button>
            </div>
            <button ref="movePicControlS" data-nudgens="-10" data-nudgeew="0" id="arrow-south" class="bi-chevron-compact-down chevrons chevron-ns chevron-s"></button>

            <div v-if="userCanUpload" class="controls" id="image-actions">
                <div id="zoom-control">
                    <i class="bi-zoom-out maglass" @click="zoomOut"></i>
                    <label for="imageZoom" class="form-label">Zoom in/out</label>
                    <i class="bi-zoom-in maglass" @click="zoomIn"></i>
                </div>
                <input
                    type="range"
                    @change="readZoom"
                    class="form-range"
                    min="100"
                    max="300"
                    id="imageZoom"
                    ref="imageZoom"
                    value="100"
                >
                <span v-if="showExtras" id="ex6CurrentSliderValLabel">Zoom: <span id="ex6SliderVal" v-if="ZoomVal">{{ZoomVal}}%</span></span>
            </div>
            <div v-if="userCanUpload"><button id="btn-clone" class="btn btn-primary mb-2" @click="doUpload">Upload</button></div>

            <div v-if="userCanUpload"><button id="btn-cancel-upload" class="btn btn-primary btn-cancel mb-2" @click="cancelUpload">Cancel Upload</button></div>
            <div v-else><button id="btn-cancel-upload" class="btn btn-primary btn-cancel mb-2" @click="cancelUpload">Return to 'My Picture'</button></div>
        </div>
        <div class="base-image-output">
            <canvas
                ref="finalDisplay"
                id="cropped-img-disp"
                class="final-display"
            >
            </canvas>
        </div>
        <canvas ref="vCanvas" id="v-canvas"></canvas>
    </div>
</template>


<script>
import {mapActions, mapState} from "pinia";
import {useAlertStore} from "@/stores/alert.module";
import {useIdCardInfoStore} from "@/stores/idcardinfo.module";
import {useAuthenticationStore} from "@/stores/authentication.module";
import TheAlert from "@/components/TheAlert.vue";

export default {
    name: "SelectPhoto",
    components: {TheAlert},
    data () {
        return {
            imageData: null,
            //croppedImageData: null,
            ZoomVal: null,
            nudgeVal: 10,
            isUploading: false,
        }
    },
    mounted() {
        this.loadedImage = null;
        this.isGrabbing = false;
        this.imageIsLoaded = false;
        this.initialMouseX = this.initialMouseY = this.cropBoxRect = null;
        this.relMouseX = this.relMouseY = null;
        this.$refs.movePicControlN.addEventListener('mousedown', this.triggerNudgePic);
        this.$refs.movePicControlN.addEventListener('mouseup', this.stopNudge);
        this.$refs.movePicControlW.addEventListener('mousedown', this.triggerNudgePic);
        this.$refs.movePicControlW.addEventListener('mouseup', this.stopNudge);
        this.$refs.movePicControlS.addEventListener('mousedown', this.triggerNudgePic);
        this.$refs.movePicControlS.addEventListener('mouseup', this.stopNudge);
        this.$refs.movePicControlE.addEventListener('mousedown', this.triggerNudgePic);
        this.$refs.movePicControlE.addEventListener('mouseup', this.stopNudge);
        this.nudging = null;
        this.imgw = 300;
        this.imgh = 400;
        this.imageRatio = 0;
        // timer vals
        this.timeStart = 0;
        this.timeStop = 0;
        this.visualise = false;
    },
    created() {
        this.initialiseUI();
        window.addEventListener('mouseup', this.mouseUp);
        window.addEventListener('touchend', this.mouseUp);
    },
    computed: {
        ...mapState(useIdCardInfoStore,['idCardPhoto', 'idCardRecord']),
        ...mapState(useAuthenticationStore, ['isLoggedIn', 'isAdmin', 'isStaff', 'isStudent', 'getUsername']),
        showExtras() {
            return false;
        },
        idCardInfo() {
            return this.idCardRecord ? this.idCardRecord : false;
        },
        userHasBarcode() {
            console.log("barcode ££££££££ " + this.idCardInfo.barcode);
            return !this.isAdmin && this.idCardInfo?.barcode !== false;
        },
        photoEligible() {
            return this.idCardInfo?.photoEligible
        },
        userCanUpload() {
            //let uname = this.getUsername;
            return this.isAdmin || (this.idCardInfo?.photoEligible); // && uname && this.idCardInfo?.idNumber);
        }
    },
    methods: {
        ...mapActions(useAlertStore, ['showInformationAlert', 'showErrorAlert', 'hideAlert']),
        ...mapActions(useIdCardInfoStore,['uploadPic', 'getIdCardRecord']),
        triggerNudgePic(e) {
            if (!this.nudging) {
                this.setNudge(e);
                this.nudging = setInterval(this.setNudge, 50, e);
            }
        },
        stopNudge() {
            clearInterval(this.nudging);
            this.nudging = null;
        },
        setNudge(e) {
            if (this.imageIsLoaded && this.$refs.sourceImage) {
                this.$refs.sourceImage.top += (e.target.getAttribute('data-nudgens') | 0);
                this.$refs.sourceImage.left += (e.target.getAttribute('data-nudgeew') | 0);

                this.nudgePic();
            }

        },
        nudgePic() {
            let bottom = this.$refs.sourceImage.height + this.$refs.sourceImage.top;
            let right = this.$refs.sourceImage.width + this.$refs.sourceImage.left;

            if (this.$refs.sourceImage.top > 0) {
                this.$refs.sourceImage.top = 0;
            }
            if (bottom < this.imgh) {
                this.$refs.sourceImage.top = this.imgh - this.$refs.sourceImage.height;
            }

            if (this.$refs.sourceImage.left > 0) {
                this.$refs.sourceImage.left = 0;
            } else if (right < this.imgw) {
                this.$refs.sourceImage.left = this.imgw - this.$refs.sourceImage.width;
            }

            this.$refs.sourceImage.style.top = this.$refs.sourceImage.top + "px";
            this.$refs.sourceImage.style.left = this.$refs.sourceImage.left + "px";
        },
        chooseImage () {
            if (this.userCanUpload) {
                this.hideAlert();
                this.$refs.fileInput.click();
            }
        },
        async onSelectFile () {
            //this.startTimer();
            const input = this.$refs.fileInput;
            const files = input.files;
            if (files && files[0]) {
                const file = files[0];
                if (this.isValidImage(files[0])) {
                    const reader = new FileReader;
                    reader.onload = e =>
                    {
                        // imageData is the blob of the image!!
                        this.imageData = e.target.result;
                        this.imageIsLoaded = true;
                        this.getImageDimensions(file);
                    }
                    reader.readAsDataURL(files[0]);
                    this.$emit('input', files[0]);
                } else {
                    this.showErrorAlert("Error: Invalid filetype. ("  + files[0].type + ")");
                }
            } else {
                this.showErrorAlert("Error: File select error.");
            }

        },
        isValidImage(file) {
            let isValid = false;
            if(file.type) {
                // accept .jpg .bmp .png .gif .jfif .webp
                switch (file.type) {
                    case "image/jpeg":
                    case "image/jpg":
                    case "image/bmp":
                    case "image/png":
                    case "image/gif":
                    case "image/jfif":
                    case "image/webp":
                        isValid = true;
                }
                return isValid;
            }
        },
        // https://stackoverflow.com/questions/71696179/how-can-i-get-width-and-height-from-an-image-before-upload-in-vuejs
        async getImageDimensions(file) {
            this.startTimer();
            this.loadedImage = new Image();
            const photoUrl = URL.createObjectURL(file);
            const imageDimensions = await new Promise((resolve) => {
                this.loadedImage.onload = () => {
                    const dimensions = {
                        width: this.loadedImage.width,
                        height: this.loadedImage.height,
                    };
                    resolve(dimensions);
                    this.imageRatio = dimensions.width / dimensions.height;
                    /**
                     * set defaults on canvas for freshly loaded image
                     * @type {number}
                     */
                    this.$refs.imageZoom.min = "100";
                    this.$refs.imageZoom.value = "100";
                    this.doZoom(this.$refs.imageZoom.value);
                    this.$refs.sourceImage.left = 0;
                    this.$refs.sourceImage.top = 0;
                    this.$refs.sourceImage.style.left = "0";
                    this.$refs.sourceImage.style.top = "0";
                    if (this.$refs.sourceImage.height < this.imgh) {
                        // - set the image so it is at least as high as the container (this.imghpx)
                        let percentage = Math.ceil((this.imgh / this.$refs.sourceImage.height) * 100);
                        this.$refs.imageZoom.value = percentage.toString(); // + 1;

                        this.$refs.sourceImage.height = this.$refs.sourceImage.width / this.imageRatio;

                        this.doZoom(this.$refs.imageZoom.value);
                        // - sometimes the image height still comes out 'close' to 400, but not quite. Cludge to fix this.
                        if (this.$refs.sourceImage.height === 399) {
                            this.$refs.sourceImage.height = 400;
                        }

                        this.$refs.imageZoom.min = percentage.toString();
                        this.$refs.imageZoom.max = (percentage * 3).toString();
                    } else {
                        //this.$refs.sourceImage.style.width = "500px";
                    }
                };
                this.loadedImage.src = photoUrl;
            });
            let timeTaken = this.stopTimer();

        },
        zoomIn() {
            if (this.$refs.sourceImage) {
                let zoomVal = parseInt(this.$refs.imageZoom.value);
                let newZoom = Math.min(zoomVal + 10, parseInt(this.$refs.imageZoom.max));
                if (zoomVal < this.$refs.imageZoom.max) {
                    this.doZoom(newZoom);
                    this.$refs.imageZoom.value = newZoom.toString();
                }
            }
        },
        zoomOut() {
            if (this.$refs.sourceImage) {
                let zoomVal = parseInt(this.$refs.imageZoom.value);
                let newZoom = Math.max(zoomVal - 10, parseInt(this.$refs.imageZoom.min));
                if (zoomVal > this.$refs.imageZoom.min) {
                    this.doZoom(newZoom);
                    this.$refs.imageZoom.value = newZoom.toString();
                }
            }
        },
        readZoom(e) {
            if (this.$refs.sourceImage) {
                this.doZoom(e.target.value);
            }
        },
        doZoom(val) {
            this.$refs.sourceImage.style.width = 3 * val + "px";
            this.$refs.sourceImage.width = 3 * val;
            this.$refs.sourceImage.height = this.$refs.sourceImage.width / this.imageRatio;
            // - if pic has been zoomed out after being moved, it can be offset/offscreen, move it back with the following
            this.nudgePic();
            this.ZoomVal = val | 0;
        },
        initialiseXY() {
            this.initialMouseX = this.initialMouseY = this.cropBoxRect = null;
            this.relMouseX = this.relMouseY = null;
        },
        cloneImage() {
            //https://levelup.gitconnected.com/how-to-preview-images-before-uploading-them-in-vue-4964803adb64
            if (this.$refs.sourceImage) {

                let ratioW, ratioH;
                ratioW = 3;
                ratioH = 3;

                /** Create a virtual canvas to copy the source image at the current zoom level. Use this to get a crop from **/
                const vCanvas = document.getElementById("v-canvas");
                const vctx = vCanvas.getContext("2d");

                // set width and height to that of the source image
                this.$refs.vCanvas.width = this.$refs.sourceImage.width * ratioW;
                this.$refs.vCanvas.height = this.$refs.sourceImage.height * ratioH;

                // copy the source image to the virtual canvas. Use this for taking a crop.
                vctx.drawImage(this.loadedImage, 0, 0, this.loadedImage.width, this.loadedImage.height, 0, 0, this.$refs.vCanvas.width, this.$refs.vCanvas.height);


                this.$refs.finalDisplay.width = this.imgw * ratioW;
                this.$refs.finalDisplay.height = this.imgh * ratioH;

                const destImage = this.$refs.finalDisplay;
                let sx, sy, sw, sh, dx, dy, dw, dh;
                const im = this.$refs.vCanvas;
                sx = Math.abs(this.$refs.sourceImage.left) * ratioW; // - the x offset from scrolling
                sy = Math.abs(this.$refs.sourceImage.top) * ratioH; // - the y offset from scrolling
                sw = this.imgw * ratioW;
                sh = this.imgh * ratioH;
                dx = 0;
                dy = 0;
                dw = this.imgw * ratioW;
                dh = this.imgh * ratioH;
                // - draw the selected are on to the canvas ready for upload
                destImage.getContext('2d').drawImage(im, sx, sy, sw, sh, dx, dy, dw, dh);
                /* this.uploadImage(); */
            }
        },
        async uploadImage() {
            // - most from
            //  https://medium.com/@this.onenterframe/capture-and-upload-dynamic-page-thumbnails-with-vue-js-and-html2canvas-502d5fa0c74b
            let canvas = this.$refs.finalDisplay;
            /**
             * Convert the canvas to a data URL
             * this will result in a string "data:image/jpeg;base64,[base64data]
             * @type {string}
             */
            const imageDataUrl = canvas.toDataURL('image/jpeg', 0.8);
            // - strip off the non-base64 bit
            const imageDataUrl64 = imageDataUrl.replace('data:image/jpeg;base64,', '');

            let userToGet = this.getUsername;
            try {
                await this.uploadPic(imageDataUrl64, userToGet);
                //redirect her to MyPhoto
                this.$router.push("/MyPhoto");

            }  catch (error) {
                this.IDCardDataError = true;
                this.showErrorAlert('Error: Something went wrong in upload.');
                //console.log("Upload error: ", error);
            }

        },
        doUpload() {
            if (this.loadedImage) {
                this.isUploading = true;
                this.cloneImage();
                this.uploadImage();
                this.isUploading = false;
            }
        },
        cancelUpload() {
            this.$router.push("/MyPhoto");
        },
        startTimer() {
            this.timeStart = Date.now();
        },
        getInterimTimer() {
            return Date.now() - this.timeStart;
        },
        stopTimer() {
            this.timeStop = Date.now();
            let timeTaken = this.timeStop - this.timeStart
            this.timeStop = this.timeStart = 0;
            return timeTaken;
        },
        async initialiseUI() {
            let loader = this.$loading.show();
            this.initialisingUI = true;

            // Digital Id data
            try {
                let userToGet = this.getUsername;
                //console.log("JEANTEST USERNAME: " + userToGet);
                await this.getIdCardRecord(userToGet);
            } catch (error) {
                this.IDCardDataError = true;
                this.showErrorAlert('Error: Unable to retrieve your ID information');
                //console.log("Unable to retrieve your ID information - error", error);
            }
            //console.log("Got record: ", this.idCardInfo);
            this.initialisingUI = false;
            loader.hide();
        },
    },
}
</script>


<style scoped>
    #btn-image-select {
        display: none;
    }
    .base-image-input {
        border: solid 1px #666;
        display: block;
        width: 300px;
        height: 400px;
        background-size: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        overflow: hidden;
        position: relative;
    }
    .base-image-output {
        border: solid 1px #666;
        /*display: block;*/
        width: 900px;
        height: 1200px;
        overflow: hidden;
        position: relative;
        display: none;
    }
    .final-display {
        width: 100%;
        height: 100%;
    }
    .placeholder {
        background: #F0F0F0;
        background-position: 10px 10px;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: #333;
        font-size: 18px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
    }
    #source-image {
        width: 300px;
        display: inline-block;
        position: relative;
    }
    .file-input {
        display: none;
    }
    #image-actions {
        width: 300px;
    }
    #btn-clone {
        width: 300px;
        text-align: center;
    }
    .pic-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 360px;
        margin: 0 auto;
    }
    .pic-container-middle {
        display: flex;
        flex-direction: row;
    }

    #v-canvas,
    #cropped-img-disp {
        display: none;
    }

    /* zoom cotrols */
    #zoom-control {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 1em;
    }
    .maglass {
        padding-bottom: 0;
        font-size: 1.3em;
        cursor: pointer;
    }

    /* 'scroll' buttons */
    .chevrons {
        font-size: 2em;
        padding: 0;
        background-color: #FFF;
        text-align: center;
    }
    .chevrons:active {
        background-color: #EEE;
    }
    .chevron-ns {
        width: 300px;
    }
    .chevron-n {
        border-radius: 15px 15px 0 0;
    }
    .chevron-s {
        border-radius: 0 0 15px 15px;
    }
    .chevron-ew {
        height: 400px;
    }
    .chevron-w {
        border-radius: 15px 0 0 15px;
    }
    .chevron-e {
        border-radius: 0 15px 15px 0;
    }

</style>
