<template>
    <div>
        <h3>{{ $strings('settings.heading') }}</h3>
    </div>
    <div>
        {{ $strings('settings.subHeading') }}
    </div>
    <the-alert />
    <div class="container">
        <div class="row">
            <div class="col-lg-4 my-1 px-1 py-1">
                <div class="carddesign card h-100">
                    <div class="card-header">
                        <h5>{{ $strings('settings.newsChannelSubscriptions') }}</h5>
                    </div>
                    <div class="card-body">
                        <div class="form-check form-switch" v-for="channel in channels" :key="channel.category">
                            <input class="form-check-input" type="checkbox" :id="channel.category" :value="channel.category" v-model="userSettingsStore.myNewsChannels" @change="updateFeeds($event)">
                            <label class="form-check-label" :for="channel.category">{{ channel.title }}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 my-1 px-1 py-1">
                <div class="carddesign card h-100">
                    <div class="card-header">
                        <h5>{{ $strings('settings.homeScreenTiles') }}</h5>
                    </div>
                    <div class="card-body">
                        <div class="form-check form-switch" v-for="tile in homeScreenTiles" :key="tile.name">
                            <input class="form-check-input" type="checkbox" :id="tile.name" :value="tile" v-model="userSettingsStore.myHomeScreenTiles" @change="sortTiles($event)">
                            <label class="form-check-label" :for="tile.name">{{ $strings('main.tiles.'+tile.name.replace('Tile', '')+'.title') }}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import { useAuthenticationStore } from '@/stores/authentication.module';
    import { useArticleStore } from '@/stores/article.module';
    import { useUserSettingsStore } from '@/stores/usersettings.module';
    import { getHomeTiles } from '@/helpers/usersettings.helper';
    import TheAlert from '@/components/TheAlert.vue';

    export default {
        name: 'UserSettingsView',
        components: {
            TheAlert
        },
        data() {
            return {
                homeScreenTiles: [],
                userSettingsStore: useUserSettingsStore()
            };
        },
        computed: {
            ...mapState(useArticleStore, ['channels']),
            ...mapState(useAuthenticationStore, ['isStaff', 'isStudent']),
            ...mapState(useUserSettingsStore, ['myHomeScreenTiles'])
        },
        created() {
            this.initialiseUI();
        },
        methods: {
            ...mapActions(useArticleStore, ['getChannels']),
            ...mapActions(useUserSettingsStore, ['setMyHomeScreenTiles']),
            async initialiseUI() {
                let loader = this.$loading.show();
                try {
                    const userType = this.isStaff ? 'staff' : this.isStudent ? 'student' : 'all';
                    if (this.myHomeScreenTiles === undefined) {
                        this.setMyHomeScreenTiles(await getHomeTiles(userType));
                    }
                    this.homeScreenTiles = await getHomeTiles(userType);
                    await this.getChannels(false);
                } catch (error) {
                    this.showErrorAlert('Error: Unable to load settings page');
                }
                loader.hide();
            },
            async updateFeeds(e) {
                let loader = this.$loading.show();
                try {
                    await this.getChannels(true, this.userSettingsStore.myNewsChannels);
                } catch (error) {
                    this.showErrorAlert('Error: Unable to update news feeds');
                }
                loader.hide();
            },
            async sortTiles(e) {
                this.myHomeScreenTiles = this.myHomeScreenTiles.sort((a, b) => a.name.localeCompare(b.name));
            }
        }
    }
</script>
