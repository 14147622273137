<!--
    Displays a still image taken from the camera (video) and
    displays the cropping frame
-->
<template>
    <div id="snap-container" class="picture-display" v-on:mouseup="mouseReleased">
        <PictureCropper></PictureCropper>
        <canvas id="snap-canvas" v-on:mousemove="moveMouse" v-on:dragover="dragOver" v-on:drop="dragDrop">
        </canvas>
        <CropControls v-on:hideSnap="$emit('hideSnap')" v-on:cropIt="cropSnap"></CropControls>
    </div>
    <!-- Do we just put this in here, or build a separate component? -->
    <div id="final-image">
        <canvas id="croppedSnap"></canvas>
        <CropControlsUpload v-on:uploadCrop="$emit('uploadCrop')" v-on:reCrop="$emit('reCrop')"></CropControlsUpload>
    </div>
</template>


<script>
// <CropControls v-on:hideSnap="$emit('hideSnap')"></CropControls>  v-on:hideSnapClicked="emitHideSnap"
import CropControls from "@/components/IdCardPic/CropControls.vue";
import PictureCropper from "@/components/IdCardPic/PictureCropper2.vue";
import CropControlsUpload from "@/components/IdCardPic/CropControlsUpload.vue";

export default {
    components: {CropControls, CropControlsUpload, PictureCropper},
    name: "PictureDisplay2",
    data() {},
    emits: 'hideSnap',
    created() {
        this.init();
    },
    mounted() {
        this.picDisplay = document.getElementById('snap-container');
        this.theCropper = document.getElementById("the-cropper");
    },
    methods: {
        cropSnap: function() {
            // copy region within the-cropper to croppedSnap
            // - hide snap-canvas and display croppedSnap
            if (this.theCropper) {
                let frameX = this.theCropper.offsetLeft;
                let frameY = this.theCropper.offsetTop;
                let frameW = this.theCropper.offsetWidth;
                let frameH = this.theCropper.offsetHeight;

                let srcImage = document.getElementById('snap-canvas');
                let croppedSnap = document.getElementById("croppedSnap");
                let ctxCrop = croppedSnap.getContext("2d");
                ctxCrop.imageSmoothingEnabled = true;
                ctxCrop.imageSmoothingQuality = "high";
                croppedSnap.width = frameW;
                croppedSnap.height = frameH;
                croppedSnap.style.display = 'block';
                ctxCrop.drawImage(srcImage, frameX, frameY, frameW, frameH, 0, 0, frameW, frameH);
                this.$emit('cropIt');
            }
        },
        showSnap: function(show = true) {
            //let srcImage = document.getElementById('snap-canvas');
            let snapContainer = document.getElementById('snap-container');
            if (show) {
                snapContainer.style.display = 'block';
                //this.theCropper.style.display = 'block';
            } else {
                snapContainer.style.display = 'none';
            }
        },
        resizeSnapContainer(width, height) {
            this.picDisplay.style.width = width + 'px';
        },
        emitHideSnap: function () {
            this.$emit('hideSnap');
        },
        init: function() {
            //console.log("PictureDisplayer2 init");
        }
    },
}
</script>


<style scoped>
    #snap-container {
        display: none;
        position: relative;
    }
    #snap-canvas {

    }
    #croppedSnap, #final-image {
        width: 300px;
        height: 400px;
        margin-bottom: 1em;
    }
    #final-image {
        display: none;
        border: solid 1px #333;
    }
</style>
