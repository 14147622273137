<template>
    <div class="clearfix header-container">
        <div class="float-start">
            <div>
                <h3>{{ $strings('directory.heading') }}</h3>
            </div>
            <div>
                {{ $strings('directory.subHeading') }}
            </div>
        </div>
    </div>
    <the-alert />
    <hr>
    <section id="search" class="welcome_area p_120">
        <div class="container">
            <div class="row">
                <input name="searchbox" type="search" class="form-control ds-input user-search-input" v-model="query" placeholder="Enter search query ..." v-debounce:300ms="searchDirectory" />
            </div>
        </div>
    </section>
    <section id="content" class="welcome_area p_120">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 my-1 px-1 py-1" v-for="item in paginated" :key="item.email">
                    <StaffCard
                        :name="`${item.title} ${item.knownAs} ${item.surname}`"
                        :department="item.department"
                        :position="item.position"
                        :email="item.email"
                        :telephone="item.telephone"
                        :restricted="item.restricted"
                    />
                </div>
            </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="my-5 px-1 py-1 d-flex justify-content-center" align="center">
                <pagination v-model="page" :records="people.length" :per-page="itemsCountPerPage" :options="{chunk:5,chunksNavigation:scroll}" v-if="people.length > itemsCountPerPage" />
                <p v-html="statusText"></p>
            </div>
          </div>
        </div>
    </section>
</template>

<script>
    import { mapActions } from 'pinia';
    import { useAlertStore } from '@/stores/alert.module';
    import TheAlert from '@/components/TheAlert.vue';
    import StaffCard from '@/components/directory/StaffCard.vue';
    import Pagination from 'v-pagination-3';
    import apiHandler from '@/shared/apiHandler';
    import config from '../../public/applicationsettings.json';
    import vueDebounce from 'vue-debounce';

    export default {
        name: 'DirectoryView',
        components: {
            StaffCard,
            Pagination,
            TheAlert
        },
        directives: {
            debounce: vueDebounce({ lock: true })
        },
        data() {
            return {
                query: "",
                statusText: "",
                people: [],
                page: 1
            };
        },
        created() {},
        computed: {
            buttonClasses() {
                return "btn btn-sm btn-block btn-secondary";
            },
            itemsCountPerPage() {
                return 9;
            },
            indexStart() {
                return (this.page - 1) * this.itemsCountPerPage;
            },
            indexEnd() {
                return this.indexStart + this.itemsCountPerPage;
            },
            paginated() {
                return this.people.slice(this.indexStart, this.indexEnd);
            }
        },
        methods: {
            ...mapActions(useAlertStore, ['showInformationAlert', 'showErrorAlert']),
            async searchDirectory(name) {
                let loader = this.$loading.show();
                await apiHandler.apiGet(`${config.Directory.ProxyURL}?name=${name}`)
                .then((result) => {
                    if (result.data?.people?.length) {
                        this.page = 1;
                        this.people = result.data?.people;
                        this.statusText = "";
                    } else {
                        this.page = 1;
                        this.people = [];
                        if (this.query.length > 0) {
                            this.statusText = "No results found";
                        }
                    }
                }).catch((e) => {
                    this.showErrorAlert('Error: Unable to retrieve results. Please try again later');
                });
                loader.hide();
            },
        },
        watch: {
            query: {
                handler: function(val) {
                    if (val.length === 0) {
                        this.page = 1;
                        this.people = [];
                        this.statusText = "";
                    }
                },
                immediate: false
            }
        }
    };
</script>

<style scoped>
.user-search-input {
    margin-bottom: 1rem;
}
</style>