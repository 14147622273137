<template>
    <section id="personal_tutor" class="welcome_area p_120">
        <hr>
        <section id="personaltutors">
            <div v-if="isInitialisingUI">Initialising</div>
            <div v-else-if="isPersonalTutorDataGood" id="tutors-container" class="container" v-for="(tutor, index) in personalTutorInfo" :key="index">
                <div id="tutorName">
                    <span class="ptdata" v-html="'Tutor: ' + `${tutor.tutorTitle}` + ' ' + `${tutor.tutorKnownAs}` + ' ' + `${tutor.tutorLastName}`"></span>
                </div>
                <div id="tutorEmail">
                    <span class="ptdata">
                        <a class="pthyperlink" v-bind:href="'mailto:' + tutor.tutorEmailAddress" v-html="'Email: ' + `${tutor.tutorEmailAddress}`"></a>
                    </span>
                </div>
                <div id="tutorPhone">
                    <span class="ptdata">
                        <a class="pthyperlink" v-bind:href="'tel:' + tutor.tutorTelephoneNumber.split(' ').join('')" v-html="'Telephone: ' + `${tutor.tutorTelephoneNumber}`"></a>
                    </span>
                </div>
                <br />
            </div>
            <div v-else class="personal-tutor-error">Sorry, we could not retrieve your Personal Tutor information.</div>
        </section>
    </section>
</template>

<script>
    import { mapState, mapActions } from 'pinia';
    import {usePersonalTutorStore} from '@/stores/personaltutors.module';
    import {useAlertStore} from "@/stores/alert.module";
    import {useAuthenticationStore} from "@/stores/authentication.module";
    export default {
        components: {
        },
        data(){
            return {
                PersonalTutorDataError: false,
            }
        },
        name: "PersonalTutor",
        created() {
            this.initialiseUI();
            this.PersonalTutorDataError = false;
        },
        computed: {
            ...mapState(usePersonalTutorStore,['getPersonalTutors', 'personaltutors']),
            ...mapState(useAuthenticationStore, ['isAdmin', 'getUsername']),
            isPersonalTutorDataGood() {
                return this.PersonalTutorDataError == false;
            },
            isInitialisingUI: function() {
                return this.isInitialisingUI;
            },
            personalTutorInfo() {
                return this.personaltutors?.tutors ? this.personaltutors.tutors : false;
            },
        },
        methods: {
            //...mapActions(usePersonalTutorStore,['getPersonalTutors']),
            ...mapActions(useAlertStore, ['showInformationAlert', 'showErrorAlert', 'hideAlert']),
            async initialiseUI() {
                this.initialisingUI = true;

                // Personal Tutor data
                try {
                    let userToGet = this.getUsername;
                    await this.getPersonalTutors(userToGet);
                } catch (error) {
                    this.PersonalTutorDataError = true;
                    //Took out the alert. Not having a personal tutor is actually a valid outcome.
                }

                this.initialisingUI = false;
            },
        }
    };
</script>

<style scoped>
    .header-container {
        padding-top: 15px;
    }
    .ptdata {
        font-weight: bold;
    }
    .pthyperlink{
        text-decoration: none;
    }
    #tutorName {
        padding: 30px 0;
    }
    #tutorEmail {
        padding: 30px 0;
    }
    #tutorPhone {
        padding: 30px 0;
    }
</style>
