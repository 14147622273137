<template>
    <div class="carddesign card h-100">
        <div class="card-header" v-html="$strings('main.tiles.PrintBalance.title')"></div>
        <div class="card-body">
            <p class="card-text" v-html="$strings('main.tiles.PrintBalance.description')"></p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PrintBalanceTile',
        methods: {}
    };
</script>

<style scoped>
</style>
