<template>
    <div class="header-container">
        <h2>{{ this.$route.meta.title }}</h2>
    </div>
    <div>
        This page is restricted to admins only.
        <form v-if="adminData">
            <div class="form-group">
                <label for="name">build run id</label>
                <input type="text" id="name" class="form-control" disabled v-model="adminData.runId" />
            </div>
            <div class="form-group">
                <label for="name">build run name</label>
                <input type="text" id="name" class="form-control" disabled v-model="adminData.runName" />
            </div>
            <div class="form-group">
                <label for="name">source commit</label>
                <input type="text" id="name" class="form-control" disabled v-model="adminData.sourceCommit" />
            </div>
            <div class="form-group">
                <label for="name">Database connection string</label>
                <input type="text" id="name" class="form-control" disabled v-model="adminData.connectionString" />
            </div>
            <div class="form-group">
                <label for="name">Environment</label>
                <input type="text" id="name" class="form-control" disabled v-model="adminData.environment" />
            </div>
            <div class="form-group">
                <label for="name">access token</label>
                <input type="text" id="name" class="form-control" disabled v-model="adminData.accessToken" />
            </div>
            <div class="form-group">
                <label>Claims</label>
            </div>
            <div class="form-group" v-for="(claim, element) in adminData.claims" v-bind:key="claim.type">
                <label v-bind:for="'claim-'+element" class="col-form-label">{{ claim.type }}</label>
                <input type="text" v-bind:id="'claim-'+element" class="form-control" disabled v-model="claim.value" />
            </div>
        </form>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'pinia';
    import { useAdminTestStore } from '@/stores/admintest.module';

    export default {
        name: 'AdminTestView',
        data() {
            return {
                isInitialisingUI: false
            };
        },
        computed: {
            ...mapState(useAdminTestStore, ['adminData'])
        },
        created() {
            this.initialiseUI();
        },
        methods: {
            ...mapActions(useAdminTestStore, ['getAdminSettings']),
            async initialiseUI() {
                this.isInitialisingUI = true;
                try {
                    await this.getAdminSettings();
                } catch (error) {
                    //nothing
                }
                this.isInitialisingUI = false;
            },
        }
    }
</script>
