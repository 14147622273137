<template>
    <div v-if="display" :class="alertClasses">
        <button type="button" class="btn-close" v-on:click="hideAlert"></button>
        <h6>{{header}}</h6>
        <ul v-if="messages.length > 0">
            <li v-for="msg in messages" v-bind:key="msg">{{msg}}</li>
        </ul>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'pinia';
    import { useAlertStore } from '@/stores/alert.module';

    export default {
        name: "TheAlert",
        computed: {
            ...mapState(useAlertStore, ['display', 'header', 'messages', 'level']),
            alertClasses() {
                const fixedClasses = "alert alert-dismissable fade show";
                const alertClass = ["alert-success", "alert-warning", "alert-danger"][this.level];
                return fixedClasses + " " + alertClass;
            },
        },
        methods: {
            ...mapActions(useAlertStore, ['showInformationAlert', 'showErrorAlert', 'hideAlert']),
        }
    };
</script>

<style scoped>
    .btn-close {
        float:right;
    }
</style>
