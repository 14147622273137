<template>
    <select :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" required aria-required="true" class="form-control form-select">
        <option value="" disabled selected>{{$strings('application.selectOptionPlaceholder')}}</option>                   
        <option v-for="(listItem) in listData" :key="listItem.dropdownListItemId" :value="listItem.dropdownListItemId">
            {{ listItem.dropdownListItemText }}
        </option>
    </select>    
</template>

<script>
    export default {
        name: 'DropdownList',
        props: {
            modelValue: Number,
            listData: Array,
        }
    };
</script>
