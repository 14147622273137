<template>
    <section id="loans" class="welcome_area p_120">
        <hr>
        <div class="container">
          <div class="row">
            <div>
                <table class="table table-bordered">
                    <caption>
                        <h6><strong>Total number of loaned books: </strong></h6> 
                        <h6><span>{{numberOfLoans}}</span></h6>
                    </caption>
                    <thead>
                        <tr>
                            <th scope="col">Barcode</th>
                            <th scope="col">Title</th>
                            <th scope="col">Author</th>
                            <th scope="col">Location</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="loan in loansForPage" :key="loan.barcode">
                            <td>{{ loan.barcode }}</td>
                            <td>{{ loan['item_title'] }}</td>
                            <td>{{ loan.author }}</td>
                            <td>{{ loan.location }}</td>

                            <!--td>{{ loan.barcode }}</td>
                            <td>{{ loan['item-title'].$cdata_section }}</td>
                            <td>{{ loan['author'].$cdata_section}}</td>
                            <td>{{ loan.location }}</td-->
                        </tr>
                    </tbody>
                </table>
            </div>
          </div>

          <div class="row">
            <div>
                <table class="table table-bordered">
                    <caption>
                        <h6><strong>Total number of reserved (hold) books: </strong></h6> 
                        <h6><span>{{numberOfHolds}}</span></h6>
                    </caption>
                    <thead>
                        <tr>
                            <th scope="col">Title</th>
                            <th scope="col">Author</th>
                            <th scope="col">Location</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="hold in holdsForPage" :key="hold.barcode">
                            <td>{{ hold.title }}</td>
                            <td>{{ hold.author }}</td>
                            <td>{{ hold['pickup_location'] }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
          </div>

          <div class="row">
            <div>
                <table class="table table-bordered">
                    <caption>
                        <h6><strong>Cash Balance: </strong></h6> 
                        <h6><span>{{cashBalance}}</span></h6>
                    </caption>
                </table>
            </div>
          </div>

        </div>
    </section>

</template>

<script>
    import { mapState, mapActions } from 'pinia';
    import { useLibraryStore } from '@/stores/library.module';

    export default {
        name: "LibraryLoan",
        computed: {
            ...mapState(useLibraryStore, ['loans']),
            
            loansForPage() {
                //console.log ("this.loans.items are");
                //console.log (this.loans.items);
                return this.loans.items; 
            },

            numberOfLoans() {
                //console.log ("this.loans.noOfItems are");
                //console.log (this.loans.noOfItems);
                return this.loans.noOfItems;
            },

            holdsForPage() {
                return this.loans.holditems;  
            },

            numberOfHolds() {
                return this.loans.noOfHoldItems;
            },

            cashBalance() {
                return this.loans.cashbalance;
            },
        },
        methods: {
            ...mapActions(useLibraryStore, ['toggleVisibility']),
        }
    };
</script>

<style scoped>
    .btn-close {
        float:right;
    }
</style>
