// This store is to be used in conjunction with the Timetable and Find-a-PC components

import apiHandler from '@/shared/apiHandler';
import {defineStore, mapActions} from 'pinia';
import config from '../../public/applicationsettings.json';
import moment from 'moment';
import {useAuthenticationStore} from "@/stores/authentication.module";
import {useApplicationSettingsStore} from "@/stores/applicationsettings.module";

const defaultState = {};

export const useTimetableStore = defineStore('timetable', {
    state: () => ({
        clustersTimetable: [],
        timetableWeekMap: { byWeek:{}, byWeekNo:{}, byWeekStart:{}, byYearWeek:{} },
    }),
    actions: {

        ...mapActions(useAuthenticationStore, ['buildAuthHeaders']),

        async mapLocationsActivitiesToWeeksAndDays(locs){
            if(locs){
                let locslen = locs.length;
                for(;locslen--;){
                    let loc = locs[locslen];
                    let activityTimetableWeekMap = loc.activityTimetableWeekMap;
                    if(activityTimetableWeekMap===undefined){loc.activityTimetableWeekMap = activityTimetableWeekMap = {};}
                    let locActs = loc.activities;
                    if(locActs){
                        let actlen = locActs.length;
                        for(let acti=0;acti<actlen;acti++){/*must be iterated forward to not lose time (by start time) ordering of activities*/
                            let locAct = locActs[acti];
                            if(!Array.isArray(locAct.weekAndDays)){
                                locAct.weekAndDays = new Array(locAct.weekAndDays);     
                            }     
                            let wads = locAct.weekAndDays;
                            if(wads){
                                let wadslen = wads.length;
                                for(;wadslen--;){ /*don't think the ordering of parsing weeks and days matters*/
                                    if(wads[wadslen].timetableWeekInt){
                                        let wad = wads[wadslen];
                                        let dayMap = activityTimetableWeekMap[wad.timetableWeekInt];
                                        if(dayMap===undefined){activityTimetableWeekMap[wad.timetableWeekInt] = dayMap = {};}
                                        let actArray = dayMap[wad.isoDayInWeekInt];
                                        if(actArray===undefined){dayMap[wad.isoDayInWeekInt] = actArray = [];} /* 1 - monday -> 7 sunday*/
                                        actArray.push(locAct);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        mapWeekAndDays(mapFrom,associativeArrayFromKey,mappingArray,associativePropertyName){
            for(let fromKey in mapFrom){
            let associativeArray = [];
            let fromObj = mapFrom[fromKey]; /*activity*/
            let len = mappingArray.length;
            for(let i=0;i<len;i++){
                let association = mappingArray[i];  /*WeekAndDay*/
                if(association[associativeArrayFromKey]&&association[associativeArrayFromKey]===fromKey){ /*WeekAndDay["Activity_Hostkey"] === "#SPLUS...."*/
                    try{association.isoDayInWeekInt = parseInt(association.DayInWeek)+1;}catch(err){association.isoDayInWeekInt = -1;}
                    try{association.timetableWeekInt = parseInt(association.TimetableWeek);}catch(err){association.timetableWeekInt = -1;}
                    try{association.yearWeekInt = parseInt(association.YearWeek);}catch(err){association.yearWeekInt = -1;}
                    let ttWeek = this.timetableWeekMap.byWeek[association.timetableWeekInt];
                    if(ttWeek){association.timetableWeek = ttWeek;}
                    associativeArray.push(association);
                }
            }
            fromObj[associativePropertyName] = associativeArray; /*activity.weekAndDays*/
            }
        },        
        async mapTimetableWeeks(){
            let ttw = this.timetableWeeks;
            if(ttw&&ttw.Data&&ttw.Data.weeks){
                let weeks = ttw.Data.weeks;
                if(weeks.week&&Array.isArray(weeks.week)){
                    if(!Array.isArray(weeks.week)){
                    weeks.week = new Array(weeks.week);     
                    }
                    let wks = weeks.week;
                    let wkslen = wks.length;
                    for(;wkslen--;){
                        let wk = wks[wkslen];
                        try{wk.weekInt = parseInt(wk.Week)}catch(err){console.log(err)}
                        try{wk.weekNoInt = parseInt(wk.WeekNo)}catch(err){console.log(err)}
                        try{wk.yearWeekInt = parseInt(wk.YearWeek)}catch(err){console.log(err)}
                        wk.weekStartObject = this.getMomentObjFromDateString(wk.WeekStart);
                        wk.weekEndObject = this.getMomentObjFromDateString(wk.WeekEnd);
                        wk.weekStartObject.month();/*month the week starts*/
                        wk.weekEndObject.month();/*month the week ends*/
                        /* 1 - tuesday -> 6 - sunday */
                    }
                    let weeksMap = {};
                    let weekNoMap = {};
                    let weekStartMap = {};
                    let yearWeekMap = {};
                    this.mapObjects(wks,weeksMap,"Week");
                    this.mapObjects(wks,weekNoMap,"WeekNo");
                    this.mapObjects(wks,weekStartMap,"WeekStart");
                    this.mapObjects(wks,yearWeekMap,"YearWeek");
    /*                    this.timetableWeekMap.byMonth= monthMap;*/
                    this.timetableWeekMap.byWeek = weeksMap;
                    this.timetableWeekMap.byWeekNo = weekNoMap;
                    this.timetableWeekMap.byWeekStart = weekStartMap;
                    this.timetableWeekMap.byYearWeek = yearWeekMap;
                }else{
                    throw new Error("Timetable weeks data does not have a 'week' array");
                }
            }else{
                throw new Error("Timetable weeks data does not have 'Data' property");
            }
        },
        async mapActivitiesToWeeksAndDays(tt){
            if(tt&&tt.Acts){
                let activityTimetableWeekMap = tt.activityTimetableWeekMap;
                if(activityTimetableWeekMap===undefined){tt.activityTimetableWeekMap = activityTimetableWeekMap = {};}
                if(!Array.isArray(tt.Acts.Act)){tt.Acts.Act = new Array(tt.Acts.Act);}
                let acts = tt.Acts.Act;
                if(acts){
                    let actlen = acts.length;
                    for(let acti=0;acti<actlen;acti++){/*must be iterated forward to not lose time (by start time) ordering of activities*/
                        let act = acts[acti];
                        if(!Array.isArray(act.weekAndDays)){act.weekAndDays = new Array(act.weekAndDays);}
                        let wads = act.weekAndDays;
                        if(wads){
                            let wadslen = wads.length;
                            for(;wadslen--;){ /*don't think the ordering of parsing weeks and days matters*/
                                if(wads[wadslen].timetableWeekInt){
                                    let wad = wads[wadslen];
                                    let dayMap = activityTimetableWeekMap[wad.timetableWeekInt];
                                    if(dayMap===undefined){activityTimetableWeekMap[wad.timetableWeekInt] = dayMap = {};}
                                    let actArray = dayMap[wad.isoDayInWeekInt];
                                    if(actArray===undefined){dayMap[wad.isoDayInWeekInt] = actArray = [];} /* 1 - monday -> 7 sunday*/
                                    actArray.push(act);
                                }
                            }
                        }
                    }
                }
            }
        },
        async updateClusterTimetables(clusters){

            let token = await apiHandler.getSsoToken();
            let subscriptionKey = useApplicationSettingsStore().idfsSubscriptionKey;
            const authHeaders = this.buildAuthHeaders(token, subscriptionKey);

            // - set url, this changes in azure deploy depending on whether dev, or live/QA
            let endpointURL = config.Endpoint.Talend.Url + useApplicationSettingsStore().endpointTalendPath;
            let ClusterTimetablesEP = endpointURL + config.FindAPC.ClusterTimetablesEP;

            await apiHandler.apiGet(ClusterTimetablesEP, authHeaders)
            .then((result) => {
                this.clustersTimetable = result.data;
            }).catch((error) => {
                throw error;
            });
            await this.mapTimetable(this.clustersTimetable, false, true);
            await this.updateClusterTimetableAvailabilitiesAndEnumerations(clusters);
        },
        async mapTimetable(tt,mapActToWeeks = false,mapActLocationToWeeks = false){
            if(tt&&tt.Data){
                if(tt.Data.ActsModules&&tt.Data.ActsModules.ActModule!=null&&!Array.isArray(tt.Data.ActsModules.ActModule)){
                    tt.Data.ActsModules.ActModule = new Array(tt.Data.ActsModules.ActModule);     
                }
                if(tt.Data.ActsLocations&&tt.Data.ActsLocations.ActLocation!=null&&!Array.isArray(tt.Data.ActsLocations.ActLocation)){
                    tt.Data.ActsLocations.ActLocation = new Array(tt.Data.ActsLocations.ActLocation);     
                }
                if(tt.Data.ActsStaff&&tt.Data.ActsStaff.ActStaff!=null&&!Array.isArray(tt.Data.ActsStaff.ActStaff)){
                   tt.Data.ActsStaff.ActStaff = new Array(tt.Data.ActsStaff.ActStaff);     
                }
    
                let data = tt.Data;
                if(data.Caches&&data.Caches.Cache&&data.Caches.Cache.DateTime){
                    let m = moment(data.Caches.Cache.DateTime);
                    data.Caches.Cache.moment = m;
                    data.Caches.Cache.momentFormatted = m.format("HH:mm DD/MM/YYYY");
                }
                if(data.Acts&&data.Acts.Act!=null){
                    if(!Array.isArray(data.Acts.Act)){
                        data.Acts.Act = new Array(data.Acts.Act);     
                    }
                    let actsMap = {};
                    /*console.log(data.Acts.Act);*/
                     if(!Array.isArray(data.Acts.Act)){
                     data.Acts.Act = new Array(data.Acts.Act);     
                    }
                    let acts = data.Acts.Act;
                    /*console.log(acts);*/
                    data.ActsMap = actsMap;
                    this.mapObjects(acts,actsMap,"Hostkey");
                    let alen = acts.length;
                    for(let ai=alen;ai--;){
                        let act = acts[ai];
                        act.ActIndex = ai;
                        try{act.durationInMinutes = parseInt(act.Duration)*30;}catch(err){console.log(err)}
                        act.ScheduledEndTimeObj = this.getMomentObjFromDateString(act.ScheduledEndTime);
                        //act.ScheduledEndTimeObj = act.ScheduledEndTimeObj.add(4, 'hours');
                        act.ScheduledEndTimeFormatted = act.ScheduledEndTimeObj.format("h:mma");
                        act.ScheduledStartTimeObj = this.getMomentObjFromDateString(act.ScheduledStartTime);
                        //act.ScheduledStartTimeObj = act.ScheduledStartTimeObj.add(4, 'hours');
                        act.ScheduledStartTimeFormatted = act.ScheduledStartTimeObj.format("h:mma");
                        if (isNaN(act.durationInMinutes)){act.durationInMinutes = act.ScheduledEndTimeObj.diff(act.ScheduledStartTimeObj,'minutes');/*(act.ScheduledEndTimeObj.subtract(act.ScheduledStartTimeObj)).minutes()/1000/60;*/}
                        act.durationInHours = parseFloat((act.durationInMinutes / 60).toFixed(1));
                        act.durationInHoursFormatted = "(" + act.durationInHours + (act.durationInHours>1?" hours":" hour") + ")";
                        /*let actType = ActivityDesignate.translate(act.ActivityType_Name);*/
                        act.ActivityType_NameFormatted = /*actType===""?actType:"("+actType+")";*/this.act_translate(act.ActivityType_Name);
                        act.descriptionFormatted = this.formatDescription(act.ActivityType_NameFormatted,act.Description,act.Name);
                        act.descriptionExtraFormatted = this.formatExtraDescription(act);
                        act.descriptionExtraOptionalFormatted = this.formatExtraOptionalDescription(act);
                    }
                    if(data.Locations&&data.Locations.Location!=null){
                        if(!Array.isArray(data.Locations.Location)){
                            data.Locations.Location = new Array(data.Locations.Location);     
                        }
                        let locationsMap = {};
                        data.LocationsMap = locationsMap;
                        /*Should I add checks for data. ... is array here?*/
                        this.mapObjects(data.Locations.Location,locationsMap,"Hostkey");
                        this.mapTwoObjects(actsMap,"Activity_Hostkey",locationsMap,"Location_Hostkey",data.ActsLocations.ActLocation,"locations");
                        this.mapTwoObjects(locationsMap,"Location_Hostkey",actsMap,"Activity_Hostkey",data.ActsLocations.ActLocation,"activities");
                        for(let ai=alen;ai--;){
                            let act = acts[ai];
                            act.descriptionExtraFormatted += this.formatLocations(act.locations);
                        }
                    }
                    if(data.Modules&&data.Modules.Module!=null){
                        if(!Array.isArray(data.Modules.Module)){
                            data.Modules.Module = new Array(data.Modules.Module);     
                        }
                        let modulesMap = {};
                        data.ModulesMap = modulesMap;
                        this.mapObjects(data.Modules.Module,modulesMap,"Hostkey");
                        this.mapTwoObjects(actsMap,"Activity_Hostkey",modulesMap,"Module_Hostkey",data.ActsModules.ActModule,"modules");
                    }
                    if(data.StaffMembers&&data.StaffMembers.StaffMember!=null){
                        if(!Array.isArray(data.StaffMembers.StaffMember)){
                            data.StaffMembers.StaffMember = new Array(data.StaffMembers.StaffMember);     
                        }
                        let staffMap = {};
                        data.StaffMap = staffMap;
                        this.mapObjects(data.StaffMembers.StaffMember,staffMap,"Hostkey");
                        this.mapTwoObjects(actsMap,"Activity_Hostkey",staffMap,"Staff_Hostkey",data.ActsStaff.ActStaff,"staff");
                    }
                    if(data.WeekAndDays&&data.WeekAndDays.WeekAndDay!=null){
                        if(!Array.isArray(data.WeekAndDays.WeekAndDay)){
                            data.WeekAndDays.WeekAndDay = new Array(data.WeekAndDays.WeekAndDay);     
                        }
                    this.mapWeekAndDays(actsMap,"Activity_Hostkey",data.WeekAndDays.WeekAndDay,"weekAndDays");}
                    if(mapActToWeeks||(mapActLocationToWeeks&&data.Locations&&data.Locations.Location)){
                        let optionalProms = [];
                        if(mapActToWeeks){optionalProms.push(this.mapActivitiesToWeeksAndDays(data));}
                        if(mapActLocationToWeeks&&data.Locations&&data.Locations.Location){
                            if(!Array.isArray(data.Locations.Location)){
                            data.Locations.Location = new Array(data.Locations.Location);     
                            }
                        optionalProms.push(this.mapLocationsActivitiesToWeeksAndDays(data.Locations.Location));}
                        try{
                            await Promise.all(optionalProms);
                        }catch(e){
                            throw new Error("Something went wrong whilst mapping acts or location acts");
                        }
                    }
                }else{
                    throw new Error("Activities not defined (probably blank timetable)");
                }
            }else{
                throw new Error("No data in timetable EP response (probably blank timetable)");
            }
        },
        async flat(array) {
            let result = [];
            array.forEach(function (a) {
                result.push(a);
                if (a.children !== undefined && a.children.length > 0) {
                    result = result.concat(a.children);
                }
            });
            return result;
        },
        async updateClusterTimetableAvailabilitiesAndEnumerations(clusters){
            if(!Array.isArray(this.clustersTimetable.Data.Locations.Location)){this.clustersTimetable.Data.Locations.Location = new Array(this.clustersTimetable.Data.Locations.Location);}
            let locs = this.clustersTimetable.Data.Locations.Location;
            let clulen = locs.length;
            for(;clulen--;){
                let loc = locs[clulen];
                let flatClusters = await this.flat(clusters);
                let clo = flatClusters.find(x => x.scientiahostkey == loc.Hostkey); //clusters[loc.Hostkey];
                if(clo){
                    let clu = clo;//.instance;
                    if(clu){
                        clu.clusterTimetableActivities = loc;
                        if(!Array.isArray(loc.activities)){loc.activities = new Array(loc.activities);}
                        let acts = loc.activities;
                        let now = moment();
                        let dayInWeek = now.isoWeekday();
                        let yearWeek = now.week();
                        let actlen = acts.length;
                        /*Begin as not booked until you can find otherwise*/
                        clu.liveStats.bookingStatus = config.FindAPC.BookingStatus.NOT_BOOKED;
                        for(let acti=0;acti<actlen;acti++){
                            let act = acts[acti];
                            if (act === undefined) continue;
                            if(!Array.isArray(act.weekAndDays)){act.weekAndDays = new Array(act.weekAndDays);}
                            let wads = act.weekAndDays;
                            let wlen = wads.length;
                            for(let wi=0;wi<wlen;wi++){
                                let wad = wads[wi];
                                if (wad.isoDayInWeekInt===dayInWeek&&parseInt(wad.YearWeek)===yearWeek){
                                    let year = now.year();
                                    let month = now.month();
                                    let date = now.date();
                                    let startDateTime = act.ScheduledStartTimeObj.clone().year(year).month(month).date(date);
                                    let almostStartDateTime = startDateTime.clone();
                                    almostStartDateTime.subtract(config.FindAPC.TimeBufferMins,"minutes");
                                    let endDateTime = act.ScheduledEndTimeObj.clone().year(year).month(month).date(date);
                                    if(now>=almostStartDateTime&&now<startDateTime){
                                        clu.liveStats.bookingStatus = config.FindAPC.BookingStatus.ABOUT_TO_BE_BOOKED;
                                        acti=actlen; /*stop iterating activities*/
    /*                                        break; /*stop iterating occurances*/
                                    }else if(now>=startDateTime){
                                        if(startDateTime>endDateTime){endDateTime.add(1,'days')}
                                        if(now<endDateTime){
                                            clu.liveStats.bookingStatus = config.FindAPC.BookingStatus.BOOKED;
                                            acti=actlen; /*stop iterating activities*/
    /*                                            break; /*stop iterating occurances*/
                                        }
                                    }
                                    break;
                                }
                            }
                        }
                    }
                }
            }
        },
        mapObjects(mapFrom,mapDestination,mappingKey){
            if(Array.isArray(mapFrom)){
                let olen = mapFrom.length;
                for(let i=olen;i--;){
                   let obj = mapFrom[i];
                    mapDestination[obj[mappingKey]] = obj;
                }
            }else{
                let obj = mapFrom;
                mapDestination[obj[mappingKey]] = obj;
            }
        },
        mapTwoObjects(mapFrom,associativeArrayFromKey,mapTo,associativeArrayToKey,mappingArray,associativePropertyName){
            for (let fromKey in mapFrom){
                let associativeArray = [];
                let fromObj = mapFrom[fromKey];
                let len = mappingArray.length;
                for(let i=0;i<len;i++){
                    let association = mappingArray[i];
                    if (association[associativeArrayFromKey]&&association[associativeArrayFromKey]===fromKey){
                        let toKey = association[associativeArrayToKey];
                        if (toKey){
                            let to = mapTo[toKey];
                            if (to){
                                associativeArray.push(to);
                            }
                        }
                    }
                }
                fromObj[associativePropertyName] = associativeArray;
            }
        },
        getMomentObjFromDateString(date,year,month,day){
            if (date.includes("/")){ /* dd/mm/yyyy*/
                /** This will just create a date at 00:00:00 time **/
                let dateArr = date.split("/");
                try{
                    return moment({
                        years:year||parseInt(dateArr[2]),
                        months:(month||parseInt(dateArr[1]))-1,
                        date:day||parseInt(dateArr[0])
                    });
                }catch(err){
                    return moment(); /* returns now if error*/
                }
            }else if(date.includes("T")&&date.includes("-")&&date.includes(":")){ /* dateTime with or without a timezone*/
                /* This will add a given date (year/month/day) to a given datetime time */
                let dateAndTime = date.split("T");              /*yyyy-MM-ddThh:mm:ss+hh:mm:ss*/
                let dateArr = dateAndTime[0].split("-");        /*yyyy-MM-dd*/
                let timeAndTimeZone = dateAndTime[1].split("+");/*hh:mm:ss+hh:mm*/
                let timeArr = timeAndTimeZone[0].split(":");    /*hh:mm:ss*/
                try{
                    return moment({
                        years:year||parseInt(dateArr[0]),
                        months:(month||parseInt(dateArr[1]))-1,
                        date:day||parseInt(dateArr[2]),
                        hours:parseInt(timeArr[0]),
                        minutes:parseInt(timeArr[1]),
                        seconds:parseInt(timeArr[2])
                    });
                }catch(err){
                    return moment(); /*returns now if error*/
                }
            }else{
                return moment(); /*returns now if error*/
            }
        },
        hoursToMoment(hour){
            if(hour!==null){
                let time = hour.split(":");
                try{return moment().hours(parseInt(time[0])).minutes(parseInt(time[1])).seconds(parseInt(time[2]));}catch(err){return null;}
            }else{
                return null;
            }
        },
        formatDescription(activityTypeNameFormatted,activityDescription,activityName){return (activityTypeNameFormatted!==""?activityTypeNameFormatted+": ":activityTypeNameFormatted)+(activityDescription===""||Object.prototype.hasOwnProperty.call(activityDescription,"text")?activityName:activityDescription);},
        formatExtraDescription(activity){return activity.ScheduledStartTimeFormatted+" "+activity.durationInHoursFormatted/*+" "+this.formatLocations(activity.locations)*/;},
        formatExtraOptionalDescription(activity){return activity.Description===""||Object.prototype.hasOwnProperty.call(activity.Description,"text")?"":activity.Name;},
        formatLocations(locations){
            if(locations){
                let loclen = locations.length;
                return loclen>1?" Multiple locations":(loclen===1?" "+locations[0].Name:"");
            }else{
                return "";
            }
        },
        act_translate(ActivityType_Name){
            switch(ActivityType_Name){
                case "B": return "Booking";
                case "C": return "Computing Lab";
                case "F": return "Fieldwork";
                case "H": return "Clinic";
                case "L": return "Lecture";
                case "P": return "Practical";
                case "S": return "Seminar";
                case "SO": return "Study Online";
                case "T": return "Tutorial";
                case "V": return "Film Showing";
                case "W": return "Workshop";
                default: return "";
            }
        },
    },
});
