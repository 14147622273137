<template>
    <the-alert />   
    <study-space />
</template>

<script>
    import { mapState, mapActions } from 'pinia';
    import { useAlertStore } from '@/stores/alert.module';
    import { useStudySpaceStore } from '@/stores/studyspace.module';
    import TheAlert from '@/components/TheAlert.vue';
    import StudySpace from '@/components/StudySpace.vue';

    export default {
        name: 'StudySpaceView',
        
        components: {
            TheAlert,
            StudySpace,
        },
    };
</script>
