<template>
    <div>
        <h1>You Are Logged Out</h1>
        <h2>This is just a temporary page</h2>
        <div>Your session has expired. You can <button @click="LogmeIn">Log in</button> again, or return to our <a href="https://www.google.com">Home Page</a></div>
    </div>
</template>


<script>
    import {HeaderAuth} from "@/components/HeaderAuth";

    export default {
        name: "LoggedOut.vue",
        methods: {
            LogmeIn() {
                //console.log("Clicked it!");
                this.HeaderAuth.Login();
            }
        }
    }
</script>

<style scoped>

</style>
