<template>
    <div id="pic-controls'">
        <div class="container">
            <div class="row">
                <div class="col-6 text-end yes">
                    <button class="cropbutton cropbutton-ok bg-success" v-on:click="$emit('uploadCrop')" id="OK"><i class="bi-check-lg"></i><span> Upload</span></button>
                </div>
                <div class="col-6  no">
                    <button class="cropbutton cropbutton-cancel bg-danger" v-on:click="$emit('reCrop')" id="cancel"><i class="bi-x-lg"></i><span> Re-Crop</span></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CropControlsUpload"
    }
</script>



<style scoped>
    .cropbutton {
        width: 90px;
        /*height: 90px;*/
        border-radius: 10px;
        color: #EEE;
        border: 0 transparent solid;
    }
    .cropbutton-ok {

    }
    .cropbutton-cancel {

    }
</style>
