<template>
    <div class="header-container">
        <h2>Article Record View</h2>
    </div>
    <a class="btn btn-primary" v-on:click="$router.push({ name: 'ArticleSummaryView' })">{{$strings('application.backButton')}}</a>
    <the-alert />
    <form v-if="articleRecord" v-on:submit.prevent="saveRecord">
        <div class="form-group">
            <label for="articleId">{{ $strings('article.articleId') }}</label>
            <input type="text" id="articleId" disabled readonly class="form-control" v-model="articleRecord.articleId" />
        </div>
        <div class="form-group">
            <label for="Title">{{ $strings('article.title') }}</label>
            <input type="text" id="Title" class="form-control" v-model="articleRecord.title" maxlength="100" required />
        </div>
        <div class="form-group">
            <label for="Code">{{ $strings('article.code') }}</label>
            <input type="text" id="Code" class="form-control" v-model="articleRecord.code" maxlength="3" />
        </div>
        <div class="form-group">
            <label for="ImportantDate">{{ $strings('article.importantDate') }}</label>
            <input type="date" id="ImportantDate" class="form-control" v-model="articleRecord.importantDate" required />
        </div>
        <div class="form-group">
            <label for="ImportantTime">{{ $strings('article.importantTime') }}</label>
            <input type="time" id="ImportantTime" class="form-control" v-model="articleRecord.importantTime" required />
        </div>
        <div class="form-group">
            <label for="ImportantNumber">{{ $strings('article.importantNumber') }}</label>
            <input type="number" id="ImportantNumber" class="form-control" v-model.number="articleRecord.importantNumber" required min="0" max="999" />
        </div>
        <div class="form-group">
            <label for="LessImportantNumber">{{ $strings('article.lessImportantNumber') }}</label>
            <input type="number" id="LessImportantNumber" class="form-control" v-model.number="articleRecord.lessImportantNumber" />
        </div>
        <div class="form-group">
            <label for="AnotherString">{{ $strings('article.anotherString') }}</label>
            <input type="text" id="AnotherString" class="form-control" v-model="articleRecord.anotherString" />
        </div>
        <div class="form-group">
            <label for="Option">{{ $strings('article.option') }}</label>
            <dropdown-list v-model.number="articleRecord.optionTypeId" :listData="optionTypes"></dropdown-list>
        </div>
        <button class="btn btn-primary">{{$strings('application.saveButton')}}</button>
        <button class="btn btn-secondary" v-on:click.prevent="deleteRecord">{{$strings('application.deleteButton')}}</button>
    </form>
</template>

<script>
    import { mapState, mapActions } from 'pinia';
    import { useArticleStore } from '@/stores/article.module';
    import { useAlertStore } from '@/stores/alert.module';
    import { useDropdownListStore } from '@/stores/dropdownlists.module';
    import TheAlert from '@/components/TheAlert.vue';
    import DropdownList from '@/components/DropdownList.vue';

    export default {
        name: 'ArticleRecordView',
        components: {
            TheAlert,
            DropdownList,
        },
        created() {
            this.initialiseUI();
            this.$logger.logInformation("Component ArticleRecordView created.")
            if (this.$route?.query?.new) {
                this.showInformationAlert('Record created');
            }
        },
        computed: {
            ...mapState(useArticleStore, ['articleRecord']),
            ...mapState(useDropdownListStore, ['optionTypes']),
            createMode() {
                return this.$route.params.id === '0';
            }
        },
        methods: {
            ...mapActions(useArticleStore, ['getArticleRecord', 'createArticle', 'updateArticle', 'deleteArticle', 'clearState']),
            ...mapActions(useAlertStore, ['showInformationAlert', 'showErrorAlert']),
            async initialiseUI() {
                this.isInitialisingUI = true;

                if (!this.createMode) {
                    try {
                        await this.getArticleRecord({ articleId: this.$route.params.id });
                    } catch (error) {
                        if (error.response.status === 404) {
                            this.showErrorAlert('Error: Article not found');
                        }
                        else {
                            this.showErrorAlert('Error: An unknown error occurred');
                        }
                    }
                }
                else {
                    this.clearState();
                }
                this.isInitialisingUI = false;
            },
            async saveRecord() {
                try {
                    if (this.createMode) {
                        const result = await this.createArticle();
                        this.$router.push({ name: 'ArticleRecordView', params: { id: result.createdId }, query: { new: true }, replace: true });
                    }
                    else {
                        await this.updateArticle();
                        this.showInformationAlert('Record updated');
                    }
                } catch (error) {
                    //validation error
                    const data = error.response.data;
                    this.showErrorAlert(data.error, data.errorDetails);
                }
            },
            async deleteRecord() {
                try {
                    await this.deleteArticle({ articleId: this.$route.params.id }); //go via route or state?
                    this.$router.push({ name: 'ArticleSummaryView', query: { delete: true } });
                } catch (error) {
                    this.showErrorAlert("Error: An unknown error occured when attempting to delete the record");
                }
            },
        },
    };
</script>
