import axios from 'axios';
import { defineStore } from 'pinia';
//import applicationSettings from '@/applicationsettings.json';

export const useApplicationSettingsStore = defineStore('applicationSettings', {
    state: () => ({
        environment: null,
        itemsCountPerPage: 0,
        isLoading: false,
        applicationInsightsConnectionString: '',
        endpointTalendPath: '',
        idfsSubscriptionKey: '',
    }),
    actions: {
        async getApplicationSettings() {
            this.isLoading = true;
            const response = await axios.get('/applicationsettings.json');
            this.environment = response.data.Application.Environment;
            this.itemsCountPerPage = response.data.Application.ItemsCountPerPage;
            this.applicationInsightsConnectionString = response.data.Application.ApplicationInsightsConnectionString;
            this.endpointTalendPath = response.data.Endpoint.Talend.Path;
            this.idfsSubscriptionKey = response.data.Api.SubscriptionKey;
            this.isLoading = false;
        },
    },
});
