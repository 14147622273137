import { defineStore } from 'pinia';
import axios from 'axios';

export const useDropdownListStore = defineStore('dropdownlists', {
    state: () => ({
        optionTypes: null,
        isLoading: false,
    }),
    actions: {
        async getOptionTypesList() {
            this.isLoading = true;
            try {
                let response = await axios.get('/api/dropdownlists/optiontypes');
                this.optionTypes = response.data.dropdownListItems;
            }
            finally {
                this.isLoading = false;
            }
        },
        async getAll() {
            //add options here as we add new dropdowns, getAll() is 
            //called prior to starting the application
            this.getOptionTypesList();
        },
        clearState() {
            this.$reset();
        },
    },
});
