// This store is to be used in conjunction with the IdCard component
import apiHandler from '@/shared/apiHandler';
import {defineStore, mapActions, mapState} from 'pinia';
import moment from 'moment';
import config from '../../public/applicationsettings.json';
import { useAuthenticationStore } from '@/stores/authentication.module';
import {useApplicationSettingsStore} from "@/stores/applicationsettings.module";
import { formatUserId } from '@/shared/stringFunctions';
import {useRoute} from "vue-router";

let IdCardDataSource =  config.IdCardData;//config.IdCardDataLive; //config.IdCardData
let IdCardRecordSource = config.IdCardDataLive;

export const useIdCardInfoStore = defineStore('idCardInfo', {
    state: () => ({
        photoURL: undefined,
        photoData: undefined,
        idCardRecord: {
            updatedAt: undefined,
            idNumber: undefined,
            lastName: '',
            /*forenames: 'fornames',*/
            /*initials: 'initials',*/
            startDate: '',
            expiryDate: '',
            knownAs: '',
            barcode: false,
            /*staffOrStudent: 'Staff',*/
            type: '',
            class: undefined,
            isPG: false,
            isUG: false,
        },
        idCardPhoto: {
            display: false,
            updatedAt: undefined,
            src: undefined,
        },
        useLoggedInUser: true,
    }),
    computed: {
        ...mapState(useAuthenticationStore, ['isLoggedIn', 'account', 'msalConfig', 'getUsername', 'getName', 'getPhotoID']),
    },
    methods: {
        getImageQString: function() {
            return this.getPhotoID() ? this.getPhotoID() : "";
        },
    },
    actions: {

        ...mapActions(useAuthenticationStore, ['buildAuthHeaders']),

        /**
         * Hard coded values until we get a working endpoint which to connect,
         * or get details from azure login.
         *
         * All fields to be gotten from Integration Services, except class which can be one of (staff, student, med)
         *  and will need to be computed from type (med from Med. Ed.)
         */
        async getIdCardRecord(userId) {
            let userToQuery = formatUserId(userId);
            // - set userToQuery to a student login or a staff n-id to see their record data. Testing only

            //userToQuery = "npm137"; //"c3026684";
            // - check if queryString over-ride is present and allowable
            userToQuery = this.checkQStr(userToQuery);
            let isTrue = true;
            if (isTrue && `${IdCardRecordSource.CardDataPath}` !== null) {
                const lastRefresh = moment(this.idCardRecord.updatedAt);
                const dateNow = moment();
                // - hack to fetch data every time.
                const elapsedHours = dateNow.diff(lastRefresh, 'hours') + 12;
                //let idCardRecord = null;

                if (this.idCardRecord.updatedAt === undefined || elapsedHours > 1 ) {
                    let token = await apiHandler.getSsoToken();
                    let subscriptionKey = useApplicationSettingsStore().idfsSubscriptionKey;
                    const authHeaders = this.buildAuthHeaders(token, subscriptionKey);

                    // - set url, this changes in azure deploy depending on whether dev, or live/QA
                    let endpointURL = config.Endpoint.Talend.Url + useApplicationSettingsStore().endpointTalendPath;
                    let IdCardRecordEndpoint = endpointURL + IdCardRecordSource.CardDataPath;

                    let qstr = "?username=" + userToQuery; //"npg76";
                    //console.log("JEANTEST ID Card full url: " + IdCardRecordEndpoint + qstr);
                    /*qstr += "&subscription-key=d4db970dafcb401f869c5c0cf5c9b3a2";*/
                    await apiHandler.apiGet(`${IdCardRecordEndpoint}` + qstr, authHeaders)
                        .then((result) =>
                        {
                            this.idCardRecord = result.data;
                            //console.log("!*!*!*!*!*!*! DATA: ", result.data);
                            this.idCardRecord.updatedAt = new Date();
                            this.fixFields();
                        })
                        .catch((error) =>
                        {
                            //console.log("JEANTEST ID Card error", error);
                            throw error;
                        });
                    //console.log("JEANTEST idCardRecord:", this.idCardRecord);

                    // - change data sent through as empty array/object into empty strings
                    for (const [key, value] of Object.entries(this.idCardRecord)) {
                        if (typeof value == "object" || Array.isArray(value)) {
                            this.idCardRecord[key] = "";
                        }
                    }

                    if (this.idCardRecord.expiryDate && !Array.isArray(this.idCardRecord.expiryDate)) {
                        //console.log("Still gonna try exp date");
                        let exp = this.idCardRecord.expiryDate.substring(0, 10); // eg 2032-07-06
                        let newExp = new Date(exp);
                        this.idCardRecord.expiryDate = newExp.toLocaleDateString('en-gb', {
                            day: 'numeric',
                            year: "numeric",
                            month: "short"
                        })
                    } else {
                        this.idCardRecord.expiryDate = "";
                    }
                }
            } else {
                this.idCardRecord = {
                    idNumber: 12345,
                    surname: this.getName(), //"Föřsythe-O'Calläghan 的 Pančáke",
                    forenames: "",
                    initials: "",
                    expiryDate: "",
                    knownAs: "",
                    barcode: false,
                    type: "staff", /* staff, student, or Med.Ed. */
                    /*staffOrStudent: "staff",*/ /* one of staff, or student */
                    class: "staff", /* calculated from type - staff, student, student-pg, or med */
                    isPG: true,
                    photoEligible: false,
                }
            }
            return this.idCardRecord;
        },



        /**
         * @param idNumber
         * @returns {Promise<void>}
         */
        async fetchUserPic(idNumber) {
            let newIdNumber = formatUserId(idNumber);
            //newIdNumber = this.checkQStr(newIdNumber);
            //console.log("newIdNumber " + newIdNumber);
            //newIdNumber = "npm137";
            // - access tokens etc.
            let token = await apiHandler.getSsoToken();
            
            //console.log('here? fetchUserPic');
            let subscriptionKey = useApplicationSettingsStore().idfsSubscriptionKey;
            const authHeaders = this.buildAuthHeaders(token, subscriptionKey);
            authHeaders.responseType = {params: {username: newIdNumber}, responseType: "arraybuffer",};

            // - build query url
            let endpointURL = config.Endpoint.Talend.Url + useApplicationSettingsStore().endpointTalendPath;
            let ImageDataEndpoint = endpointURL + IdCardDataSource.ImageFetchScript;
            ImageDataEndpoint += "?username=" + newIdNumber; //"npg76"; //newIdNumber;

            //console.log("ImageDataEndpoint: " + ImageDataEndpoint);
            // - this one currently sends a base64 string
            await apiHandler.apiGet(ImageDataEndpoint, authHeaders) //, {params: {idNumber: newIdNumber}, responseType: "arraybuffer",})
                .then((response) => {
                    //let data = atob(response.data);
                    //let binary = data; //this.dataToBinary(response.data);
                    this.idCardPhoto = {
                        display: true,
                        updatedAt: new Date(),
                        src: "data:image/jpeg;base64," + response.data,
                    };
                }).catch((error) =>
                {
                    //console.log("JEANTEST Photo fetch error", error);
                    throw error;
                }
            );
        },

        /**
         * Send the data from the cropped image to idfs for storing
         * @param formData
         * @param userLogin - n-id for staff (not likely) or login (cnnnnnn for students)
         * @returns {Promise<void>}
         */
        async uploadPic(formData, userLogin) {
            userLogin = formatUserId(userLogin);
            let token = await apiHandler.getSsoToken();
            let subscriptionKey = useApplicationSettingsStore().idfsSubscriptionKey;
            const authHeaders = this.buildAuthHeaders(token, subscriptionKey);

            let endpointURL = config.Endpoint.Talend.Url + useApplicationSettingsStore().endpointTalendPath;
            let ImageDataEndpoint = endpointURL + IdCardDataSource.ImageFetchScript;
            ImageDataEndpoint += "?username=" + userLogin; //"npg76"; //idNumber;

            //console.log("JEANTEST Endpoint: " + endpointURL);
            let postURL = ImageDataEndpoint; //'https://idfs.ncl.ac.uk/mobile/dev/digitalid/image/?username=' + userLogin
            //console.log("p-p-p-p-postURL: " + postURL);

            //return true;

            await apiHandler.apiPost(postURL, formData, authHeaders)
            .then(function (response) {
                //console.log(response);
                return true;
            }).catch((error) => {
                throw error;
            });
        },
        /**
         * pass in the response.data in the form of a byte array
         * @param arrayBuff
         * @returns {*}
         */
        dataToBinary: function(arrayBuff) {
            return this.bytesToBinary(this.dataToBytes(arrayBuff));
        },
        /**
         * convert the response.data to binary to enable btoa (base64'ing)
         * @param arrayBuff arraybuffer
         * @returns {string}
         */
        dataToBytes: function(arrayBuff) {
            return new Uint8Array(arrayBuff);
        },
        /**
         * Convert bytes to binary
         * @param bytes
         * @returns {*}
         */
        bytesToBinary: function(bytes) {
            return bytes.reduce((data, b) => data += String.fromCharCode(b), '');
        },
        fixFields: function() {
            // - following line is used for testing 'allow upload'
            // need to add in switches fo Med-Ed and PG ? !!!
            // using idNumber toidentify a valid ID record was returned - this may need to change
            this.idCardRecord.idNumber = null;
            switch (this.idCardRecord.type.toLowerCase()) {
                case 'student':
                    this.idCardRecord.class = 'student';
                    this.idCardRecord.idNumber = this.idCardRecord.studentNumber;
                    break;
                case 'staff':
                default:
                    this.idCardRecord.staffNumber = "S" + this.idCardRecord.staffNumber;
                    this.idCardRecord.class = 'staff';
                    this.idCardRecord.idNumber = this.idCardRecord.staffNumber;
            }
            if (this.idCardRecord.idNumber == "") {
                this.idCardRecord.idNumber = false;
            }
            // barcode is explicitly tested as truthy, but is sent as an empty string when not set. This fixes that.
            if (this.idCardRecord?.barcode == "") {
                this.idCardRecord.barcode = false;
            }
            if (this.idCardRecord.knownAs) {
                this.idCardRecord.knownAs = this.idCardRecord.firstName;
            }
            //this.idCardRecord.photoEligible = true;
        },
        checkQStr: function(currentUser) {
            let newUser = currentUser;
            const auth = useAuthenticationStore();
            if (auth.isAdmin) {
                let route = useRoute();
                //console.log("rq: ", route.query);
                //console.log(currentUser + " is admin");
                if (route?.query?.chongeToUser) {
                    //console.log("Changing to user " + route.query.chongeToUser, route);
                    newUser = route.query.chongeToUser;
                } else {
                    //console.log("Not chonging " + currentUser + " to something else ", route);
                }
            } else {
                console.log(currentUser + " not admin");
            }
            return newUser;
        },
    },
});
