<template>
    <div class="container-fluid header-container">
        <div class="row">
            <h2>{{ this.$route.meta.title }}</h2>
        </div>
    </div>
    <the-alert />
    <library-loan />
    <content-card />
</template>

<script>
    import { mapState, mapActions } from 'pinia';
    import { useAlertStore } from '@/stores/alert.module';
    import { useLibraryStore } from '@/stores/library.module';
    import TheAlert from '@/components/TheAlert.vue';
    import LibraryLoan from '@/components/LibraryLoan.vue';
    import { useAuthenticationStore } from '@/stores/authentication.module';
    import { useCardStore } from '@/stores/card.module';
    import ContentCard from '@/components/ContentCard.vue';

    export default {
        name: 'LibraryView',
        components: {
            TheAlert,
            LibraryLoan,
            ContentCard
        },
        created() {
            this.initialiseUI();
        },
        computed: {
            ...mapState(useLibraryStore, ['libraryloans']),
            ...mapState(useAuthenticationStore, ['isAdmin', 'getUsername']),
            ...mapState(useCardStore, ['cards']),
        },
        methods: {
            ...mapActions(useLibraryStore, ['getLibraryLoans']),
            ...mapActions(useAlertStore, ['showInformationAlert', 'showErrorAlert']),
            ...mapActions(useCardStore, ['getCards']),
            
            async initialiseUI() {
                this.isInitialisingUI = true;
                //console.log ("initialise ui");
                
                let userToGet = this.getUsername;
                
                try {
                    await this.getLibraryLoans(userToGet);
                } catch (error) {
                    this.showErrorAlert('Error: Unable to retrieve library loans');
                }

                this.isInitialisingUI = false;

                let loader = this.$loading.show();

                try {

                    await this.getCards(this.$route.name, true);
                } catch (error) {
                    this.showErrorAlert('Error: Unable to retrieve cards');
                }
                loader.hide();
            },

            
        },
    };
</script>
